.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

.dim {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.sp_logo {
  background: url(../img/mobile/sp_logo.png) 0 0 no-repeat;
  background-size: 600px 875px;
}

.sp_txt {
  background: url(../img/mobile/sp_txt.png) 0 0 no-repeat;
  background-size: 600px 875px;
}

.sp_ico {
  background: url(../img/mobile/sp_ico.png) 0 0 no-repeat;
  background-size: 250px 750px;
}

.sp_arrow {
  background: url(../img/mobile/sp_arrow.png) 0 0 no-repeat;
  background-size: 100px 900px;
}

.br_mo {
  display: none;
}

@media screen and (max-width: 767px) {
  .br_pc {
    display: none;
  }
  .br_mo {
    display: block;
  }
}