/* 미디어쿼리 */
@media screen and (min-width:568px) and (max-width:812px) and (max-height:449px) {

  /* 미디어쿼리 개인정보 처리방침 */
  .cont_wrap .tbl th {
    padding: 10px 15px;
  }

  .cont_wrap .tbl.privacy_policy .first_tr td {
    padding: 7px 15px;
  }

  .cont_wrap .tbl.privacy_policy .second_tr td {
    padding: 8px 15px 7px;
  }

  .cont_wrap .privacy_policy.tbl th.col1, .cont_wrap .privacy_policy.tbl td.col1 {
    width: auto;
    padding-left: 5px !important;
  }

  .cont_wrap .privacy_policy.tbl th.col2, .cont_wrap .privacy_policy.tbl td.col2 {
    width: auto;
  }

  .cont_wrap .tbl th.col1, .cont_wrap .tbl td.col1 {
    width: 120px;
  }

  .cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt1,
  .cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt1 {
    display: none;
  }

  .cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt2,
  .cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt2 {
    display: block;
  }

  .contact_pop .info_inpt {
    position: relative;
  }

  .contact_pop .info_inpt li:nth-child(1) {
    display: inline-block;
    width: 180px;
  }

  .contact_pop .info_inpt li:nth-child(2) {
    position: absolute;
    top: 9px;
    left: 186px;
    right: 0;
  }

  /* 미디어쿼리 메인페이지 */
  .section_inner .main_box .head_txt {
    display: none;
  }

  .section_inner.about .main_box .head_txt.type1,
  .section_inner:not(.about) .main_box .head_txt.type2 {
    display: block;
    letter-spacing: -0.5px;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt {
    display: none;
  }

  .section_inner .main_box .sub_txt_box {
    margin-top: -10px;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type2 {
    display: block;
    margin-top: 0;
    font-size: 14px;
  }

  .section_inner .main_box .btn_box a {
    margin-top: 23px;
  }

  .section.contact .contact_inner .btn_naver_map {
    margin-top: 10px;
  }

  .section.contact .contact_inner .contact_bottom .img_lico_map {
    display: block;
    width: 607px;
    height: 278px;
    background: url(../img/mobile/img_contact_map_media1334_v2.png) 50% 50% no-repeat;
    background-size: 100% auto;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li a {
    margin-top: 16px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method .job p {
    margin-top: 7px;
    display: block;
  }

  .section.contact .contact_inner .way_to_come {
    margin-top: 44px;
  }

  .section.contact .contact_inner .way_to_come dd {
    margin-top: 9px;
  }

  .section.contact .contact_inner .way_to_come dd ul li:first-child {
    display: none;
  }

  .section.contact .contact_inner .way_to_come dd ul li.type2 {
    display: block;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li p {
    margin-top: 7px;
    line-height: 1.5;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li p+p {
    margin-top: 2px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method:before {
    margin-bottom: 44px;
  }

  .sub_about_middle .lico_exp_box {
    margin-top: 15px;
  }

  /* 미디어쿼리 서브 워크*/
  .fullpage_wrapper.work .btn_box {
    margin-top: 4px;
    font-size: 0;
  }

  .fullpage_wrapper.work .btn_box li {
    display: inline-block;
    vertical-align: top;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_link:after {
    margin-top: 3px;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation {
    top: 25%;
    left: 98px;
  }

  .fullpage_wrapper.work h3 {
    margin-top: 17px;
  }

  .fullpage_wrapper.work .work_button_next {
    top: 50%;
    margin-top: 0;
  }

  .fullpage_wrapper.work .work_button_prev {
    top: 50%;
    margin-top: 0;
  }

  /* 미디어쿼리 서브 어바웃*/
  .sub_about_top h3 {
    margin-top: 46px;
  }

  .sub_about_top h3 .about_en_spot {
    font-size: 29px;
  }

  .sub_about_top p {
    line-height: 1.65;
    letter-spacing: -0.1px;
  }

  .sub_about_top .logo_box {
    position: absolute;
    right: 30px;
    top: 129px;
    margin: 0;
    text-align: center;
  }

  .sub_about_top .explanation:last-child {
    margin-top: 28px;
  }

  .sub_about_top .established_logo h3>span {
    width: 147px;
    height: 78px;
    background-position: 0 -375px;
  }

  .sub_about_middle {
    padding-top: 27px;
  }

  .sub_about_middle .lico_exp_box ul {
    width: auto;
    max-width: 676px;
  }

  .sub_about_middle .lico_exp_box ul li:nth-child(2) {
    margin-left: -30px;
    z-index: 84;
  }

  .sub_about_middle .lico_exp_box ul li:nth-child(4) {
    margin-left: -30px;
    z-index: 85;
  }

  .sub_about_bottom h3 {
    margin-top: 72px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst {
    height: 440px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst div {
    height: 440px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a {
    width: 60px;
    height: 60px;
    background: url(../img/mobile/sub_about_slide_btn2.png) no-repeat;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a>span {
    width: 15px;
    height: 30px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_prev>span {
    background-position: 0 -774px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_next>span {
    background-position: -18px -774px;
  }

  .sub_about_bottom .office_sub_txt {
    padding: 9px 30px 0;
  }

  /* 로딩 */
  .loading_box .loading_750 {
    display: none;
  }

  .loading_box .loading_1334 {
    display: block;
  }

  /* 푸터 메뉴리스트 */
  .mo_menu .menu_ft {
    bottom: 22px;
  }
}

@media screen and (min-width:768px) and (max-width:1023px) and (min-height:450px) {
  .section_inner .main_box {
    margin: 0 55px;
    z-index: 11;
  }

  /* 미디어쿼리 개인정보 처리방침 */
  .cont_wrap {
    padding: 0 55px;
  }

  .cont_wrap .tbl th {
    padding: 10px 15px;
  }

  .cont_wrap .tbl.privacy_policy .first_tr td {
    padding: 7px 15px;
  }

  .cont_wrap .tbl.privacy_policy .second_tr td {
    padding: 8px 15px 7px;
  }

  .cont_wrap.ethics {
    margin-bottom: 0;
    padding: 121px 55px 0;
    margin-top: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
  }

  .cont_wrap .privacy_policy.tbl th.col1, .cont_wrap .privacy_policy.tbl td.col1 {
    width: auto;
    padding-left: 5px !important;
  }

  .cont_wrap .privacy_policy.tbl th.col2, .cont_wrap .privacy_policy.tbl td.col2 {
    width: auto;
  }

  .cont_wrap .tbl th.col1, .cont_wrap .tbl td.col1 {
    width: 130px;
  }

  .cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt1,
  .cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt1 {
    display: none;
  }

  .cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt2,
  .cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt2 {
    display: block;
  }

  .cont_wrap.ethics .tbl.tbl_type th {
    width: 80px;
  }

  .cont_wrap.ethics .tbl.tbl_type td>div {
    padding-left: 15px;
  }

  /* 푸터 메뉴리스트 */
  .mo_menu .menu_ft li a {
    width: 50px;
    height: 20px;
    background-position: -181px -526px;
  }

  .mo_menu .menu_ft li:first-child {
    padding: 0 28px 0 0;
  }

  .mo_menu .menu_ft li:first-child a {
    width: 75px;
    height: 27px;
    background-position: -181px -489px;
  }

  /* 미디어쿼리 메인페이지 */
  .section_inner .main_box .head_txt {
    display: block;
    font-size: 105px;
  }

  .section_inner .main_box .head_txt .txt_transform_line+.txt_transform_line {
    margin-top: -50px
  }

  .section_inner .main_box .head_txt.type2,
  .section_inner .main_box .head_txt.type3 {
    display: none;
  }

  .section_inner.work .main_box .sub_txt_box, .section_inner.career .main_box .sub_txt_box {
    margin-top: -6px;
  }

  .section_inner .main_box .sub_txt_box .sub_txt {
    font-size: 25px;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt {
    display: none;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type2 {
    display: block;
    margin-top: 8px;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: -0.44px;
  }

  .section_inner .main_box .btn_box a {
    margin-top: 40px;
    width: 163px;
    height: 50px;
    line-height: 52px;
    font-size: 17px;
  }

  .section_inner.work .main_box .head_txt.type1 {
    display: block;
  }

  .section.contact .contact_inner .header_contact {
    font-size: 51px;
    line-height: 1.1;
  }

  .section.contact .contact_inner .way_to_come {
    margin-top: 20px;
  }

  .section.contact .contact_inner .way_to_come dd {
    margin-top: 11px;
  }

  .section.contact .contact_inner .btn_naver_map {
    margin-top: 10px;
  }

  .section.contact .contact_inner .contact_bottom {
    margin-top: 25px;
  }

  .section.contact .contact_inner .contact_bottom .img_lico_map {
    display: block;
    width: 100%;
    height: 303px;
    background: url(../img/mobile/img_contact_map_media1536_v2.png) 50% 50% no-repeat;
    background-size: 100% auto;
  }

  .section.contact .contact_inner .contact_bottom .contact_method .job p {
    display: block;
    margin-top: 7px;
  }

  .section_inner .main_box .head_txt.sub {
    font-size: 85px;
    line-height: 1.3;
    letter-spacing: 0;
  }

  .section_inner .main_box .head_txt.sub .txt_transform_line+.txt_transform_line {
    margin-top: -20px;
  }

  .section.contact .contact_inner {
    padding: 110px 55px 258px;
  }

  .section.contact .contact_inner .way_to_come dd ul li:first-child {
    display: none;
  }

  .section.contact .contact_inner .way_to_come dd ul li.type2 {
    display: block;
  }

  .section.contact .contact_inner .contact_bottom .contact_method:before {
    margin-bottom: 44px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li p {
    margin-top: 7px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li p+p {
    margin-top: 3px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li a {
    margin-top: 16px;
  }

  /* 로딩 */
  .loading_box .loading_750 {
    display: none;
  }

  .loading_box .loading_1536 {
    display: block;
  }

  /* 미디어쿼리 스크롤다운 버튼 */
  .btn_scroll_down {
    width: 57px;
    height: 30px;
    background-position: 0 -626px;
  }

  /* 미디어쿼리 풋터 */
  .footer_wrap .mo_footer .policy_lst {
    margin-top: 20px;
  }

  .footer_wrap .mo_footer .mo_copyright {
    margin-top: 13px;
  }

  .footer_wrap .mo_footer .btn_lst li a {
    margin-bottom: 2px;
  }

  /* 미디어쿼리 메뉴리스트 */
  .mo_menu .menu_lst li {
    margin-top: 25px;
  }

  .mo_menu .menu_lst li a {
    font-size: 40px;
    line-height: 1.13;
  }

  /* 미디어쿼리 서브 워크*/
  .fullpage_wrapper.work h2 {
    font-size: 50px;
  }

  .fullpage_wrapper.work .webtoon_sub {
    margin-top: 14px;
    font-size: 15px;
    line-height: 20px;
  }

  .fullpage_wrapper.work .sub_small {
    margin-top: 2px;
    font-size: 12px;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_list {
    font-size: 18px;
    line-height: 18px;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_list:after {
    margin-top: 4px;
  }

  .fullpage_wrapper.work.white .btn_box li .btn_work_list:after {
    width: 8px;
    height: 14px;
    background-position: -92px -702px;
  }

  .fullpage_wrapper.work .slider-for .slick-active.on .list_box {
    top: 300px;
    width: 494px;
    max-width: none;
    padding: 30px;
  }

  .tab-menu {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .tab-menu a.on {
    border-bottom: 2px solid #000;
  }

  .tab-menu a {
    font-size: 20px;
  }

  .tab-menu a+a {
    margin-left: 30px;
  }

  .list_box strong {
    font-size: 20px;
  }

  .list_box .list {
    max-height: 300px;
    padding-top: 16px;
    font-size: 17px;
  }

  .list_box dl+dl {
    margin-top: 11px;
  }

  .list_box .list_title {
    width: 160px;
    margin-right: 50px;
  }


  .btn_popup_close {
    font-size: 15px;
    line-height: 18px;
  }

  .btn_popup_close span.mo_off {
    display: inline-block;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_link {
    font-size: 18px;
    line-height: 18px;
  }

  .fullpage_wrapper.work .btn_box {
    margin-top: 19px;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_link:after {
    margin-top: 4px;
  }

  .fullpage_wrapper.work.white .btn_box li .btn_work_link:after {
    width: 8px;
    height: 14px;
    background-position: -92px -702px;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation {
    top: 121px;
    left: 55px;
  }

  .fullpage_wrapper.work .work_button_next {
    top: auto;
    bottom: 30%;
    right: 33px;
    width: 30px;
    height: 57px;
    background-position: -35px -285px;
  }

  .fullpage_wrapper.work .work_button_next.off {
    background-position: -35px -357px;
  }

  .fullpage_wrapper.work .work_button_prev {
    top: auto;
    bottom: 30%;
    left: 33px;
    width: 30px;
    height: 57px;
    background-position: 0 -285px;
  }

  .fullpage_wrapper.work .work_button_prev.off {
    background-position: 0 -357px;
  }

  .fullpage_wrapper.work.white .work_button_next {
    background-position: -35px -435px
  }

  .fullpage_wrapper.work.white .work_button_next.off {
    background-position: -35px -513px
  }

  .fullpage_wrapper.work.white .work_button_prev {
    background-position: 0 -435px;
  }

  .fullpage_wrapper.work.white .work_button_prev.off {
    background-position: 0 -513px;
  }

  /* 미디어쿼리 서브 어바웃 */
  .sub_about_top {
    padding-top: 113px;
  }

  .sub_about_top h3 {
    max-width: 768px;
    margin-top: 35px;
    font-size: 23px;
  }

  .sub_about_top h3>span {
    width: 227px;
    height: 23px;
    background-position: -280px -4px;
  }

  .sub_about_top .logo_box {
    position: absolute;
    right: 55px;
    top: 134px;
    margin: 0;
    text-align: center;
  }

  .sub_about_top .logo_box .logo {
    display: inline-block;
    width: 75px;
    height: 75px;
    background: url(../img/mobile/sp_logo.png) -150px -238px no-repeat;
    background-size: 600px 875px;
  }

  .sub_about_top .logo_box .logo.webtoon {
    width: 86px;
    height: 81px;
    margin-left: 34px;
    background-position: -150px -389px;
  }

  .sub_about_top p {
    font-size: 17px;
    line-height: 1.65;
    left: -0.1px;
  }

  .sub_about_top .explanation:last-child {
    margin-top: 27px;
  }

  .sub_about_top h2 {
    font-size: 50px;
    line-height: 1.02;
  }

  .sub_about_middle {
    padding-top: 46px;
  }

  .lico_at>span {
    width: 270px;
    height: 23px;
    background-position: -280px -128px;
  }

  .sub_about_middle .lico_exp_box {
    display: none;
  }

  .sub_about_middle .lico_exp_box.v2 {
    display: block;
    margin-top: 25px;
  }

  .sub_about_middle .lico_exp_box.v2 ul:last-child {
    margin-top: -37.5px;
  }

  .sub_about_middle .lico_exp_box ul {
    width: auto;
  }

  .sub_about_middle .lico_exp_box ul li {
    position: relative;
    display: inline-block;
    width: 270px;
    height: 270px;
    background: url(../img/mobile/sub_about_box2.png) no-repeat;
    background-size: 270px 270px;
  }

  .sub_about_middle .lico_exp_box ul li:first-child {
    margin: 0 -37px 0 0;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner {
    overflow: hidden;
    width: 233px;
    height: 233px;
    text-align: center;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner strong {
    margin-top: 80px;
    font-size: 36px;
    line-height: 0.92;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner p {
    margin-top: 7px;
    font-size: 16px;
    line-height: 25px;
  }

  .sub_about_bottom h3 {
    margin-top: 99px;
    padding: 0;
    background-position: -201px -254px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a {
    width: 60px;
    height: 60px;
    background: url(../img/mobile/sub_about_slide_btn2.png) no-repeat;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a>span {
    width: 15px;
    height: 30px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_prev>span {
    background-position: 0 -774px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_next>span {
    background-position: -18px -774px;
  }

  .sub_about_bottom .office_sub_txt {
    padding: 13px 0 0;
    font-size: 17px;
  }

  .sub_about_bottom .office_img_slid_box {
    margin-top: 24px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst {
    height: 370px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst div {
    height: 370px;
  }

  .mo_menu .mo_menu_close {
    width: 22px;
    height: 22px;
    background-position: 0 -90px;
  }

  .sub_about_bottom {
    max-width: 768px;
    padding: 0 55px 60px;
    margin: 0 auto;
  }

  .footer_wrap .mo_footer .mo_footer_inner {
    max-width: 768px;
    padding: 0 55px;
  }

  .sub_about_top .about_exp {
    max-width: 768px;
    padding: 0 55px;
  }

  .sub_about_middle .about_middle_inner {
    max-width: 768px;
    padding: 0 55px 100px;
  }

  .fullpage_wrapper.work h3.webtoon_tit {
    margin-top: 40px;
    font-size: 41px;
    line-height: 46px;
  }

  .fullpage_wrapper.work h3.webtoon_tit .txt_en, .fullpage_wrapper.work h3 .txt_en {
    font-family: Museo_Sans_500, sans-serif;
    font-weight: 500;
  }

}

@media screen and (min-width:1024px) and (min-height:550px) {
  .section_inner .main_box {
    margin: 0 55px;
    z-index: 11;
  }

  /* 미디어쿼리 개인정보 처리방침 */
  .cont_wrap {
    padding: 18px 55px 0;
  }

  .cont_wrap.ethics {
    margin: 0 auto;
    padding: 121px 55px 40px;
  }

  .cont_wrap .privacy_policy.tbl th.col1, .cont_wrap .privacy_policy.tbl td.col1 {
    width: auto;
    padding-left: 7px !important
  }

  .cont_wrap .privacy_policy.tbl th.col2, .cont_wrap .privacy_policy.tbl td.col2 {
    width: auto;
  }

  .cont_wrap .tbl th {
    padding: 10px 15px;
  }

  .cont_wrap .tbl.privacy_policy .first_tr td {
    padding: 7px 15px;
  }

  .cont_wrap .tbl.privacy_policy .second_tr td {
    padding: 8px 15px 7px;
  }

  .cont_wrap .tbl th.col1, .cont_wrap .tbl td.col1 {
    width: 130px;
  }

  .cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt1,
  .cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt1 {
    display: none;
  }

  .cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt2,
  .cont_wrap .tbl.privacy_policy td.purpose>div span.privacy_policy_txt2,
  .cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt3 {
    display: block;
  }

  .cont_wrap.ethics .tbl.tbl_type th {
    width: 80px;
  }

  .cont_wrap.ethics .tbl.tbl_type td>div {
    padding-left: 15px;
  }

  .contact_pop .info_inpt li:nth-child(1) {
    display: inline-block;
    width: 180px;
  }

  .contact_pop .info_inpt li:nth-child(2) {
    position: absolute;
    top: 9px;
    left: 186px;
    right: 0;
  }

  /* 푸터 메뉴리스트 */
  .mo_menu .menu_ft li a {
    width: 50px;
    height: 20px;
    background-position: -181px -526px;
  }

  .mo_menu .menu_ft li:first-child {
    padding: 0 28px 0 0;
  }

  .mo_menu .menu_ft li:first-child a {
    width: 75px;
    height: 27px;
    background-position: -181px -489px;
  }

  /* 미디어쿼리 메인 */
  .section.contact .contact_inner {
    padding: 119px 55px 247px;
  }

  .section.contact .contact_inner .way_to_come {
    margin-top: 34px;
  }

  .section.contact .contact_inner .way_to_come dd ul li {
    margin-top: 3px;
  }

  .section.contact .contact_inner .btn_naver_map {
    margin-top: 10px;
  }

  .section_inner .main_box .head_txt {
    display: none;
  }

  .section_inner .main_box .head_txt.type2 {
    display: block;
    font-size: 105px;
    letter-spacing: -0.5px;
  }

  .section_inner .main_box .sub_txt_box {
    margin-top: -19px;
  }

  .section_inner.work .main_box .sub_txt_box {
    margin-top: -9px;
  }

  .section_inner.career .main_box .sub_txt_box {
    margin-top: -6px;
  }

  .section_inner .main_box .sub_txt_box .sub_txt {
    font-size: 25px;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt {
    display: none;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type2 {
    display: block;
    margin-top: 7px;
    font-size: 15px;
    line-height: 1.33;
    opacity: 0.6;
  }

  .section_inner .main_box .btn_box a {
    margin-top: 40px;
    width: 163px;
    height: 50px;
    line-height: 52px;
    font-size: 17px;
  }

  .section_inner .main_box .btn_box.sub a {
    margin-top: 29px;
  }

  .section.contact .contact_inner .header_contact {
    font-size: 51px;
    line-height: 1.1
  }

  .section.contact .contact_inner .contact_bottom .img_lico_map {
    display: block;
    width: 676px;
    height: 303px;
    background: url(../img/mobile/img_contact_map_media2048_v2.png) 50% 50% no-repeat;
    background-size: 676px auto;
  }

  .section.contact .contact_inner .contact_bottom .contact_method .job p {
    display: block;
    margin-top: 6px;
  }

  .section.contact .contact_inner .way_to_come dd {
    margin-top: 11px;
  }

  .section.contact .contact_inner .way_to_come dd ul li:first-child {
    display: none;
  }

  .section.contact .contact_inner .way_to_come dd ul li.type2 {
    display: block;
  }

  .section_inner .main_box .head_txt.sub {
    font-size: 85px;
  }

  .section_inner .main_box .head_txt.sub .txt_transform_line+.txt_transform_line {
    margin-top: -32px;
  }

  /* 미디어쿼리 메뉴리스트 */
  .mo_menu .menu_lst li {
    margin-top: 25px;
  }

  .mo_menu .menu_lst li a {
    font-size: 40px;
    line-height: 1.13;
  }

  /* 미디어쿼리 스크롤다운 버튼*/
  .btn_scroll_down {
    width: 57px;
    height: 30px;
    background-position: 0 -626px;
  }

  /* 미디어쿼리 풋터 */
  .footer_wrap .mo_footer .btn_lst li a {
    margin-bottom: 2px;
  }

  /* 미디어쿼리 서브 워크*/
  .fullpage_wrapper.work .btn_box {
    margin-top: 8px;
  }

  .fullpage_wrapper.work .btn_box.mo_off {
    display: block;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation {
    top: 50%;
    left: 125px;
    margin-top: 3px;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .fullpage_wrapper.work h3 {
    margin-top: 0;
    font-size: 41px;
    line-height: 46px;
  }

  .fullpage_wrapper.work .btn_box li {
    margin-top: 16px;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_list:after {
    width: 8px;
    height: 14px;
    margin-top: 3px;
    margin-left: 2px;
    background-position: -92px -720px;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_list {
    font-size: 18px;
    line-height: 18px;
  }


  /* list_box */
  .fullpage_wrapper.work .slider-for .slick-active.on .list_box {
    top: 50%;
    left: auto;
    right: 117px;
    transform: translateY(-50%);
    width: 420px;
    max-width: none;
    padding: 30px;
  }

  .tab-menu {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .tab-menu a.on {
    border-bottom: 2px solid #000;
  }

  .tab-menu a {
    font-size: 18px;
  }

  .list_box strong {
    font-size: 18px;
  }

  .list_box .list {
    max-height: 278px;
    padding-top: 24px;
    font-size: 15px;
  }

  .list_box dl+dl {
    margin-top: 8px;
  }

  .list_box .list_title {
    width: 133px;
    margin-right: 42px;
  }

  .btn_popup_close {
    font-size: 15px;
    line-height: 18px;
  }

  .btn_popup_close::before {
    width: 24px;
    height: 24px;
    vertical-align: -10px;
    background: url('../img/sp_ico.png') no-repeat;
    background-position: -28px -2px;
    background-size: 150px 1000px;
    -webkit-background-size: 150px 1000px;
  }

  .btn_popup_close span.mo_off {
    display: inline-block;
  }

  .fullpage_wrapper.work .ghost_teller .btn_box li {
    display: block;
  }

  .fullpage_wrapper.work .refund_high .btn_box li {
    display: block;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_link:after {
    width: 8px;
    height: 14px;
    margin-top: 3px;
    margin-left: 2px;
    background-position: -92px -720px;
  }

  .fullpage_wrapper.work .btn_box li .btn_work_link {
    font-size: 18px;
    line-height: 18px;
  }

  .fullpage_wrapper.work .webtoon_sub {
    margin-top: 11px;
    font-size: 15px;
    line-height: 20px;
  }

  .fullpage_wrapper.work .webtoon_sub.mo_off {
    display: block;
  }

  .fullpage_wrapper.work .sub_small {
    margin-top: 5px;
    font-size: 12px;
  }

  .fullpage_wrapper.work .work_button_next {
    top: 50%;
    right: 33px;
    width: 30px;
    height: 57px;
    margin-top: -23px;
    background-position: -35px -285px;
  }

  .fullpage_wrapper.work .work_button_next.off {
    background-position: -35px -357px;
  }

  .fullpage_wrapper.work .work_button_prev {
    top: 50%;
    left: 33px;
    width: 30px;
    height: 57px;
    margin-top: -23px;
    background-position: 0 -285px;
  }

  .fullpage_wrapper.work .work_button_prev.off {
    background-position: 0 -357px;
  }

  .fullpage_wrapper.work.white .work_button_next {
    background-position: -35px -435px
  }

  .fullpage_wrapper.work.white .work_button_next.off {
    background-position: -35px -513px
  }

  .fullpage_wrapper.work.white .work_button_prev {
    background-position: 0 -435px;
  }

  .fullpage_wrapper.work.white .work_button_prev.off {
    background-position: 0 -513px;
  }


  /*미디어쿼리 서브 어바웃 */
  .sub_about_top {
    padding-top: 118px;
  }

  .sub_about_top h2 {
    font-size: 50px;
    line-height: 1.02;
  }

  .sub_about_top h3 {
    margin-top: 35px;
  }

  .sub_about_top h3>span {
    width: 227px;
    height: 23px;
    background-position: -280px -4px;
  }

  .sub_about_top p {
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: -0.1px;
  }

  .sub_about_top .logo_box {
    position: absolute;
    right: 53px;
    top: 177px;
    margin: 0;
    text-align: center;
  }

  .sub_about_top .logo_box .logo {
    display: inline-block;
    width: 99px;
    height: 92px;
    background: url(../img/mobile/sp_logo.png) -300px -238px no-repeat;
    background-size: 600px 875px;
  }

  .sub_about_top .logo_box .logo.webtoon {
    width: 111px;
    height: 100px;
    margin-left: 34px;
    background-position: -300px -389px;
  }

  .sub_about_middle h3 {
    width: 149px;
    height: 27px;
    background-position: -200px -128px;
  }

  .sub_about_middle .lico_exp_box {
    margin-top: 24px;
  }

  .sub_about_middle .lico_exp_box ul {
    margin-left: -35px;
    width: auto;
  }

  .sub_about_middle .lico_exp_box ul li {
    position: relative;
    display: inline-block;
    width: 255px;
    height: 255px;
    margin-right: -35px;
    background: url(../img/mobile/sub_about_box2.png) no-repeat;
    background-size: 255px 255px;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner {
    width: 221px;
    height: 221px;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner strong {
    margin-top: 74px;
    font-size: 36px;
    line-height: 0.92;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner p {
    font-size: 16px;
    line-height: 1.19;
  }

  .sub_about_bottom h3 {
    margin-top: 129px;
    padding: 0;
    background-position: -201px -254px;
  }

  .sub_about_bottom .office_sub_txt {
    padding: 12px 0 0;
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: -0.2px;
  }

  .sub_about_bottom .office_img_slid_box {
    margin-top: 23px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst {
    height: 514px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst div {
    height: 514px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a {
    width: 70px;
    height: 70px;
    background: url(../img/mobile/sub_about_slide_btn3.png) no-repeat;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a>span {
    width: 18px;
    height: 35px;
    margin-top: 18px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_next>span {
    background-position: -20px -811px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_prev>span {
    background-position: 0 -811px;
  }

  .mo_menu .mo_menu_close {
    width: 22px;
    height: 22px;
    background-position: 0 -90px;
  }

  .sub_about_bottom {
    max-width: 1024px;
    padding: 0 55px 60px;
    margin: 0 auto;
  }

  .footer_wrap .mo_footer .mo_footer_inner {
    max-width: 1024px;
    padding: 0 55px;
  }

  .sub_about_top .about_exp {
    max-width: 1024px;
    padding: 0 55px;
  }

  .sub_about_middle .about_middle_inner {
    max-width: 1024px;
    padding: 0 55px 100px;
  }

  /* 로딩 */
  .loading_box .loading_750 {
    display: none;
  }

  .loading_box .loading_2048 {
    display: block;
  }

  .fullpage_wrapper.work .page_tit {
    display: none;
  }

  .fullpage_wrapper.work h2.separation {
    display: block;
    position: absolute;
    top: 119px;
    left: 125px;
    font-size: 50px;
  }
}

@media screen and (min-width:1023px) and (max-width:1279px) {
  .section_inner.about .main_box .head_txt .txt_transform_line+.txt_transform_line {
    margin-top: -56px
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type2 {
    letter-spacing: -0.89px;
  }
}

@media screen and (min-width:768px) and (max-width:1279px) {
  .section_inner.about .main_box .head_txt {
    display: block;
    font-size: 85px;
  }

  .section_inner.about .main_box .head_txt.type2,
  .section_inner.about .main_box .head_txt.type3 {
    display: none;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type2 {
    font-size: 20px;
  }
}

@media screen and (min-width:1280px) {
  .sp_logo {
    background: url("../img/sp_logo.png") 0 0 no-repeat;
    background-size: 750px 400px;
  }

  .sp_ico {
    background: url("../img/sp_ico.png") 0 0 no-repeat;
    background-size: 150px 1000px;
  }

  .contact_pop .btn_qa_close {
    background: url("../img/mobile/sp_ico.png") 0 -29px no-repeat;
    background-size: 250px 750px;
  }

  .contact_pop .info_inpt li .ico_dn {
    background: url("../img/mobile/sp_ico.png") 0 -53px no-repeat;
    background-size: 250px 750px;
  }

  .sp_txt {
    background: url("../img/sp_txt.png") 0 0 no-repeat;
    background-size: 400px 400px;
  }

  .sp_arrow {
    background: url("../img/sp_arrow.png") 0 0 no-repeat;
    background-size: 100px 900px;
  }

  /* 메인 헤더*/
  .header_wrap.pc {
    position: absolute;
    display: block;
  }

  header.contact {
    display: none;
  }

  header.contact.sub_header {
    display: block;
  }

  header.contact.contact_pc_only {
    display: block;
  }

  .header_wrap.mobile {
    display: none;
  }

  .header_wrap {
    z-index: 100;
    padding: 50px 80px;
  }

  .header_wrap .lico_logo {
    display: inline-block;
    margin-right: 83px;
    vertical-align: top;
  }

  .header_wrap .lico_logo a {
    display: block;
    width: 98px;
    height: 50px;
    background-position: -355px -315px;
  }

  .header_wrap .nav_wrap {
    display: inline-block;
    vertical-align: top;
  }

  .header_wrap .nav_wrap .gnb {
    margin-top: 16px;
  }

  .header_wrap .nav_wrap .gnb:after {
    content: '';
    display: block;
    clear: both;
  }

  .header_wrap .nav_wrap .gnb li {
    position: relative;
    float: left;
    line-height: 0;
    margin-left: 37px;
  }

  .header_wrap .nav_wrap .gnb li:first-child {
    margin-left: 0;
  }

  .header_wrap .nav_wrap .gnb li a {
    display: inline-block;
  }

  .header_wrap .nav_wrap .gnb .gnb_about a {
    width: 77px;
    height: 15px;
    background-position: 1px -100px;
  }

  .header_wrap .nav_wrap .gnb .gnb_work a {
    width: 67px;
    height: 15px;
    background-position: -79px -100px;
  }

  .header_wrap .nav_wrap .gnb .gnb_career a {
    width: 85px;
    height: 15px;
    background-position: -154px -100px;
  }

  .header_wrap .nav_wrap .gnb .gnb_contact a {
    width: 106px;
    height: 15px;
    background-position: -244px -100px;
  }

  .header_wrap .nav_wrap .gnb .gnb_about a:hover {
    background-position: -360px -125px;
  }

  .header_wrap .nav_wrap .gnb .gnb_about.on a {
    height: 21px;
    background-position: 0 -125px;
  }

  .header_wrap .nav_wrap .gnb .gnb_work a:hover {
    background-position: -440px -125px;
  }

  .header_wrap .nav_wrap .gnb .gnb_work.on a {
    height: 25px;
    background-position: -80px -125px;
  }

  .header_wrap .nav_wrap .gnb .gnb_career a:hover {
    background-position: -515px -125px;
  }

  .header_wrap .nav_wrap .gnb .gnb_career.on a {
    height: 26px;
    background-position: -155px -125px;
  }

  .header_wrap .nav_wrap .gnb .gnb_contact a:hover {
    background-position: -605px -125px;
  }

  .header_wrap .nav_wrap .gnb .gnb_contact.on a {
    height: 25px;
    background-position: -245px -125px;
  }

  .header_wrap.black .lico_logo a {
    background-position: -355px -260px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_about a {
    background-position: 1px -175px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_work a {
    background-position: -79px -175px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_career a {
    background-position: -154px -175px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_contact a {
    background-position: -244px -175px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_about a:hover {
    background-position: -360px -200px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_about.on a {
    background-position: 0 -200px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_work a:hover {
    background-position: -440px -200px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_work.on a {
    background-position: -80px -200px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_career a:hover {
    background-position: -515px -200px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_career.on a {
    background-position: -155px -200px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_contact a:hover {
    background-position: -605px -200px;
  }

  .header_wrap.black .nav_wrap .gnb .gnb_contact.on a {
    background-position: -245px -200px;
  }

  /* 서브 페이지 헤더 */
  .sub_header .header_wrap {
    padding: 50px 80px 47px;
    background-color: #fff;
  }

  /* 서브 페이지 노배경 헤더 */
  .sub_header.notbg .header_wrap {
    background-color: inherit;
  }

  .sub_header .header_wrap .lico_logo a {
    background-position: -355px -260px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_about a {
    background-position: 1px -175px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_work a {
    background-position: -79px -175px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_career a {
    background-position: -154px -175px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_contact a {
    background-position: -244px -175px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_about a:hover {
    background-position: -360px -200px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_work a:hover {
    background-position: -440px -200px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_career a:hover {
    background-position: -515px -200px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_contact a:hover {
    background-position: -605px -200px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_about.on a {
    background-position: 0 -200px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_work.on a {
    background-position: -80px -200px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_career.on a {
    background-position: -155px -200px;
  }

  .sub_header .header_wrap .nav_wrap .gnb .gnb_contact.on a {
    background-position: -245px -200px;
  }

  .sub_header .header_wrap.white .lico_logo a {
    display: block;
    background: url("../img/sp_logo.png") -355px -315px no-repeat;
    background-size: 750px 400px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_about a {
    width: 74px;
    height: 15px;
    background-position: 1px -100px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_work a {
    width: 67px;
    height: 15px;
    background-position: -79px -100px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_career a {
    width: 85px;
    height: 15px;
    background-position: -154px -100px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_contact a {
    width: 105px;
    height: 15px;
    background-position: -244px -100px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_about a:hover {
    background-position: -360px -125px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_work a:hover {
    background-position: -440px -125px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_career a:hover {
    background-position: -515px -125px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_contact a:hover {
    background-position: -605px -125px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_about.on a {
    height: 25px;
    background-position: 0 -125px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_work.on a {
    height: 25px;
    background-position: -80px -125px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_career.on a {
    height: 25px;
    background-position: -155px -125px;
  }

  .header_wrap.white .nav_wrap .gnb .gnb_contact.on a {
    height: 25px;
    background-position: -245px -125px;
  }

  /* 로딩 */
  .loading_box .loading_2048 {
    display: none;
  }

  .loading_box .loading_2560 {
    display: block;
  }

  /* 메인 스크롤 다운 버튼*/
  .btn_scroll_down {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 47px;
    z-index: 110;
    width: 68px;
    height: 39px;
    background: url("../img/sp_ico.png") 0 -355px no-repeat;
    background-size: 150px 1000px;
  }

  .section {
    background-color: inherit;
  }

  .section_inner .main_box {
    max-height: 800px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
  }

  .section_inner .main_box .head_txt.type2 {
    position: relative;
    font-weight: 900;
    font-size: 130px;
    line-height: 1.2;
    letter-spacing: -2.89px;
    font-family: Museo_Slab_900, sans-serif;
  }

  .section_inner.about .main_box .head_txt.type2 {
    line-height: 1.3;
  }

  .section_inner .main_box .head_txt.sub .txt_transform_line+.txt_transform_line {
    margin-top: -20px;
  }

  .section_inner.career .main_box .head_txt.sub .txt_transform_line+.txt_transform_line {
    margin-top: -20px;
  }

  .section_inner.career .main_box .sub_txt_box {
    margin-top: -9px;
  }

  .section_inner .main_box .sub_txt_box .sub_txt {
    font-weight: 500;
    font-size: 42px;
    letter-spacing: -0.9px;
    font-family: NotoSansM, sans-serif;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type2 {
    margin-top: 15px;
    font-size: 30px;
    letter-spacing: -0.67px;
    opacity: 0.6;
    line-height: 44px;
  }

  .section_inner .main_box .btn_box {
    position: relative;
  }

  .section_inner .main_box .btn_box a {
    position: relative;
    display: inline-block;
    width: 236px;
    height: 68px;
    margin-top: 56px;
    border: solid 2px #ffffff;
    font-size: 26px;
    color: #fff;
    letter-spacing: -0.6px;
    line-height: 72px;
    font-family: Museo_Slab_500, serif;
  }

  .section_inner .main_box .btn_box.sub a {
    margin-top: 43px;
  }

  /* 메인 about */
  .section_inner.about .main_box .sub_txt_box {
    margin-left: -6px;
  }

  /* 메인 워크 */
  .section_inner.work .main_box {
    position: relative;
    z-index: 11;
    margin-left: 262px;
    line-height: 1.1;
    text-align: left;
  }

  .section_inner.work .main_box .head_txt {
    font-size: 100px;
    line-height: 1.3;
    letter-spacing: normal;
  }

  .section_inner.work .main_box .btn_box a {
    margin-top: 58px;
    text-align: center;
  }

  .section_inner.work .main_box .sub_txt {
    margin-top: 12px;
  }

  /* 메인 커리어 */
  .section_inner.career .main_box {
    position: relative;
    z-index: 11;
    margin-left: 262px;
    text-align: left;
  }

  .section_inner.career .main_box .head_txt {
    font-size: 100px;
    line-height: 1.3;
    letter-spacing: normal;
  }

  .section_inner.career .main_box .btn_box a {
    margin-top: 58px;
    text-align: center;
  }

  .section_inner.career .section_inner .main_box .sub_txt {
    margin-top: 2px;
  }

  /* 메인 콘텍트 */
  .section.contact .contact_inner {
    width: 1360px;
    margin: 185px auto 0;
    padding: 0 80px 214px;
    font-size: 0;
  }

  .section.contact .contact_inner .way_to_come .en {
    font-family: Museo_Sans_500, sans-serif;
  }

  .section.contact .contact_inner .header_contact {
    margin-left: -4px;
    font-size: 80px;
    color: #000;
    line-height: 95px;
  }

  .section.contact .contact_inner .way_to_come {
    margin-top: 66px;
  }

  .section.contact .contact_inner .way_to_come dt {
    font-weight: 600;
    font-size: 26px;
    color: #000;
    line-height: 34px;
  }

  .section.contact .contact_inner .way_to_come dd {
    margin-top: 18px;
  }

  .section.contact .contact_inner .way_to_come dd ul li {
    margin-top: 3px;
    font-size: 18px;
    color: #000;
    line-height: 25px;
  }

  .section.contact .contact_inner .way_to_come dd ul li:first-child {
    margin-top: 0;
  }

  .section.contact .contact_inner .way_to_come dd ul li .txt_box {
    line-height: 25px;
  }

  .section.contact .contact_inner .btn_naver_map {
    margin-top: 22px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 300;
  }

  .section.contact .contact_inner .btn_naver_map:after {
    content: '';
    display: inline-block;
    width: 9px;
    height: 13px;
    margin: 5px 0 0 7px;
    background: url("../img/sp_ico.png") 0 -310px no-repeat;
    background-size: 150px 1000px;
    vertical-align: top;
  }

  .section.contact .contact_inner .btn_naver_map:before {
    display: none;
  }

  .section.contact .contact_inner .contact_bottom .img_lico_map {
    display: inline-block;
    width: 492px;
    height: 435px;
    margin-right: 109px;
    background: url("../img/img_contact_map_v2.png") no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
  }

  .section.contact .contact_inner .contact_bottom {
    margin-top: 65px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method {
    display: inline-block;
    padding-top: 1px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method:before {
    display: none;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li {
    margin-top: 54px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li strong {
    display: block;
    padding-bottom: 20px;
    border-bottom: 1px solid #000;
    font-weight: 600;
    font-size: 26px;
    color: #000;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li p {
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method .job p {
    margin-top: 20px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li p .spot_en {
    font-family: Museo_Sans_500, sans-serif;
    line-height: 29px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li a {
    width: 140px;
    height: 50px;
    margin-top: 30px;
    font-size: 18px;
    line-height: 50px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method .m_only_view {
    display: none;
  }

  .section.contact .contact_inner .contact_bottom .contact_method .pc_only_view {
    display: block;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li:first-child:after {
    display: none;
  }

  /* 서브 워크 */
  .fullpage_wrapper.work .work_button_next {
    position: fixed;
    top: 50%;
    right: 78px;
    z-index: 30;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 60px;
    height: 104px;
    background: url(../img/sp_ico.png) 0 0 no-repeat;
    background-size: 150px 1000px;
    background-position: -75px -480px;
  }

  .fullpage_wrapper.work .work_button_next.off {
    background-position: -75px -595px;
  }

  .fullpage_wrapper.work .work_button_prev {
    position: fixed;
    top: 50%;
    left: 78px;
    z-index: 30;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 60px;
    height: 104px;
    background: url(../img/sp_ico.png) 0 0 no-repeat;
    background-size: 150px 1000px;
    background-position: 0 -480px;
  }

  .fullpage_wrapper.work .work_button_prev.off {
    background-position: 0 -595px;
  }

  .fullpage_wrapper.work .container>h2 {
    font-size: 80px;
    font-weight: 900;
    font-style: normal;
    color: #000;
  }

  .fullpage_wrapper.work .slide {
    // display: inline-block;
    position: relative;
    height: 100%;
  }

  .fullpage_wrapper.work .slide .slide_inner {
    height: 100%;
  }

  .fullpage_wrapper.work .slide .slide_inner .page_tit {
    top: 17%;
    left: 260px;
    z-index: 30;
    font-weight: 900;
    font-size: 80px;
    color: #000;
    line-height: 1;
    font-family: Museo_Slab_900, sans-serif;
    font-weight: normal;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation {
    position: absolute;
    top: 50%;
    left: 260px;
    margin-top: -8px;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 30;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .webtoon_tit {
    font-size: 64px;
    line-height: 1.19;
    letter-spacing: -1px;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .webtoon_sub {
    margin-top: 22px;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: -1px;
  }

  .fullpage_wrapper.work .btn_box li {
    display: block;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box {
    margin-top: 34px;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li {
    margin-top: 0;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li.mobile_only {
    display: none;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li.pc_only {
    display: block;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li.pc_only .btn_work_list:after {
    display: none;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_list {
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: -1px;
  }

  .fullpage_wrapper.work .slider-for .slick-active.on .list_box {
    right: 170px;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_list:after {
    width: 11px;
    height: 16px;
    margin: 9px 0 0 7px;
    background: url("../img/sp_ico.png") 0 -290px no-repeat;
    background-size: 150px 1000px;
  }

  .fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_list:after {
    background-position: -14px -290px;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li.pc_only .btn_work_link:after {
    display: none;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_link {
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: -1px;
  }

  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_link:after {
    width: 11px;
    height: 16px;
    margin: 9px 0 0 7px;
    background: url("../img/sp_ico.png") 0 -290px no-repeat;
    background-size: 150px 1000px;
  }

  .fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_link:after {
    background-position: -14px -290px;
  }

  .fullpage_wrapper.work.white .slide .slide_inner .page_tit {
    color: #fff;
  }

  .fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .webtoon_tit {
    color: #fff;
  }

  .fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .webtoon_sub {
    color: #fff;
  }

  .fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_list {
    color: #fff;
  }


  .fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_link {
    color: #fff;
  }

  .fullpage_wrapper.work.white .btn_box li .btn_work_link:after {
    background: url("../img/sp_ico.png") -15px -290px no-repeat;
    background-size: 150px 1000px;
  }

  .fullpage_wrapper.work.white .work_button_next {
    background-position: -75px -710px;
  }

  .fullpage_wrapper.work.white .work_button_next.off {
    background-position: -75px -825px;
  }

  .fullpage_wrapper.work.white .work_button_prev {
    background-position: 0 -710px;
  }

  .fullpage_wrapper.work.white .work_button_prev.off {
    background-position: 0 -825px;
  }

  .fullpage_wrapper.work .sub_small {
    letter-spacing: 0;
    font-size: 13px;
    margin-top: 2px;
  }

  /* 메인 섹션 내비게이션 */
  .section_nav {
    position: fixed;
    top: 50%;
    right: 80px;
    z-index: 50;
    margin-top: -32px;
  }

  .section_nav li {
    position: relative;
    width: 4px;
    height: 4px;
    padding: 9px 0;
  }

  .section_nav li:first-child {
    margin-top: 0;
  }

  .section_nav li a:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: #fff;
  }

  .section_nav li.on a:after,
  .section_nav li:hover a:after {
    margin: -2px 0 0 -2px;
    width: 8px;
    height: 8px;
  }

  /* 개인정보 처리방침 */
  .cont_wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 216px 0 78px;
    color: #353b42;
    font-family: NotoSansM, sans-serif;
  }

  .cont_wrap.ethics {
    margin: 0 auto;
    padding: 216px 0 50px;
    font-family: NotoSansB, sans-serif;
  }

  .cont_wrap h2 {
    font-weight: normal;
    font-size: 30px;
    color: #000;
    font-family: NotoSansB, sans-serif;
  }

  .cont_wrap.ethics .top_txt {
    margin: 30px 0 33px;
    font-weight: normal;
    font-size: 17px;
    line-height: 1.76;
    font-family: NotoSansM, sans-serif;
    letter-spacing: 0;
  }

  .cont_wrap.ethics .ethics_table p {
    font-size: 16px;
    line-height: 1.76;
    font-family: NotoSansM, sans-serif;
  }

  .cont_wrap h3 {
    padding-top: 52px;
    margin: 53px 0 19px;
    border-top: 1px solid #e9e9f1;
    font-size: 22px;
    font-weight: 600;
    line-height: inherit;
  }

  .cont_wrap .pc_blind {
    display: none;
  }

  .cont_wrap .mo_blind {
    display: block;
  }

  .cont_wrap a,
  .cont_wrap ol,
  .cont_wrap p,
  .cont_wrap ul {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.69;
    color: #595e65;
    font-family: NotoSansM, sans-serif;
  }

  .btn_prev_info:after {
    bottom: 2px;
  }

  .cont_wrap .top_txt {
    margin: 30px 0 33px;
    font-weight: normal;
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    font-family: NotoSansR, sans-serif;

    &.last {
      +p {
        font-weight: 500;
        font-size: 17px;
        line-height: 30px;
      }
    }
  }

  .cont_wrap .top_txt+h3 {
    // margin-top: 64px;
  }

  .cont_wrap.ethics a {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.69;
    font-family: Museo_Sans_700, sans-serif;
  }

  .cont_wrap li .other_color:last-child {
    margin-bottom: 19px;
  }

  .cont_wrap p {
    margin-bottom: 26px;
    font-weight: normal;
    font-family: NotoSansM, sans-serif;
  }

  .cont_wrap h2 {
    font-weight: normal;
    font-size: 30px;
    color: #000;
  }

  .cont_wrap h2 .mo_blind {
    display: inline;
  }

  .cont_wrap .other_color {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 16px;
    color: #a6adb4;
  }

  .cont_wrap .other_color a {
    display: inline-block;
    vertical-align: top;
    line-height: inherit;
    color: #a6adb4;
    font-family: Museo_Sans_700, sans-serif;
  }

  .cont_wrap .cont_en {
    font-family: Museo_Sans_700, sans-serif;
  }

  .cont_wrap .person_list {
    margin: -26px 0 26px;
    line-height: 28px;
  }

  .cont_wrap strong {
    margin-bottom: 9px;
    font-size: 16px;
    line-height: 1.69;
    font-weight: 600;
  }

  .cont_wrap .tbl+h3 {
    margin-top: 60px;
  }

  .cont_wrap .privacy_policy.tbl th {
    border-top: 2px solid #595e65;
    border-bottom: 2px solid #595e65;
  }

  .cont_wrap .privacy_policy.tbl th>div {
    border-top: 0;
    border-bottom: 0;
    font-family: NotoSansM, sans-serif;
  }

  .cont_wrap .tbl th {
    padding: 17px 30px 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
    font-family: NotoSansM, sans-serif;
  }

  .cont_wrap .tbl th:first-child {
    padding-left: 30px;
  }

  .cont_wrap.ethics .tbl.tbl_type th {
    padding: 17px 45px 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
    font-family: NotoSansM, sans-serif;
  }

  .cont_wrap.ethics .ethics_table .tbl.tbl_type .middle th, .cont_wrap .privacy_policy.tbl th, .cont_wrap .tbl.privacy_policy .first_tr td, .cont_wrap .tbl.privacy_policy .first_tr td:first-child, .cont_wrap .tbl.privacy_policy .second_tr td, .cont_wrap .tbl.privacy_policy .second_tr td:first-child {
    padding: 17px 45px 16px;
  }

  .cont_wrap .tbl td.align_top {
    padding: 18px 30px 17px;
  }

  .cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt2, .cont_wrap .tbl.privacy_policy td.purpose>div span.privacy_policy_txt2, .cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt3, .cont_wrap .tbl td>div {
    font-size: 15px;
    line-height: 2;
  }

  .cont_wrap .privacy_policy.tbl th.col1, .cont_wrap .privacy_policy.tbl td.col1 {
    padding-left: 9px !important;
  }

  .cont_wrap .privacy_policy.tbl th.col1, .cont_wrap .privacy_policy.tbl td.col1, .cont_wrap .privacy_policy.tbl th.col2, .cont_wrap .privacy_policy.tbl td.col2 {
    width: auto;
  }

  .cont_wrap .tbl {
    margin: 28px 0;
  }

  .cont_wrap .tbl.tbl_type th {
    padding: 20px 30px 17px;
  }

  .cont_wrap.ethics .tbl.tbl_type td>div {
    padding: 19px 38px;
  }

  .cont_wrap .tbl.tbl_type td,
  .cont_wrap .tbl.tbl_type th {
    border-bottom: 1px solid #595e65;
  }

  .cont_wrap.ethics .tbl.tbl_type td,
  .cont_wrap.ethics .tbl.tbl_type th {
    border-bottom: 1px solid #595e65;
  }

  .cont_wrap .tbl.tbl_type .post td>div {
    padding: 20px 38px;
  }

  .cont_wrap .tbl.tbl_type .btn_counseling {
    margin-top: 10px;
    width: 148px;
    height: 50px;
    font-weight: normal;
    font-size: 17px;
    line-height: 50px;
  }

  .cont_wrap .tbl_type_area {
    margin-top: 24px;
    border-bottom: 1px solid #595e65;
  }

  .cont_wrap .list_base {
    margin-top: 19px;
  }

  .cont_wrap .def_info {
    font-weight: normal;
    font-family: NotoSansM, sans-serif;
    font-size: 15px;
  }

  .cont_wrap .def_info dt {
    float: left;
    margin-right: 5px;
    line-height: 2;
  }

  .cont_wrap .def_info dd {
    overflow: hidden;
    line-height: 2;
  }

  .mo_careers {
    display: none;
  }

  /* 메인 푸터 */
  footer.main_footer_pc {
    display: block;
  }

  footer.main_footer_mo {
    display: none;
  }

  footer.main_footer_pc.contact {
    display: none;
  }

  footer.main_footer_mo.contact {
    display: block;
  }

  footer.main_footer_mo.contact .footer_wrap {
    position: relative;
  }

  .mo_footer {
    display: none;
  }

  .footer_wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    min-width: 610px;
    padding: 46px 80px 40px;
  }

  .footer_wrap .pc_footer {
    display: block;
  }

  .footer_wrap .mo_footer {
    display: none;
  }

  .career .footer_wrap {
    border: 0;
  }

  .footer_wrap::after {
    content: '';
    display: block;
    clear: both;
  }

  .footer_wrap .ft_menu {
    float: left;
  }

  .footer_wrap .ft_menu:after {
    content: '';
    display: block;
    clear: both;
  }

  .footer_wrap .ft_menu li {
    float: left;
    margin-left: 22px;
  }

  .footer_wrap .ft_menu li:first-child {
    margin-left: 0;
  }

  .footer_wrap .ft_menu.icon li a {
    display: inline-block;
  }

  .black_footer .footer_wrap.white .ft_menu.icon .webtoon a,
  .footer_wrap .ft_menu.icon .webtoon a,
  .footer_wrap.white .ft_menu.icon .webtoon a {
    width: 40px;
    height: 38px;
    margin-top: -7px;
    background-position: -405px -30px;
  }

  .black_footer .footer_wrap.white .ft_menu.icon .naver a,
  .footer_wrap .ft_menu.icon .naver a,
  .footer_wrap.white .ft_menu.icon .naver a {
    width: 63px;
    height: 12px;
    margin-top: 5px;
    background-position: -245px 0;
  }

  .footer_wrap .ft_menu.privacy {
    margin-top: 2px;
  }

  .footer_wrap .ft_menu.privacy li {
    margin-left: 24px;
  }

  .footer_wrap .ft_menu.privacy li:first-child {
    margin-left: 0;
  }

  .footer_wrap .ft_menu.privacy li a {
    color: #fff;
    font-size: 13px;
    letter-spacing: -0.3px;
    line-height: 18px;
    opacity: 0.4;
  }

  .footer_wrap .ft_menu.privacy li.accent a {
    font-weight: bold;
    text-decoration: underline;
    opacity: 0.7;
    font-size: 14px;
  }

  .footer_wrap.black .ft_menu.privacy li a {
    color: #000;
    opacity: 0.4;
  }

  .footer_wrap.black .ft_menu.privacy li.accent a {
    opacity: 0.7;
  }

  .footer_wrap .ft_menu.privacy:before {
    content: '';
    float: left;
    width: 1px;
    height: 12px;
    margin: 3px 17px 0;
    background-color: #fff;
    opacity: 0.25;
  }

  .nofix_footer .footer_wrap .ft_menu.privacy:before {
    background-color: #000;
  }

  .footer_wrap .copyright {
    float: right;
    margin-top: 3px;
    font-weight: 500;
    font-size: 13px;
    color: #fff;
    letter-spacing: -0.3px;
    opacity: 0.5;
    line-height: 18px;
    font-family: Museo_Sans_500, sans-serif;
  }

  .black_footer .footer_wrap .copyright,
  .footer_wrap.black .copyright {
    color: #000;
    opacity: 0.4;
  }

  .black_footer .footer_wrap .ft_menu.icon .webtoon a,
  .footer_wrap.black .ft_menu.icon .webtoon a {
    background-position: -355px -30px;
  }

  .black_footer .footer_wrap .ft_menu.icon .naver a,
  .footer_wrap.black .ft_menu.icon .naver a {
    background-position: -245px -30px;
  }

  .black_footer .footer_wrap .ft_menu.privacy:before,
  .footer_wrap.black .ft_menu.privacy:before {
    background-color: #000;
  }

  .black_footer .footer_wrap .ft_menu.privacy li a,
  .footer_wrap.black .ft_menu.privacy li a {
    color: #000;
    opacity: 0.4;
  }

  .footer_wrap.white .ft_menu.privacy li a {
    color: #fff;
  }

  .footer_wrap.white .ft_menu.privacy:before {
    background-color: #fff;
  }

  .footer_wrap.white .copyright {
    color: #fff;
  }

  .container {
    padding-bottom: 114px;
  }

  /* 메인 nofix 풋터 */
  footer {
    margin-top: -114px;
  }

  .nofix_footer .footer_wrap {
    overflow-x: auto;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    z-index: auto;
    background: #fff;
  }

  .nofix_footer .footer_wrap .ft_menu.privacy li a,
  .nofix_footer .footer_wrap.black .ft_menu.privacy li a {
    color: #000;
    opacity: 0.4;
  }

  .nofix_footer .footer_wrap .copyright,
  .nofix_footer .footer_wrap.black .copyright {
    color: #000;
    opacity: 0.4;
  }

  .nofix_footer .footer_wrap.white .copyright,
  .nofix_footer .footer_wrap.white .ft_menu.privacy li a {
    color: #fff;
    opacity: 0.5;
  }

  .nofix_footer.career .footer_wrap.white .ft_menu.icon .webtoon a {
    background-position: -155px 0;
  }

  .nofix_footer .footer_wrap.white .ft_menu.icon .naver a,
  .nofix_footer.career .footer_wrap.white .ft_menu.icon .naver a {
    background-position: -245px 0;
  }

  /* 커러이 nofix 풋터 */
  .nofix_footer.career .footer_wrap .ft_menu.icon .webtoon a {
    background-position: -355px -30px;
  }

  .nofix_footer.career .footer_wrap .ft_menu.icon .naver a {
    background-position: -245px -30px;
  }

  .nofix_footer .footer_wrap .ft_menu.privacy li.accent a,
  .nofix_footer .footer_wrap.black .ft_menu.privacy li.accent a {
    opacity: 0.7;
  }

  /* markdown */
  .markdown-wrap {
    overflow: visible;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: left;
  }

  .markdown-wrap.preview {
    border: 3px dotted #ccc;
  }

  .markdown-wrap .empty {
    color: #cecece;
  }

  .markdown-wrap blockquote,
  .markdown-wrap dl,
  .markdown-wrap ol,
  .markdown-wrap p,
  .markdown-wrap pre,
  .markdown-wrap table,
  .markdown-wrap ul {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: normal;
    line-height: 28px;
  }

  .markdown-wrap h1,
  .markdown-wrap h2,
  .markdown-wrap h3,
  .markdown-wrap h4,
  .markdown-wrap h5,
  .markdown-wrap h6 {
    margin-bottom: 16px;
    padding: 0;
    font-weight: 500;
  }

  .markdown-wrap h1 {
    font-size: 2.0em;
  }

  .markdown-wrap h2 {
    border-bottom: 1px solid #bbb;
    font-size: 1.5em;
  }

  .markdown-wrap h3 {
    font-size: 1.2em;
  }

  .markdown-wrap h4 {
    font-size: 1.2em;
  }

  .markdown-wrap h5 {
    font-size: 1.1em;
  }

  .markdown-wrap h6 {
    font-size: 1.0em;
  }

  .markdown-wrap hr {
    height: 1px;
    margin: 10px 0;
    border: 0;
    color: #ccc;
    background-color: #ccc;
  }

  .markdown-wrap p {
    margin: 28px 0 0;
    line-height: 28px;
  }

  .markdown-wrap p:first-child {
    margin-top: 15px;
  }

  .markdown-wrap blockquote {
    border-left: 4px solid #DDD;
    padding: 0 15px;
    color: #777;
  }

  .markdown-wrap blockquote p {
    font-size: 1em;
  }

  .markdown-wrap ol,
  .markdown-wrap ul {
    line-height: 20px;
  }

  .markdown-wrap ul {
    list-style: disc;
  }

  .markdown-wrap ol {
    list-style: decimal;
  }

  .markdown-wrap li {
    line-height: 1.9;
    border: 0 none;
    list-style-type: none;
  }

  .markdown-wrap ol>ul,
  .markdown-wrap ul>ul {
    list-style: circle;
  }

  .markdown-wrap ul ul,
  ol ol,
  ol ul,
  ol ul {
    list-style: square;
  }

  .markdown-wrap pre {
    background-color: #f8f8f8;
    padding: 10px;
    margin: 10px 0;
  }

  .markdown-wrap code {
    margin: 0;
    padding: 0;
    border: none;
  }

  .markdown-wrap table {
    border-collapse: collapse;
    margin: 15px;
  }

  .markdown-wrap table th {
    padding: 5px;
    border: 1px solid #dcddde;
    background-color: #f7f7f7;
  }

  .markdown-wrap table td {
    padding: 5px;
    border: 1px solid #dcddde;
  }

  .markdown-wrap a {
    display: inline-block !important;
  }

  .markdown-wrap a:hover {
    text-decoration: underline;
  }

  .markdown-wrap .note {
    font-size: 0.85em;
    margin: 5em 0 1em;
    padding: 12px 15px;
    border: 1px solid #e5e5e5;
  }

  .markdown-wrap .note h3 {
    margin-bottom: 0.5em;
    font-size: 1em;
  }

  .markdown-wrap .note ul {
    color: #777;
    margin-bottom: 0;
  }

  .markdown-wrap a.btn_appl_write {
    margin-top: 24px;
    display: inline-block;
    background: #000;
    position: relative;
    padding: 11px 25px;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.56;
  }

  .markdown-wrap a.btn_appl_write.off {
    background: #949496;
  }

  .about .footer_wrap .ft_menu.icon .webtoon a,
  .career .footer_wrap .ft_menu.icon .webtoon a,
  .contact .footer_wrap .ft_menu.icon .webtoon a {
    background-position: -355px -30px;
  }

  .about .footer_wrap .ft_menu.icon .naver a,
  .career .footer_wrap .ft_menu.icon .naver a,
  .contact .footer_wrap .ft_menu.icon .naver a {
    background-position: -245px -30px;
  }

  /* 서브 어바웃 */
  .sub_about_top .logo_box .logo {
    background: url("../img/sp_logo.png") 0 0 no-repeat;
    background-size: 750px 400px;
  }

  .sub_about_top .about_exp {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 74px 80px 0;
  }

  .sub_about_top h2 {
    font-weight: 900;
    font-size: 80px;
    line-height: 1;
    font-family: Museo_Slab_900, sans-serif;
  }

  .sub_about_top h3 {
    display: inline-block;
    margin-top: 78px;
    font-size: 28px;
    line-height: 30px;
  }

  .sub_about_top h3>span {
    width: 271px;
    height: 26px;
    background-position: 0 -72px;
  }

  .sub_about_top .sub_tit {
    width: 116px;
    height: 26px;
    background-position: 0 -72px;
  }

  .sub_about_top p {
    font-size: 18px;
    line-height: 1.56;
  }

  .sub_about_top p .spot {
    font-weight: 600;
  }

  .sub_about_top .logo_box {
    position: absolute;
    top: 307px;
    right: 83px;
  }

  .sub_about_top .logo_box .logo {
    display: inline-block;
    width: 107px;
    height: 106px;
    background-position: 0 -255px;
  }

  .sub_about_top .logo_box .logo.webtoon {
    width: 120px;
    height: 113px;
    margin-left: 69px;
    background-position: -125px -255px;
  }

  .sub_about_circle {
    position: absolute;
    width: 91px;
    height: 91px;
    top: -41px;
    right: -41px;
    z-index: 20;
  }

  .sub_about_circle span {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    border: 1px solid #000;
    animation: sub_about_circle 3s linear infinite;
  }

  .sub_about_circle span:nth-of-type(2) {
    animation: sub_about_circle 3s linear infinite 1s;
  }

  .sub_about_circle span:nth-of-type(3) {
    animation: sub_about_circle 3s linear infinite 2s;
  }

  .sub_about_middle {
    width: 1200px;
    margin: 112px auto 0;
    padding: 0 0 40px;
  }

  .sub_about_middle h3 {
    width: 319px;
    height: 26px;
    background: url(../img/sp_txt.png) 0 -107px no-repeat;
    background-size: 400px 400px;
  }

  .sub_about_middle h3 .sp_txt {
    display: none;
  }

  .sub_about_middle h3 .about_en_spot {
    font-weight: 900;
    font-size: 34px;
    line-height: 35px;
    vertical-align: top;
    font-family: Museo_Slab_900, sans-serif;
  }

  .sub_about_middle .about_middle_inner {
    max-width: none;
    padding: 0;
  }

  .sub_about_middle .lico_exp_box {
    margin-top: 30px;
  }

  .sub_about_middle .lico_exp_box ul {
    font-size: 0;
    text-align: center;
  }

  .sub_about_middle .lico_exp_box ul li {
    position: relative;
    display: inline-block;
    width: 330px;
    height: 330px;
    margin-left: -42px;
    margin-right: 0;
    background: url("../img/img_about_box.png") no-repeat;
    background-size: 330px 330px;
  }

  .sub_about_middle .lico_exp_box ul li:first-child {
    margin-left: 0;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner {
    width: 290px;
    height: 290px;
    text-align: center;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner strong {
    display: inline-block;
    margin-top: 90px;
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    font-family: Museo_Slab_900, sans-serif;
  }

  .sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner p {
    margin-top: 9px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.44;
  }

  .sub_about_bottom {
    overflow: hidden;
    max-width: 1200px;
    margin: 100px auto 0;
    padding: 0 80px 114px;
  }

  .sub_about_bottom h3 {
    margin-top: 0;
    margin-left: 0;
    width: 129px;
    height: 26px;
    background: url(../img/sp_txt.png) 0 -144px no-repeat;
    background-size: 400px 400px;
  }

  .sub_about_bottom h3 .sp_txt {
    display: none;
  }

  .sub_about_bottom .office_sub_txt {
    margin-top: 9px;
    font-size: 18px;
    line-height: 1.56;
  }

  .sub_about_bottom .office_img_slid_box {
    margin: 21px 0 42px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a {
    width: 80px;
    height: 80px;
    background: url("../img/sub_about_slide_btn.png") no-repeat;
    background-size: 80px 80px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide a>span {
    width: 25px;
    height: 42px;
    margin-top: 19px;
    background: url("../img/sp_ico.png") no-repeat;
    background-size: 150px 1000px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_prev>span {
    background-position: 0 -405px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .control_next>span {
    background-position: -30px -405px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst {
    height: 675px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst>div {
    height: 675px;
  }

  .container.sub_career .content .career_bg {
    background: url("../img/mo_sub_career_work_life.png") 50% 50% no-repeat;
    background-size: 100% auto;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1700px) {
  .section_inner.about .main_box .head_txt.type2 {
    line-height: 1.3;
    font-size: 100px;
  }
}

@media screen and (min-width:1920px) {
  /* list_box */

  .fullpage_wrapper.work .slider-for .slick-active.on .list_box {
    right: 260px;
    width: 561px;
    padding: 40px;
  }

  .tab-menu a {
    font-size: 24px;
  }

  .tab-menu a+a {
    margin-left: 24px;
  }

  .list_box strong {
    font-size: 24px;
  }

  .list_box .list {
    max-height: 360px;
    font-size: 20px;
  }

  .list_box dl+dl {
    margin-top: 12px;
  }

  .list_box .list_title {
    width: 165px;
    margin-right: 60px;
  }

  .btn_popup_close {
    font-size: 20px;
    line-height: 22px;
  }
}

@media screen and (max-width:666px) {
  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_750 {
    display: block;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_1334 {
    display: block;
    margin-top: 25px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation {
    margin-top: 24px;
    max-width: 375px;
    width: auto;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3 {
    width: auto;
    background: 0;
    font-weight: 900;
    font-size: 28px;
    line-height: 1.04;
    letter-spacing: -2.4px;
    font-family: Museo_Slab_900, sans-serif;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3 .tit_txt {
    display: block;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>p.career_exp_txt2 {
    margin-top: 22px;
  }

  .container.sub_career .content .career_middle {
    margin-top: 16px;
  }

  .container.sub_career .content .career_top .career_top_inner>h2 {
    font-size: 45px;
    line-height: 1.13;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>p {
    margin-top: 17px;
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: -0.4px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram {
    position: relative;
    top: auto;
    right: auto;
    width: 315px;
    height: 362px;
    margin: 12px 0 0;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle {
    width: 235px;
    height: 235px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap {
    width: 235px;
    height: 235px;
  }

  .container.sub_career .content .career_top .career_top_inner {
    max-width: 315px;
    padding: 110px 30px 0;
  }

  .container.sub_career .content .career_bottom .career_bottom_inner {
    max-width: 375px;
    padding: 0 30px;
  }

  .mo_careers {
    display: block;
  }

  .mo_careers h3 {
    margin-top: 43px;
    font-weight: bold;
    font-size: 23px;
    font-family: NotoSansB, sans-serif;
  }

  .mo_careers p {
    margin-top: 12px;
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: -0.6px;
  }

  .mo_careers .mo_careers_qesn {
    display: inline-block;
    width: 150px;
    height: 47px;
    margin: 20px 0 50px;
    background-color: #000;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    text-align: center;
    line-height: 47px;
  }

  .mo_careers .mo_careers_qesn .en {
    font-family: Museo_Sans_700, sans-serif;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .txt1 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .txt2 {
    display: block;
    font-size: 10px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_center {
    width: 71px;
    height: 68px;
    margin-top: -4px;
    background: url("../img/img_payer_750.png") no-repeat;
    background-size: 71px 68px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt {
    font-weight: 500;
    font-size: 10px;
    line-height: 1.3;
  }

  .container.sub_career .content .career_top {
    padding-bottom: 0;
  }

  .sub_header.career .header_wrap {
    display: none;
  }

  .sub_header.career .header_wrap.mobile {
    display: block;
  }

  .career .footer_wrap.pc {
    display: none;
  }

  .career .mo_footer {
    display: block;
  }

  .career .mo_footer .mo_footer {
    max-width: 375px;
    padding: 50px 30px 46px;
    margin: 0 auto;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: -0.3px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .en {
    font-weight: 500;
    font-size: 11px;
    line-height: 1.23;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.com {
    top: -20px;
    left: -39px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.know {
    top: 18px;
    left: 15px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.pasn {
    top: 71px;
    left: -15px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.crtv {
    top: 71px;
    left: -63px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.fun {
    top: 18px;
    left: -93px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt {
    width: 150px;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.new {
    top: 22px;
    left: 44px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.hori {
    top: 148px;
    left: -25px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.grown {
    top: 148px;
    right: -31px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.top {
    top: -35px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.left {
    top: 17px;
    left: -40px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.right {
    top: 17px;
    left: 37px;
  }

  /* 서브 커리어 WORK LIFE  */
  .container.sub_career .content .career_middle .work_life_box {
    display: block;
    position: relative;
    max-width: 375px;
    padding: 0 30px;
    margin: 0 auto;
  }

  .container.sub_career .content .career_middle .work_life_box .br {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box h3 {
    font-size: 28px;
    line-height: 31px;
  }

  .container.sub_career .content .career_middle .work_life_box p {
    margin: 14px 0 0;
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: -0.6px;
    word-break: keep-all;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list {
    margin-top: 19px;
    border: 1px solid #000;
    border-width: 1px 0;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list {
    background-image: none !important;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on {
    padding-bottom: 28px;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:before {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list:hover:after {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list+.list {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .container.sub_career .content .career_middle .work_life_box .list_title {
    padding: 29px 0 30px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_title .title_txt {
    font-size: 18px;
    line-height: 18px;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on .list_title {
    padding-bottom: 15px;
    color: #000;
  }

  .container.sub_career .content .career_middle .work_life_box .list .support_list {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box .list button:before {
    top: 31px;
    right: 0;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_more:before {
    width: 12px;
    height: 12px;
    background-position: 0 0;
    vertical-align: baseline;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_close:before {
    width: 10px;
    height: 10px;
    margin-bottom: -6px;
    background-position: -15px 0;
    vertical-align: baseline;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list {
    position: relative;
    padding-left: 9px;
    font-size: 13px;
    color: #000;
    line-height: 19px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #000;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on .list_content {
    padding: 0;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list+.list {
    margin-top: 10px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list:last-child {
    padding-bottom: 2px;
  }

  /* 서브 커리어 채용 */
  .recruit_progress {
    margin-top: 80px;
  }

  .recruit_progress>h3 {
    padding-bottom: 18px;
    font-size: 23px;
    line-height: 34px;
  }

  .recruit_progress>.progress_info {
    margin-top: 0;
  }

  .recruit_progress>.progress_info a {
    padding: 7px 17px 6px 18px;
    font-size: 14px;
    text-align: center;
  }

  .recruit_progress>.progress_info a:first-child {
    display: none;
  }

  .recruit_progress>ul {
    margin-bottom: 60px;
  }

  .recruit_progress>ul>li .recruit_board_title {
    padding: 18px 50px 19px 0;
  }

  .recruit_progress>ul>li .recruit_board_title .recruit_date {
    position: initial;
    display: block;
    font-size: 13px;
  }

  .recruit_progress>ul>li .recruit_board_title .crd_dday {
    display: none;
  }

  .recruit_progress>ul>li .recruit_board_title .title_txt {
    width: 100%;
    padding: 0;
    font-size: 15px;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow {
    right: 0;
    text-align: right;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow:before {
    width: 12px;
    height: 7px;
    margin-top: -4px;
    background-position: -15px -15px;
    vertical-align: middle;
  }

  .recruit_progress>ul>li.open .recruit_board_title .btn_arrow:before {
    background-position: 0 -15px;
  }

  .recruit_progress .recruit_board_content .markdown-wrap {
    font-size: 13px;
    padding: 20px 12px 28px;
    color: #353b42;
  }

  .markdown-wrap p {
    margin: 22px 0 0;
    line-height: 22px;
  }

  .markdown-wrap p:first-child {
    margin-top: 0;
  }

  .markdown-wrap li {
    line-height: 1.7;
    border: 0 none;
    list-style-type: none;
  }

  .markdown-wrap a.btn_appl_write {
    position: relative;
    display: inline-block;
    background: #000;
    margin-top: 20px;
    min-width: 100px;
    padding: 8px 16px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .markdown-wrap a.btn_appl_write.off {
    background: #949496;
  }
}

@media screen and (min-width:568px) and (max-height:320px) {
  .container.sub_career .content .career_bg {
    background-size: 100% auto;
  }
}

@media screen and (min-width:667px) and (max-width:767px) {
  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on {
    background-image: none !important;
    padding-bottom: 28px;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:before {
    display: none;
  }
}

@media screen and (min-width:667px) and (max-width:812px) {
  .sub_header.career .header_wrap {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner>h2 {
    font-size: 45px;
    line-height: 1.13;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3 {
    font-size: 28px;
    line-height: 1.04;
    letter-spacing: -0.4px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap {
    width: 350px;
    height: 350px;
  }

  .container.sub_career .content .career_middle .work_life_box h3 {
    font-size: 28px;
    line-height: 31px;
    ;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>p {
    margin-top: 18px;
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: -0.4px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: 500px;
    height: 495px;
    margin: 0 auto;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_center {
    width: 96px;
    height: 92px;
    margin-top: 8px;
    background: url("../img/img_payer_1334_2048.png") no-repeat;
    background-size: 96px 92px;
  }

  .container.sub_career .content .career_top {
    padding-bottom: 54px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle {
    width: 350px;
    height: 350px;
  }

  .container.sub_career .content .career_bottom .career_bottom_inner {
    width: auto;
    padding: 0 30px;
  }

  .container.sub_career .content .career_top .career_top_inner {
    width: auto;
    padding: 85px 30px 0;
  }

  .mo_careers {
    display: block;
  }

  .mo_careers h3 {
    margin-top: 38px;
    font-weight: bold;
    font-size: 23px;
    font-family: NotoSansB, sans-serif;
  }

  .mo_careers p {
    margin-top: 12px;
    font-size: 15px;
    line-height: 1.65;
    letter-spacing: -0.1px;
  }

  .mo_careers .mo_careers_qesn {
    display: inline-block;
    width: 150px;
    height: 47px;
    margin: 20px 0 50px;
    background-color: #000;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    text-align: center;
    line-height: 47px;
    font-family: Museo_Slab_700, sans-serif;
  }

  .mo_careers .mo_careers_qesn .en {
    font-family: Museo_Sans_700, sans-serif;
  }

  .sub_header.career .header_wrap.mobile {
    display: block;
  }

  .career .footer_wrap.pc {
    display: none;
  }

  .career .mo_footer {
    display: block;
  }

  .nofix_header.career .mo_footer {
    display: block;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.83;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: -0.3px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .en {
    display: inline-block;
    margin-top: 2px;
    font-weight: 500;
    font-size: 11px;
    line-height: 1.23;
  }

  .container.sub_career .content .career_bg {
    background-size: 100% auto;
    background-position-y: 85%
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.com {
    top: -41px;
    left: -27px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.know {
    top: 27px;
    left: 55px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.pasn {
    top: 95px;
    left: 10px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.crtv {
    top: 95px;
    left: -70px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.fun {
    top: 27px;
    left: -109px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt {
    line-height: 17px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.new {
    top: 38px;
    left: 130px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.hori {
    top: 223px;
    left: 29px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.grown {
    top: 223px;
    right: 29px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.top {
    top: -40px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.left {
    top: 40px;
    left: -47px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.right {
    top: 40px;
    left: 47px;
  }

  /* 서브 커리어 WORK LIFE  */
  .container.sub_career .content .career_top .career_top_inner .career_explanation {
    width: auto;
    margin-top: 53px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_1334 {
    display: block;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_1334 {
    display: block;
    margin-top: 26px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3 {
    background: 0;
    font-weight: 900;
    font-size: 28px;
    line-height: 1.04;
    letter-spacing: -0.4px;
    font-family: Museo_Slab_900, sans-serif;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3 .tit_txt {
    display: block;
  }

  /* 서브 커리어 WORK LIFE  */
  .recruit_progress>ul {
    margin-bottom: 60px;
  }

  .container.sub_career .content .career_middle .work_life_box {
    display: block;
    position: relative;
    padding: 0 30px;
    margin: 0 auto;
  }

  .container.sub_career .content .career_middle .work_life_box p {
    margin: 13px 0 0;
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: -0.6px;
    word-break: keep-all;
  }

  .container.sub_career .content .career_middle .work_life_box .br {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list {
    margin-top: 20px;
    border: 1px solid #000;
    border-width: 1px 0;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on {
    padding-bottom: 28px;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list:hover:after {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list+.list {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .container.sub_career .content .career_middle .work_life_box .list_title {
    position: relative;
    display: block;
    width: 100%;
    padding: 29px 0;
    border: 0;
    background: none;
    outline: none;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on .list_title {
    padding-bottom: 15px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_title .title_txt {
    font-size: 18px;
    line-height: 18px;
    color: #000;
  }

  .container.sub_career .content .career_middle .work_life_box .list .support_list {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box .list button:before {
    top: 34px;
    right: 0;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_more:before {
    width: 12px;
    height: 12px;
    background-position: 0 0;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_close:before {
    width: 10px;
    height: 10px;
    margin-bottom: -8px;
    background-position: -15px 0;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content {
    padding: 0;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list {
    position: relative;
    padding-left: 9px;
    font-size: 13px;
    color: #000;
    line-height: 19px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #000;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list+.list {
    margin-top: 10px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list:last-child {
    padding-bottom: 2px;
  }

  /* 서브 커리어 채용 */
  .recruit_progress {
    margin-top: 80px;
  }

  .recruit_progress>h3 {
    padding-bottom: 18px;
    font-size: 23px;
    line-height: 34px;
  }

  .recruit_progress>.progress_info {
    margin-top: 0;
  }

  .recruit_progress>.progress_info a {
    padding: 7px 17px 6px 18px;
    font-size: 14px;
    text-align: center;
  }

  .recruit_progress>.progress_info a:first-child {
    display: none;
  }

  .recruit_progress>ul>li .recruit_board_title {
    padding: 18px 50px 17px 0;
  }

  .recruit_progress>ul>li .recruit_board_title .recruit_date {
    position: initial;
    display: block;
    margin-top: 2px;
    font-size: 13px;
  }

  .recruit_progress>ul>li .recruit_board_title .crd_dday {
    display: none;
  }

  .recruit_progress>ul>li .recruit_board_title .title_txt {
    width: 100%;
    padding: 0;
    font-size: 15px;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow {
    right: 0;
    text-align: right;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow:before {
    width: 12px;
    height: 7px;
    margin-top: -6px;
    background-position: -15px -15px;
    vertical-align: middle;
  }

  .recruit_progress>ul>li.open .recruit_board_title .btn_arrow:before {
    background-position: 0 -15px;
  }

  .recruit_progress .recruit_board_content {
    border-top: 1px solid #e4e4e4;
    background: #f4f5fa;
  }

  .recruit_progress .recruit_board_content .markdown-wrap {
    font-size: 13px;
    padding: 19px 14px 28px;
    color: #353b42;
  }

  .markdown-wrap p {
    margin: 22px 0 0;
    line-height: 22px;
  }

  .markdown-wrap p:first-child {
    margin-top: 0;
  }

  .markdown-wrap li {
    line-height: 1.7;
    border: 0 none;
    list-style-type: none;
  }

  .markdown-wrap a.btn_appl_write {
    position: relative;
    display: inline-block;
    background: #000;
    margin-top: 20px;
    min-width: 100px;
    padding: 8px 16px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .markdown-wrap a.btn_appl_write.off {
    background: #949496;
  }
}

@media screen and (min-width:768px) and (max-width:1023px) and (min-height:450px) {
  .container.sub_career .content .career_bg {
    background-size: auto 100%;
    background-position-y: 50%;
  }

  .container.sub_career .content .career_middle .work_life_box h3 {
    font-size: 28px;
    line-height: 29px;
    letter-spacing: -0.4px;
  }

  .sub_header.career .header_wrap {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3 {
    background: 0;
    font-weight: 900;
    font-size: 28px;
    line-height: 1.04;
    letter-spacing: -0.4px;
    font-family: Museo_Slab_900, sans-serif;
  }

  .container.sub_career .content .career_top .career_top_inner>h2 {
    font-size: 50px;
    line-height: 1.02;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation {
    width: auto;
    margin-top: 41px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>p {
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: -0.1px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_1334 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_1334 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_1536 {
    display: block;
    margin-top: 17px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_1536 {
    display: block;
    margin-top: 27px;
  }

  .container.sub_career .content .career_top {
    padding-bottom: 77px;
  }

  .container.sub_career .content .career_top .career_top_inner {
    max-width: 767px;
    padding: 108px 55px 0;
  }

  .container.sub_career .content .career_bottom .career_bottom_inner {
    max-width: 767px;
    padding: 0 55px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle {
    width: 401px;
    height: 401px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.top {
    top: -63px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.left {
    top: 28px;
    left: -53px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.right {
    top: 28px;
    left: 55px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap {
    width: 401px;
    height: 401px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_center {
    width: 110px;
    height: 105px;
    margin-top: -9px;
    background: url("../img/img_payer_1536.png") no-repeat;
    background-size: 110px 105px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram {
    position: relative;
    top: auto;
    right: auto;
    width: auto;
    height: 500px;
    margin: 54px auto 0;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2 {
    display: none;
  }

  .mo_careers {
    display: block;
  }

  .mo_careers h3 {
    margin-top: 53px;
    font-weight: bold;
    font-size: 23px;
    font-family: NotoSansB, sans-serif;
  }

  .mo_careers p {
    margin-top: 12px;
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: -0.1px;
  }

  .mo_careers .mo_careers_qesn {
    display: inline-block;
    width: 150px;
    height: 47px;
    margin: 20px 0 70px;
    background-color: #000;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    text-align: center;
    line-height: 47px;
    font-family: Museo_Sans_700, sans-serif;
  }

  .mo_careers .mo_careers_qesn .en {
    font-family: Museo_Sans_700, sans-serif;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3>.tit_txt {
    display: block;
  }

  .sub_header.career .header_wrap.mobile {
    display: block;
    padding-bottom: 14px;
  }

  .career .footer_wrap.pc {
    display: none;
  }

  .career .mo_footer {
    display: block;
  }

  .nofix_header .mo_footer {
    display: block;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .en {
    display: inline-block;
    margin-top: 2px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.27;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.46;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.com {
    top: -45px;
    left: -19px;
    min-height: auto;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.know {
    top: 31px;
    left: 76px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.pasn {
    top: 110px;
    left: 19px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.crtv {
    top: 110px;
    left: -67px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.fun {
    top: 31px;
    left: -113px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.new {
    top: 43px;
    left: 149px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.hori {
    top: 255px;
    left: 33px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.grown {
    top: 255px;
    right: 33px;
  }

  /* 서브 커리어 WORK LIFE  */
  .container.sub_career .content .career_middle .work_life_box {
    display: block;
    position: relative;
    padding: 0 55px;
    margin: 0 auto;
  }

  .container.sub_career .content .career_middle .work_life_box .br {
    display: block;
  }

  .container.sub_career .content .career_middle .work_life_box p {
    margin: 17px 0 0;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.1px;
    word-break: keep-all;
  }

  .container.sub_career .content .career_middle .work_life_box .tb_br {
    display: block;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list {
    margin-top: 28px;
    border: 1px solid #000;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list:hover:after {
    display: none;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on {
    padding-bottom: 24px;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(1) {
    background-image: url('../img/img_work_life_tablet_01.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(2) {
    background-image: url('../img/img_work_life_tablet_02.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(3) {
    background-image: url('../img/img_work_life_tablet_03.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(4) {
    background-image: url('../img/img_work_life_tablet_04.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list+.list {
    border-top: 1px solid #000;
  }

  .container.sub_career .content .career_middle .work_life_box .list_title {
    padding: 27px 30px 28px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_title .title_txt {
    font-size: 20px;
    line-height: 20px;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on .list_title .title_txt {
    color: #fff;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on .list_title {
    padding-bottom: 15px;
  }

  .container.sub_career .content .career_middle .work_life_box .list .support_list {
    display: block;
    margin: 17px 0 0;
  }

  .container.sub_career .content .career_middle .work_life_box .list .support_list .item {
    font-size: 16px;
    line-height: 16px;
  }

  .container.sub_career .content .career_middle .work_life_box .list button:before {
    position: absolute;
    top: 29px;
    right: 29px;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_more:before {
    width: 18px;
    height: 18px;
    background-position: 0 -84px;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_close:before {
    width: 15px;
    height: 15px;
    background-position: -21px -84px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content {
    padding: 0 30px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list {
    position: relative;
    padding-left: 9px;
    font-size: 16px;
    color: #fff;
    line-height: 22px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list+.list {
    margin-top: 12px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #fff;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list:last-child {
    padding-bottom: 2px;
  }

  /* 서브 커리어 채용 */
  .recruit_progress {
    margin-top: 99px;
  }

  .recruit_progress>h3 {
    padding-bottom: 19px;
    font-size: 23px;
    line-height: 34px;
  }

  .recruit_progress>.progress_info {
    margin-top: 0;
  }

  .recruit_progress>.progress_info a {
    padding: 7px 17px 6px 18px;
    font-size: 14px;
    text-align: center;
  }

  .recruit_progress>.progress_info a:first-child {
    display: none;
  }

  .recruit_progress>ul {
    margin-bottom: 60px;
  }

  .recruit_progress>ul>li .recruit_board_title {
    padding: 22px 50px 21px 0;
  }

  .recruit_progress>ul>li .recruit_board_title .recruit_date {
    position: initial;
    display: block;
    margin-top: 9px;
    font-size: 13px;
    line-height: 13px;
  }

  .recruit_progress>ul>li .recruit_board_title .crd_dday {
    display: none;
  }

  .recruit_progress>ul>li .recruit_board_title .title_txt {
    width: 100%;
    padding: 0;
    font-size: 17px;
    line-height: 21px;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow {
    right: 0;
    width: 78px;
    text-align: center;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow:before {
    width: 18px;
    height: 11px;
    margin-top: -4px;
    background-position: -21px -25px;
    vertical-align: middle;
  }

  .recruit_progress>ul>li.open .recruit_board_title .btn_arrow:before {
    background-position: 0 -25px;
  }

  .recruit_progress .recruit_board_content {
    border-top: 1px solid #e4e4e4;
    background: #f4f5fa;
  }

  .recruit_progress .recruit_board_content .markdown-wrap {
    font-size: 13px;
    padding: 19px 14px 28px;
    color: #353b42;
  }

  .markdown-wrap p {
    margin: 22px 0 0;
    line-height: 22px;
  }

  .markdown-wrap p:first-child {
    margin-top: 0;
  }

  .markdown-wrap li {
    line-height: 1.7;
    border: 0 none;
    list-style-type: none;
  }

  .markdown-wrap a.btn_appl_write {
    position: relative;
    display: inline-block;
    background: #000;
    margin-top: 20px;
    min-width: 100px;
    padding: 8px 16px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .markdown-wrap a.btn_appl_write.off {
    background: #949496;
  }
}

@media screen and (min-width:1024px) and (max-width: 1280px) {
  .container {
    padding-bottom: 199px;
  }

  /* 미디어쿼리 풋터 */
  footer {
    margin-top: -199px;
  }

  .container.sub_career .content .career_top .career_top_inner>h2 {
    font-weight: 900;
    font-size: 50px;
    line-height: 1.02;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3 {
    background: 0;
    font-weight: 900;
    font-size: 28px;
    line-height: 1.04;
    letter-spacing: -0.4px;
    font-family: Museo_Slab_900, sans-serif;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>p {
    margin-top: 17px;
    font-weight: normal;
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: -0.1px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1,
  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2 {
    display: none;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_2048 {
    display: block;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_2048 {
    display: block;
    margin-top: 29px;
  }

  .mo_careers {
    display: block;
    max-width: 1024px;
    margin: 0 auto;
  }

  .mo_careers h3 {
    margin-top: 73px;
    font-weight: bold;
    font-size: 23px;
    font-family: NotoSansB, sans-serif;
  }

  .mo_careers p {
    margin-top: 13px;
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: -0.1px;
  }

  .mo_careers .mo_careers_qesn {
    display: inline-block;
    width: 150px;
    height: 46px;
    margin: 20px 0 70px;
    background-color: #000;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    text-align: center;
    line-height: 47px;
    font-family: Museo_Sans_700, sans-serif;
  }

  .mo_careers .mo_careers_qesn .en {
    font-family: Museo_Sans_700, sans-serif;
  }

  .container.sub_career .content .career_middle .career_middle_inner {
    padding: 0 55px;
  }

  .container.sub_career .content .career_bottom .career_bottom_inner {
    width: auto;
    padding: 0 55px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation {
    margin-top: 40px;
    width: 430px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram {
    top: 87px;
    right: 31px;
    width: 500px;
    height: 500px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle {
    width: 351px;
    height: 351px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_center {
    width: 96px;
    height: 92px;
    background: url("../img/img_payer_1334_2048.png") no-repeat;
    background-size: 96px 92px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt {
    min-height: auto;
    font-weight: 500;
    font-size: 11px;
    line-height: 1.45;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .txt1 {
    display: inline-block;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .en {
    display: inline-block;
    margin-top: 3px;
    font-weight: 500;
    font-size: 11px;
    color: #000;
    line-height: 1.3;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt {
    font-weight: normal;
    font-size: 12px;
    line-height: 1.5;
    font-family: NotoSansM, sans-serif;
  }

  .container.sub_career .content .career_top {
    min-height: 535px;
  }

  .container.sub_career .content .career_top .career_top_inner {
    max-width: 1024px;
    padding: 110px 55px 0;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap {
    width: 351px;
    height: 351px;
  }

  .sub_header.career .header_wrap {
    display: none;
  }

  .sub_header.career .header_wrap.mobile {
    display: block;
    padding-bottom: 14px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>h3>.tit_txt {
    display: block;
  }

  .career .footer_wrap.pc {
    display: none;
    width: 1024px;
  }

  .career .mo_footer {
    display: block;
  }

  .container.sub_career .content .career_bg {
    background-size: 100% auto;
  }

  .container.sub_career .content .career_middle .career_middle_mask {
    display: block;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.com {
    top: -38px;
    left: -27px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.know {
    top: 30px;
    left: 55px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.pasn {
    top: 97px;
    left: 8px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.crtv {
    top: 97px;
    left: -69px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.fun {
    top: 30px;
    left: -108px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.new {
    top: 37px;
    left: 131px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.hori {
    top: 221px;
    left: 30px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.grown {
    top: 221px;
    right: 30px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.top {
    top: -46px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.left {
    top: 34px;
    left: -47px
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.right {
    top: 34px;
    left: 47px;
  }

  /* 서브 커리어 WORK LIFE */
  .container.sub_career .content .career_middle .work_life_box h3 {
    font-size: 28px;
    line-height: 29px;
    letter-spacing: -0.4px;
  }

  .container.sub_career .content .career_middle .work_life_box p {
    margin-top: 14px;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list {
    margin-top: 28px;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on {
    padding-bottom: 26px;
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(1) {
    background-image: url('../img/img_work_life_1024_01.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(2) {
    background-image: url('../img/img_work_life_1024_02.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(3) {
    background-image: url('../img/img_work_life_1024_03.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(4) {
    background-image: url('../img/img_work_life_1024_04.jpg');
  }

  .container.sub_career .content .career_middle .work_life_box .list .support_list .item+.item {
    margin-left: 15px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_title {
    padding: 26px 90px 27px 31px;
  }

  .container.sub_career .content .career_middle .work_life_box .list.on .list_title {
    padding-bottom: 21px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_title .title_txt {
    font-size: 20px;
    line-height: 20px;
  }

  .container.sub_career .content .career_middle .work_life_box .list .support_list .item {
    font-size: 16px;
    letter-spacing: -0.71px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list {
    padding-left: 9px;
    font-size: 16px;
  }

  .container.sub_career .content .career_middle .work_life_box .list .support_list {
    margin-left: 15px;
  }

  .container.sub_career .content .career_middle .work_life_box .list button:before {
    top: 28px;
    right: 29px;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_more:before {
    width: 18px;
    height: 18px;
    background-position: 0 -84px;
  }

  .container.sub_career .content .career_middle .work_life_box .list .btn_close:before {
    width: 15px;
    height: 15px;
    background-position: -21px -84px;
  }

  .container.sub_career .content .career_middle .work_life_box .list_content {
    padding: 0 29px
  }

  .container.sub_career .content .career_middle .work_life_box .list_content .list+.list {
    margin-top: 18px;
  }

  /* 서브 커리어 채용 */
  .recruit_progress {
    margin-top: 99px;
  }

  .recruit_progress>h3 {
    padding-bottom: 19px;
    font-size: 23px;
    line-height: 34px;
  }

  .recruit_progress>.progress_info {
    margin-top: 0;
  }

  .recruit_progress>.progress_info a {
    padding: 7px 17px 6px 18px;
    font-size: 14px;
    text-align: center;
  }

  .recruit_progress>.progress_info a:first-child {
    display: none;
  }

  .recruit_progress>ul {
    margin-bottom: 60px;
  }

  .recruit_progress>ul>li .recruit_board_title {
    padding: 18px 50px 19px 0;
  }

  .recruit_progress>ul>li .recruit_board_title .recruit_date {
    position: initial;
    display: block;
    margin-top: 5px;
    font-size: 13px;
  }

  .recruit_progress>ul>li .recruit_board_title .crd_dday {
    display: none;
  }

  .recruit_progress>ul>li .recruit_board_title .title_txt {
    width: 100%;
    padding: 0;
    font-size: 17px;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow {
    right: 0;
    width: 78px;
    text-align: center;
  }

  .recruit_progress>ul>li .recruit_board_title .btn_arrow:before {
    width: 18px;
    height: 11px;
    margin-top: -4px;
    background-position: -21px -25px;
    vertical-align: middle;
  }

  .recruit_progress>ul>li.open .recruit_board_title .btn_arrow:before {
    background-position: 0 -25px;
  }

  .recruit_progress .recruit_board_content {
    border-top: 1px solid #e4e4e4;
    background: #f4f5fa;
  }

  .recruit_progress .recruit_board_content .markdown-wrap {
    font-size: 13px;
    padding: 19px 16px 28px;
    color: #353b42;
  }

  .markdown-wrap p {
    margin: 22px 0 0;
    line-height: 22px;
  }

  .markdown-wrap p:first-child {
    margin-top: 0;
  }

  .markdown-wrap li {
    line-height: 1.7;
    border: 0 none;
    list-style-type: none;
  }

  .markdown-wrap a.btn_appl_write {
    position: relative;
    display: inline-block;
    background: #000;
    margin-top: 20px;
    min-width: 100px;
    padding: 8px 16px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .markdown-wrap a.btn_appl_write.off {
    background: #949496;
  }
}

@media screen and (min-width:1024px) and (max-width:1280px) and (min-height:1447px) {
  .container.sub_career .content .career_bg {
    max-height: 1296px;
  }
}

@media screen and (max-width:374px) and (max-height:568px) {

  /* 메인페이지 */
  .section_inner .main_box .head_txt,
  .section_inner .main_box .head_txt.sub {
    font-size: 45px;
  }

  .section_inner .main_box .sub_txt_box {
    margin-top: -10px;
  }

  .section_inner .main_box .sub_txt_box .sub_txt {
    font-size: 17.5px;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt {
    display: none;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type3 {
    display: block;
    margin-top: 0;
  }

  .section_inner .main_box .btn_box a {
    width: 108px;
    height: 33px;
    font-size: 12px;
    line-height: 33px;
  }

  .btn_scroll_down {
    bottom: 20px;
  }

  .section.contact .contact_inner .contact_bottom .img_lico_map {
    width: 100%;
    background-size: 100% auto;
  }

  /* 서브페이지 */
  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation {
    top: 87px;
  }

  .fullpage_wrapper.work h3 {
    margin-top: 5px;
  }

  .fullpage_wrapper.work .webtoon_sub {
    margin-top: 7px;
  }

  .fullpage_wrapper.work .btn_box {
    margin-top: 15px;
  }

  .fullpage_wrapper.work .btn_box li {
    margin-top: 12px;
  }

  .fullpage_wrapper.work .work_button_prev {
    bottom: 76px;
  }

  .fullpage_wrapper.work .work_button_next {
    bottom: 76px;
  }

  .container.sub_career .content .career_top .career_top_inner .career_explanation>p {
    margin-top: 35px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_center {
    width: 59px;
    height: 57px;
    background: url(../img/img_payer_640.png) no-repeat;
    background-size: 59px auto;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.new {
    left: 25px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.hori {
    top: 120px;
    left: -35px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.grown {
    top: 120px;
    right: -37px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.com {
    top: -15px;
    left: -44px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.fun {
    top: 15px;
    left: -90px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.know {
    top: 15px;
    left: -1px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram {
    width: 290px;
    margin-left: -15px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle {
    width: 193px;
    height: 193px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.top {
    top: -11px;
    left: 20px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.left {
    top: 39px;
    left: -11.5px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.right {
    top: 39px;
    left: 53.5px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt, .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .txt2, .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt {
    font-size: 8px;
    line-height: 11.5px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.crtv {
    top: 60.5px;
    left: -65px;
  }

  .container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.pasn {
    top: 60.5px;
    left: -25px;
  }

  /* .container.sub_career .content .career_middle .work_life_box2{
		top: -185px;
	} */
  /* .container.sub_career .content .career_middle .work_life_box2 h3{
		width: auto;
	} */
  .cont_wrap {
    min-width: 260px;
  }

  .cont_wrap.ethics .ethics_table .tbl.tbl_type .middle th {
    padding-right: 20px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst {
    height: 213px;
  }

  .sub_about_bottom .office_img_slid_box .office_slide .offic_img_lst div {
    height: 213px;
  }
}

@media screen and (max-width:568px) and (max-height:320px) {
  .section_inner .main_box .head_txt.type2 {
    margin-bottom: -10px;
  }

  .section_inner .main_box .sub_txt_box .sub_txt {
    font-size: 17.5px;
  }

  .section_inner .main_box .sub_txt_box .explanation_txt.type2 {
    margin-top: 2px;
  }

  .section_inner .main_box .btn_box a {
    margin-top: 18px;
  }

  .section.contact .contact_inner .contact_bottom .img_lico_map {
    width: 100%;
    background-size: 100% auto;
  }

  .sub_about_top .logo_box .logo.webtoon {
    margin-left: 10px;
  }

  .cont_wrap.ethics .ethics_table .tbl.tbl_type .middle th {
    padding-right: 20px;
  }
}

@media screen and (max-width:440px) {
  .mobile_block {
    display: block;
  }

  .fullpage_wrapper.work .slide .vegetable_garden .br_responsive {
    display: block;
  }
}

/* 아이폰 x 세로 */
@media screen and (max-width : 375px) and (max-height : 812px) {
  .container.sub_career .content .career_top .career_top_inner {
    margin-left: env(safe-area-inset-left);
    margin-left: constant(safe-area-inset-left);
    margin-right: env(safe-area-inset-left);
    margin-right: constant(safe-area-inset-left);
  }
}

/* 아이폰 x 가로 */
@media screen and (max-width : 812px) and (max-height : 375px) {
  .container.sub_career .content .career_top .career_top_inner {
    margin-left: env(safe-area-inset-left);
    margin-left: constant(safe-area-inset-left);
    margin-right: env(safe-area-inset-left);
    margin-right: constant(safe-area-inset-left);
  }
}

@media screen and (max-height: 449px) and (max-width: 812px) and (min-width: 568px) {
  .contact_lico_map {
    height: 300px;
  }

  .contact_lico_map #mapContainer {
    height: 300px;
  }

  .section.contact .contact_inner {
    padding-top: 90px;
    padding-bottom: 256px;
  }

  .contact_method .pc_show.mo_show {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .contact_lico_map {
    height: 300px;
    margin: 36px -55px 0;
  }

  .contact_lico_map #mapContainer {
    height: 300px;
  }

  .section.contact .contact_inner .contact_method {
    margin-top: 47px;
  }

  .contact_method .pc_show.mo_show {
    display: none;
  }
}

@media screen and (min-width: 1280px) {

  .sub_contact {
    position: relative;
  }

  .contact_bottom {
    padding-top: 548px;
  }

  .contact_lico_map {
    position: absolute;
    top: 496px;
    left: 0;
    right: 0;
    height: 460px;
    margin: 0;
  }

  .contact_lico_map #mapContainer {
    height: 460px;
  }

  .section.contact .contact_inner .btn_naver_map {
    display: none;
  }

  .section.contact .contact_inner .contact_bottom,
  .section.contact .contact_inner .contact_bottom .contact_method {
    margin-top: 0;
  }

  .section.contact .contact_inner .contact_bottom .contact_method {
    overflow: hidden;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li {
    float: left;
    width: 380px;
    margin-top: 0;
    padding: 0 15px;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li:first-child {
    padding-left: 0;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li:last-child {
    padding-right: 0;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li+li:before {
    display: none;
  }

  .section.contact .contact_inner .contact_bottom .contact_method li p {
    min-height: 84px;
  }

  .contact_method .pc_show.mo_show {
    display: block;
  }
}
