.fullpage_wrapper.work {
  height: 100%;
  font-size: 0;
  overflow: hidden;
}

.fullpage_wrapper.work .container {
  height: 100%;
  font-size: 0;
  padding-bottom: 0;
}

.fullpage_wrapper.work .slide {
  position: relative;
  display: inline-block;
  width: 100%;
}

.fullpage_wrapper.work .slider-nav .slide {
  display: none;
}

.fullpage_wrapper.work .slider-nav .slide.slick-slide {
  display: inline-block;
}

.fullpage_wrapper.work .slide .slide_inner {
  position: relative;
}

.fullpage_wrapper.work .slide .slide_inner .webtoon_explanation {
  position: absolute;
  top: 121px;
  left: 30px;
  padding-left: env(safe-area-inset-left);
  padding-left: constant(safe-area-inset-left);
}

.fullpage_wrapper.work .slide .ghost_theater .br {
  display: none;
}

.fullpage_wrapper.work .slide .vegetable_garden .br_responsive {
  display: none;
}

.fullpage_wrapper.work h3.webtoon_tit span.sub-tit {
  display: block;
  margin-top: 5px;
  font-family: Museo_Slab_900, sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.fullpage_wrapper.work .slide21 .webtoon_sub {
  margin-top: 9px;
}

@media screen and (min-width: 568px) and (max-height: 479px) {
  .fullpage_wrapper.work .slide .mood_for_love .br_responsive {
    display: none;
  }
}

@media screen and (min-width: 768px) and (min-height: 480px) {
  .fullpage_wrapper.work .slide .today_love .br_responsive {
    display: none;
  }

  .fullpage_wrapper.work .slide .ghost_theater .br_responsive {
    display: none;
  }

  .fullpage_wrapper.work .slide .ghost_theater .br {
    display: block;
  }

  .fullpage_wrapper.work h3.webtoon_tit span.sub-tit {
    margin-top: 0;
    font-size: 28px;
  }

  .fullpage_wrapper.work .slide21 .webtoon_sub {
    margin-top: 7px;
  }
}

@media screen and (min-width: 1024px) and (min-height: 550px) {
  .fullpage_wrapper.work .slide .today_love .br_responsive {
    display: block;
  }

  .fullpage_wrapper.work .slide .ghost_theater .br {
    display: none;
  }

  .fullpage_wrapper.work .slide .ghost_theater .br_responsive {
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .fullpage_wrapper.work .slide .ghost_theater .br_responsive {
    display: none;
  }

  .fullpage_wrapper.work .slide .ghost_theater .br {
    display: block;
  }

  .fullpage_wrapper.work h3.webtoon_tit span.sub-tit {
    display: block;
    margin-top: 22px;
    font-family: Museo_Slab_900, sans-serif;
    font-weight: 500;
    font-size: 34px;
  }

  .fullpage_wrapper.work .slide21 .webtoon_sub {
    margin-top: 28px;
  }
}

.fullpage_wrapper.work .work_button_next {
  position: fixed;
  top: auto;
  bottom: 19%;
  right: 30px;
  z-index: 30;
  width: 24px;
  height: 57px;
  background-position: -31px 6px;
  margin-right: env(safe-area-inset-left);
  margin-right: constant(safe-area-inset-left);
}

.fullpage_wrapper.work .work_button_next.off {
  background-position: -31px -56px;
}

.fullpage_wrapper.work .work_button_prev {
  position: fixed;
  top: auto;
  bottom: 19%;
  left: 30px;
  z-index: 30;
  width: 24px;
  height: 57px;
  background-position: 0 6px;
  margin-left: env(safe-area-inset-left);
  margin-left: constant(safe-area-inset-left);
}

.fullpage_wrapper.work .work_button_prev.off {
  background-position: 0 -56px;
}

.fullpage_wrapper.work h2 {
  font-weight: 900;
  font-size: 45px;
  line-height: 37px;
  font-family: Museo_Slab_900, sans-serif;
  font-weight: normal;
}

.fullpage_wrapper.work h2.separation {
  display: none;
}

.fullpage_wrapper.work h3 {
  margin-top: 23px;
  font-size: 24px;
  line-height: 24px;
}

.fullpage_wrapper.work .webtoon_sub {
  margin-top: 11px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.fullpage_wrapper.work .webtoon_sub.mo_off {
  display: none;
}

.fullpage_wrapper.work .sub_small {
  display: inline-block;
  margin-top: 1px;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.36;
  vertical-align: top;
  font-family: Museo_Sans_500, sans-serif;
}

.fullpage_wrapper.work .btn_box.mo_off {
  display: none;
}

.fullpage_wrapper.work .btn_box {
  margin-top: 16px;
}

.fullpage_wrapper.work .btn_box li.pc_only {
  display: none;
}

.fullpage_wrapper.work .btn_box li .btn_work_list {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  line-height: 14px;
  vertical-align: top;
}

.fullpage_wrapper.work .btn_box li .btn_work_list:after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 11px;
  margin: 3px 0 0 1px;
  background: url(../img/mobile/sp_arrow.png) -93px -688px no-repeat;
  background-size: 100px 900px;
  vertical-align: top;
}

.fullpage_wrapper.work .slider-for .slick-active.on .btn_box .btn_work_list {
  border-bottom: 1px solid #000;
}

.fullpage_wrapper.work .btn_box li span.btn_work_list:after {
  display: none;
}

@media screen and (min-width: 768px) {
  .br_mo {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .br_pc {
    display: none;
  }

  .br_mo {
    display: block;
  }
}

/* list_box 375일때 */

.fullpage_wrapper.work .slider-for .slick-active .list_box {
  display: none;
}

/* 세로 */
.fullpage_wrapper.work .slider-for .slick-active.on .list_box {
  display: block;
  position: absolute;
  top: 235px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.85);
  padding: 16px;
  width: 80vw;
  max-width: 303px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.inner {
  position: relative;
}
