.loading_box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: #000;
}

.loading_box img {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 210;
  transform: translate(-50%, -50%);
}

.loading_box .loading_750 {
  display: block;
}
