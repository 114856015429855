.mo_least_show {
  display: none;
}

.mo_show2 {
  display: none;
}

.mo_show {
  display: block;
}

.tablet_show {
  display: none;
}

.tablet_show2 {
  display: none;
}

.tablet_show3 {
  display: none;
}

.tablet_show_md {
  display: none;
}

.pc_show {
  display: none;
}

.mo_show.pc_show.mo_least_show {
  display: block;
}

@media screen and (max-width: 374px) {
  .mo_show {
    display: none;
  }

  .mo_least_show {
    display: block;
  }

  .mo_least_hide {
    display: none;
  }
}

@media screen and (min-width: 667px) {
  .mo_show {
    display: none;
  }

  .mo_show2 {
    display: block;
  }

  .mo_show.pc_show.mo_least_show {
    display: none;
  }
}

@media screen and (min-width: 667px) and (max-width: 767px) {
  .tablet_show_md {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .mo_show2 {
    display: none;
  }

  .tablet_show {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .tablet_show {
    display: none;
  }

  .tablet_show2 {
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .tablet_show2 {
    display: none;
  }

  .pc_show {
    display: block;
  }

  .pc_hide {
    display: none;
  }

  .mo_show.pc_show.mo_least_show {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tablet_show3 {
    display: block;
  }
}

/* 탭 스타일 */
.tab-menu {
  font-size: 0;
  border-bottom: 0.6px solid rgba(0, 0, 0, 0.2);
}

.tab-menu a {
  display: inline-block;
  padding: 6px 0;
  font-family: Museo_Slab_500, sans-serif;
  font-size: 14px;
}

.tab-menu a+a {
  margin-left: 16px;
}

.tab-menu a.on {
  font-family: Museo_Slab_900, sans-serif;
  border-bottom: 1.6px solid #000;
}

.hidden-area {
  display: none;
}

.hidden-area.on {
  display: block;
}
