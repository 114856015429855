.mo_show {
  display: block;
}

.pc_show {
  display: none;
}

.cont_wrap.game ul, p {
  font-family: NotoSansR, sans-serif !important;
}

.cont_wrap.game .tab_menu_box .tab_menu_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.cont_wrap.game .tab_menu_box .tab_menu_list>li {
  flex: auto;
  width: 50%;
}

.cont_wrap.game .tab_menu_box .tab_menu_list>li:nth-child(3) {
  border-left: 1px solid #000;
}

.cont_wrap.game h3 {
  padding-top: 40px;
}

.cont_wrap.game .btn_prev_info {
  margin: 20px 0;
  font-size: 12px;
  line-height: 16px;
}

.cont_wrap.game .btn_prev_area+h3 {
  padding-top: 20px;
}

.cont_wrap.game h4 {
  margin-top: 32px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.cont_wrap.game .term_list {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #595e65;

}

.cont_wrap.game .term_list .emphasis {
  font-weight: 900;
  line-height: 1.57;
  margin-bottom: 0;
  color: #353b42;
}

.cont_wrap.game .term_list.box+.cont_wrap.game .term_list.box {
  margin-top: 0;
}

.cont_wrap.game .term_list .term_list_title {
  display: block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #595e65;
  font-family: NotoSansR, sans-serif;
}

.cont_wrap.game .term_list .term_list_title.mt-16 {
  margin-top: 0;
}

.cont_wrap.game .term_list_txt {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
}

.cont_wrap.game .term_list_txt a {
  font-size: 14px;
  text-decoration: underline;
}

.cont_wrap.game .term_list_txt.mt-0 {
  margin-top: 0;
}

.cont_wrap.game .term_list_txt.mt-12 {
  margin-top: 12px !important;
}

.cont_wrap.game .term_list_txt.mt-16 {
  margin-top: 0;
}

.cont_wrap.game .term_list_txt.tbl {
  margin: 0;
  color: #a6adb4;
}

.cont_wrap.game .term_sub_list {
  padding: 0 0 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #a6adb4;
}

.cont_wrap.game .term_sub_list.emphasis {
  color: #353b42;
  font-weight: bold;
}

.cont_wrap.game .term_sub_list.box>.term_sub_txt {
  padding-left: 8px;
}

.cont_wrap.game .term_sub_title {
  padding-left: 8px;
  text-indent: -8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  color: #a6adb4;
  margin-bottom: 0;
}

.cont_wrap.game .term_sub_txt {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  color: #a6adb4;
  margin-bottom: 0;
}

.cont_wrap.game .term_sub_txt>.sub_emphasis {
  line-height: 1.75;
  margin-bottom: 0;
  font-weight: bold;
  color: #353b42;
}

.cont_wrap.game .tbl.user_terms {
  margin: 10px 0;
}

.cont_wrap.game .tbl.user_terms th {
  text-align: center;
  font-size: 11px;
  padding: 5px 0;
}

.cont_wrap.game .tbl.user_terms td {
  text-align: center;
  font-size: 11px;
  padding: 12px 0px;

}

.cont_wrap.game .tbl.user_terms td .row-2 {
  text-align: left;
}

.cont_wrap.game .tbl.user_terms td .terms_tbl_title {
  font-size: 11px;
  line-height: 1.36;
  color: #595e65;
  font-weight: 500;
  margin-bottom: 0;
}

.cont_wrap.game .tbl.user_terms td .terms_tbl_txt {
  font-size: 11px;
  line-height: 1.36;
  color: #595e65;
  font-weight: 500;
}

.cont_wrap.game .tbl.user_terms td .terms_tbl_warn {
  font-size: 11px;
  line-height: 1.36;
  color: #595e65;
  font-weight: 500;
}

.cont_wrap.game .tbl.user_terms tr+tr>th:first-child {
  border-left: 1px solid #c3c6ca;

}

.cont_wrap.game .tbl.user_terms .col1 {
  text-align: left;

}

.cont_wrap.game .tbl.user_terms .col2 {
  padding: 12px 9px;

}

.cont_wrap.game .tbl.user_terms .col3 {
  border-bottom: 1px solid #c3c6ca;
}

.cont_wrap.game .tbl.user_terms .col3-2>div {
  font-size: 10px;
}

.cont_wrap.game .tbl.user_terms .col3-2 {
  border-top: 0;
}

.cont_wrap.game .information_area {
  margin-top: 40px;
}

.cont_wrap.game .information_area .information_head .title {
  line-height: 24px;
  font-size: 18px;
  font-weight: bold;
}

/* 게임 개인정보 처리방침 변경공지 */
.cont_wrap.game .notice_wrap {
  min-height: 100%;
  margin-bottom: 68px;
}

.cont_wrap.game .notice_wrap .notice_list {
  margin-top: 18px;
}

.cont_wrap.game .notice_wrap .notice_title_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 18px 0;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
  text-align: left;
}

.cont_wrap.game .notice_wrap .notice_title {
  flex: 1;
  line-height: 20px;
  margin-bottom: 0;
  font-size: 15px;
  color: #000;
}

.cont_wrap.game .notice_wrap .notice_date {
  display: block;
  margin-top: 8px;
  line-height: 13px;
  font-size: 13px;
  color: #A6ADB4;
  ;
}

.cont_wrap.game .notice_detail_box {
  display: none;
  padding-top: 18px;

  &.is-show {
    display: block;
  }
}

/*영문버전 게임 이용약관*/
.cont_wrap.game.en .tab_menu_box .tab_menu_list>li>a {
  letter-spacing: -0.4px;
}

.cont_wrap.game.en .term_sub_txt_box {
  padding-bottom: 10px;
}

.cont_wrap.game.en .tbl.user_terms {
  margin-top: 0;
}

.cont_wrap.game.en .tbl.user_terms .col2 {
  padding: 13px 9px;
}

.cont_wrap.game.en .tbl.user_terms .col3-2>div {
  font-size: 8px;
  letter-spacing: -0.9px;
}

.cont_wrap.game.en .tbl td>div {
  word-break: break-all;
}

.cont_wrap.game.en .tbl.user_terms th {
  font-size: 10px;
  letter-spacing: -0.5px;
}

.cont_wrap.game.en .term_list_txt {
  margin-top: 12px;

}

.cont_wrap.game.en .term_sub_txt {
  line-height: 1.57;
}

.cont_wrap.game.en .tab_menu_box .tab_menu_list {
  flex-wrap: nowrap;
}

@media screen and (min-width:768px) {
  .mo_show {
    display: none;
  }

  .pc_show {
    display: block;
  }

  .cont_wrap.game .tab_menu_box .tab_menu_list {
    flex-wrap: nowrap;
  }

  .cont_wrap.game .tab_menu_box .tab_menu_list>li:nth-child(3) {
    border-left: 0;
  }

  .cont_wrap.game .notice_wrap {
    min-height: 574px;
    margin-bottom: 62px;
  }

  .cont_wrap.game .notice_wrap .notice_list {
    margin-top: 22px;
  }

  .cont_wrap.game .notice_detail_box {
    padding-bottom: 62px;
  }

  .cont_wrap.game .notice_wrap .notice_title_box {
    padding: 18px 0;
  }
}

@media screen and (min-width:1024px) {
  .mo_show {
    display: none;
  }

  .pc_show {
    display: block;
  }

  .cont_wrap.game .tab_menu_box .tab_menu_list {
    display: block;
  }

  .cont_wrap.game .tab_menu_box .tab_menu_list>li {
    width: 208px;
  }

  .cont_wrap.game .btn_prev_info {
    margin: 30px 0;
  }

  .cont_wrap.game h3 {
    padding-top: 71px;
  }

  .cont_wrap.game .btn_prev_area+h3 {
    padding-top: 53px;
  }

  .cont_wrap.game h4 {
    margin-top: 64px;
    font-size: 22px;
    font-weight: bold;
    color: #353b42;
  }

  .cont_wrap.game .term_list {
    margin-top: 20px;
    font-size: 17px;
    line-height: 1.76;

  }

  .cont_wrap.game .term_list .emphasis {
    font-size: 17px;
    line-height: 1.76;
  }

  .cont_wrap.game .term_list.box+.cont_wrap.game .term_list.box {
    margin-top: 16px;
  }

  .cont_wrap.game .term_list li+li {
    margin-top: 8px;
  }

  .cont_wrap.game .term_sub_list.emphasis {
    font-size: 16px;
  }

  .cont_wrap.game .term_sub_list li+li {
    margin-top: 0;
  }

  .cont_wrap.game .term_list .term_list_title.mt-16 {
    margin-top: 16px;
  }

  .cont_wrap.game .term_list .term_list_title {
    margin-bottom: 0;
    font-size: 17px;
    line-height: 1.76;
    font-weight: 500;

  }

  .cont_wrap.game .term_list_txt {
    margin: 8px 0 0;
    font-size: 17px;
    line-height: 1.76;
  }

  .cont_wrap.game .term_list_txt a {
    font-size: 17px;
  }

  .cont_wrap.game .term_list_txt.tbl {
    margin: 16px 0 0;
    color: #595e65;
  }

  .cont_wrap.game .term_list_txt.mt-12 {
    margin: 12px 0 0 !important;
  }

  .cont_wrap.game .term_list_txt.mt-16 {
    margin-top: 16px;
  }

  .cont_wrap.game .term_sub_list {
    padding: 5px 0 8px;
    font-size: 16px;
    line-height: 1.69;
  }

  .cont_wrap.game .term_sub_list.box>.term_sub_txt {
    padding-left: 10px;

  }

  .cont_wrap.game .term_sub_list.box+.cont_wrap.game .term_sub_list.box {
    margin-top: 8px;
  }

  .cont_wrap.game .term_sub_title {
    /* padding-left: 810px;
		text-indent: -810px; */
    font-size: 16px;
  }

  .cont_wrap.game .term_sub_txt {
    font-size: 16px;
  }

  .cont_wrap.game .term_sub_txt>.sub_emphasis {
    font-size: 16px;
  }

  .cont_wrap.game .term_sub_txt_box {
    margin-top: 5px;
  }

  .cont_wrap.game .tbl.user_terms {
    margin: 16px 0;
  }

  .cont_wrap.game .tbl.user_terms th {
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
  }

  .cont_wrap.game .tbl.user_terms td {
    text-align: center;
    padding: 24px 0;
    font-size: 15px;

  }

  .cont_wrap.game .tbl.user_terms td>div {
    font-size: 15px;
  }

  .cont_wrap.game .tbl.user_terms td .row-2 {
    text-align: left;
  }

  .cont_wrap.game .tbl.user_terms td .terms_tbl_title {
    font-size: 15px;
    line-height: 1.87;
    color: #595e65;
    font-weight: 500;
    margin: 0 0 5px;
  }

  .cont_wrap.game .tbl.user_terms td .terms_tbl_txt {
    font-size: 15px;
    line-height: 1.87;
    color: #595e65;
    font-weight: 500;
  }

  .cont_wrap.game .tbl.user_terms td .terms_tbl_warn {
    font-size: 15px;
    line-height: 1.87;
    color: #595e65;
    font-weight: 500;
    margin: 5px 0 0;

  }

  .cont_wrap.game .tbl.user_terms tr+tr>th:first-child {
    border-left: 1px solid #c3c6ca;

  }

  .cont_wrap.game .tbl.user_terms .col1 {
    width: 200px;
    text-align: center;

  }

  .cont_wrap.game .tbl.user_terms .col2 {
    width: 596px;

  }

  .cont_wrap.game .tbl.user_terms .col3 {
    width: 403px;
    border-bottom: 1px solid #c3c6ca;

  }

  .cont_wrap.game .tbl.user_terms .col3-2>div {
    font-size: 15px;
  }

  .cont_wrap.game .tbl.user_terms .col3-2 {
    border-top: 0;
  }

  .cont_wrap.game .information_area {
    margin-top: 70px;
  }

  /*영문버전 게임 이용약관*/
  .cont_wrap.game.en .term_list li+li {
    margin-top: 12px;
  }

  .cont_wrap.game.en .term_sub_txt_box {
    padding-bottom: 4px;
  }

  .cont_wrap.game.en .tbl.user_terms {
    margin-top: 0;
  }

  .cont_wrap.game.en .tbl.user_terms {
    margin-top: 12px;
  }

  .cont_wrap.game.en .tbl.user_terms .col2 {
    padding: 25px 30px;
  }

  .cont_wrap.game.en .tbl.user_terms .col3-2>div {
    font-size: 16px;
    letter-spacing: normal;
  }

  .cont_wrap.game.en .tbl td>div {
    word-break: break-word;
  }

  .cont_wrap.game.en .tbl.user_terms th {
    font-size: 15px;
    letter-spacing: normal;
  }

  .cont_wrap.game.en .term_list_txt {
    margin-top: 12px;

  }

  .cont_wrap.game.en .term_list_txt.mt-0 {
    margin-top: 0;
  }

  .cont_wrap.game.en .term_sub_txt {
    line-height: 1.69;
  }

  /* 게임 개인정보 처리방침 변경공지 */
  .cont_wrap.game .notice_wrap {
    max-width: 1280px;
    min-height: 623px;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 102px;
  }

  .cont_wrap.game .notice_wrap .notice_list {
    margin-top: 36px;
  }

  .cont_wrap.game .notice_wrap .notice_title_box {
    flex-direction: row;
    align-items: center;
    padding: 36px 0;
  }

  .cont_wrap.game .notice_wrap .notice_title {
    line-height: 29px;
    margin-bottom: 0;
    font-size: 20px;
  }

  .cont_wrap.game .notice_wrap .notice_date {
    line-height: 29px;
    margin-top: 0;
    margin-left: auto;
    font-size: 16px;
  }

  .cont_wrap.game .notice_detail_box {
    padding-top: 53px;
  }
}

@media screen and (min-width:1440px) {
  .cont_wrap.game .tab_menu_box .tab_menu_list>li {
    width: 240px;
  }
}

@media screen and (min-width:1600px) {
  .cont_wrap.game .btn_prev_info {
    margin: 30px 0;
    font-size: 16px;
    line-height: 27px;
  }
}
