footer {
  margin-top: -197px
}

footer.main_footer_pc {
  display: none;
}

.footer_wrap .pc_footer {
  display: none;
}

footer.career.pc .pc_footer {
  display: block !important;
}

footer.career.pc .mo_footer {
  display: none !important;
}

.footer_wrap .mo_footer {
  display: block;
  position: relative;
  padding: 46px 0 51px;
  background-color: #f5f5f5;
}

.footer_wrap .mo_footer .mo_footer_inner {
  margin: 0 auto;
  padding: 0 30px;
}

.footer_wrap .mo_footer .btn_lst {
  font-size: 0;
  text-align: center;
}

.footer_wrap .mo_footer .btn_lst li {
  display: inline-block;
  vertical-align: middle;
}

.footer_wrap .mo_footer .btn_lst li a {
  display: block;
  width: 50px;
  height: 10px;
  background: url(../img/mobile/sp_logo.png) 0 -526px no-repeat;
  background-size: 600px 875px;
}

.footer_wrap .mo_footer .btn_lst li:first-child {
  margin-right: 26px;
}

.footer_wrap .mo_footer .btn_lst li:first-child a {
  width: 33px;
  height: 33px;
  background-position: 0 -550px;
}

.footer_wrap .mo_footer .policy_lst {
  margin-top: 24px;
  font-size: 0;
  text-align: center;
}

.footer_wrap .mo_footer .policy_lst li {
  display: inline-block;
}

.footer_wrap .mo_footer .policy_lst li.accent a {
  font-weight: bold;
  text-decoration: underline;
  opacity: 0.7;
}

.footer_wrap .mo_footer .policy_lst li a {
  font-size: 13px;
  line-height: 1.46;
  opacity: 0.6;
}

.footer_wrap .mo_footer .policy_lst li+li:before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 11px;
  margin: 0 8px;
  opacity: 0.1;
  background-color: #000;
}

.footer_wrap .mo_footer .mo_copyright {
  margin-top: 13px;
  font-weight: 300;
  font-size: 13px;
  text-align: center;
  opacity: 0.6;
  font-family: Museo_Sans_300, sans-serif;
}

/* PC 커리어 푸터 */
footer.career.pc {
  margin-top: -114px;
}

footer.career.pc .footer_wrap {
  position: relative;
  min-width: 610px;
  padding: 46px 80px 40px;
}

footer.career.pc .footer_wrap {
  border: 0;
}

footer.career.pc .footer_wrap::after {
  content: '';
  display: block;
  clear: both;
}

footer.career.pc .footer_wrap .ft_menu {
  float: left;
}

footer.career.pc .footer_wrap .ft_menu:after {
  content: '';
  display: block;
  clear: both;
}

footer.career.pc .footer_wrap .ft_menu li {
  float: left;
  margin-left: 22px;
}

footer.career.pc .footer_wrap .ft_menu li:first-child {
  margin-left: 0;
}

footer.career.pc .footer_wrap .ft_menu.icon li a {
  display: inline-block;
}

footer.career.pc .footer_wrap .ft_menu.privacy {
  margin-top: 2px;
}

footer.career.pc .footer_wrap .ft_menu.privacy li {
  margin-left: 24px;
}

footer.career.pc .footer_wrap .ft_menu.privacy li:first-child {
  margin-left: 0;
}

footer.career.pc .footer_wrap .ft_menu.privacy li a {
  color: #000;
  font-size: 13px;
  letter-spacing: -0.3px;
  line-height: 18px;
  opacity: 0.5;
}

footer.career.pc .footer_wrap .ft_menu.icon .webtoon a {
  display: inline-block;
  width: 65px;
  height: 24px;
  background: url(../img/sp_logo.png) -152px -30px no-repeat;
  background-size: 750px 400px;
}

footer.career.pc .footer_wrap .ft_menu.icon .naver a {
  width: 63px;
  height: 12px;
  margin-top: 5px;
  background: url(../img/sp_logo.png) -245px -30px no-repeat;
  background-size: 750px 400px;
}

footer.career.pc .footer_wrap .ft_menu.privacy:before {
  content: '';
  float: left;
  width: 1px;
  height: 12px;
  margin: 3px 17px 0;
  background-color: #000;
  opacity: 0.25;
}

footer.career.pc .footer_wrap .copyright {
  float: right;
  margin-top: 3px;
  font-weight: 500;
  font-size: 13px;
  color: #000;
  letter-spacing: -0.3px;
  opacity: 0.5;
  line-height: 18px;
  font-family: Museo_Sans_500, sans-serif;
}
