.mo_careers {
  display: none;
}

.mo_careers {
  margin-left: env(safe-area-inset-left);
  margin-left: constant(safe-area-inset-left);
  margin-right: env(safe-area-inset-left);
  margin-right: constant(safe-area-inset-left);
}

.container.career {
  min-width: 1200px;
  padding-bottom: 114px;
}

.container.career.array_box {
  display: table;
  width: 100%;
}

.container.career.array_box .array_ct {
  display: table-cell;
  padding: 150px 0 40px;
  width: 100%;
  vertical-align: middle;
}

.container.career .sp_ico {
  background-image: url(../img/sp_ico.png) !important;
  background-size: 150px 1000px !important;
}

.container.sub_career .content .career_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: url("../img/mo_sub_career_work_life.png") 50% 50% no-repeat;
  background-size: auto 100%;
  z-index: -1;
}

.container.sub_career .content .career_top {
  min-height: 824px;
  padding-bottom: 149px;
  background-color: #fff;
}

.container.sub_career .content .career_top .career_top_inner {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 192px 80px 0;
}

.container.sub_career .content .career_top .career_top_inner>h2 {
  font-weight: 900;
  font-size: 80px;
  line-height: 1;
  font-family: Museo_Slab_900, sans-serif;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation {
  margin-top: 74px;
  width: 500px;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation>h3 {
  width: 353px;
  height: 26px;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation>h3>.tit_txt {
  display: none;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation>p {
  margin-top: 22px;
  font-size: 18px;
  line-height: 1.56;
  word-break: keep-all;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_750,
.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_1334,
.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_1536,
.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt1_2048 {
  display: none;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2 {
  margin-top: 27px;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_1334,
.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_1536,
.container.sub_career .content .career_top .career_top_inner .career_explanation>.career_exp_txt2_2048 {
  display: none;
}

.container.sub_career .content .career_top .career_top_inner .career_explanation>p .en_spot {
  font-family: Museo_Sans_500, sans-serif;
  line-height: 1.56;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram {
  position: absolute;
  top: 194px;
  right: 108px;
  z-index: 30;
  width: 645px;
  height: 645px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
  width: 146px;
  height: 139px;
  background: url("../img/img_payer_pc.png") no-repeat;
  background-size: 146px 139px;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt {
  position: absolute;
  z-index: 40;
  width: 150px;
  min-height: 45px;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.34;
  text-align: center;
  font-family: NotoSansM, sans-serif;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.com {
  top: -50px;
  left: 0;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.know {
  top: 40px;
  left: 125px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.pasn {
  top: 149px;
  left: 54px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.crtv {
  top: 149px;
  left: -54px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt.fun {
  top: 40px;
  left: -125px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.new {
  top: 51px;
  left: 197px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.hori {
  top: 330px;
  left: 47px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt.grown {
  top: 330px;
  right: 47px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .en {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.33;
  font-family: Museo_Sans_500, sans-serif;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .center_txt .txt2 {
  display: none;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  width: 516px;
  height: 516px;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle {
  position: absolute;
  z-index: 30;
  width: 514px;
  height: 514px;
  border: 1px solid #000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.top {
  top: -63px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.left {
  top: 45px;
  left: -62px
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle.right {
  top: 45px;
  left: 66px;
}

.container.sub_career .content .career_top .career_top_inner .sub_career_diagram .diagram_circle_wrap .diagram_circle .circle_txt {
  position: absolute;
  z-index: 40;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  font-family: NotoSansM, sans-serif;
}

.container.sub_career .content .career_middle {
  position: relative;
}

.container.sub_career .content .career_middle {
  padding-left: env(safe-area-inset-left);
  padding-left: constant(safe-area-inset-left);
  padding-right: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-left);
}

/* 서브 커리어 WORK LIFE  */
.container.sub_career .content .career_middle .career_middle_inner {
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

.container.sub_career .content .career_middle .work_life_box {
  display: block;
  position: relative;
  margin: 0 auto;
}

.container.sub_career .content .career_middle .work_life_box h3 {
  font-family: Museo_Slab_900, sans-serif;
  font-weight: normal;
  font-size: 34px;
  line-height: 1.1;
}

.container.sub_career .content .career_middle .work_life_box p {
  margin: 19px 0 0;
  font-size: 18px;
  line-height: 28px;
  word-break: keep-all;
}

.container.sub_career .content .career_middle .work_life_box .tb_br {
  display: none;
}

.container.sub_career .content .career_middle .work_life_box .work_life_list {
  margin-top: 32px;
  border: 1px solid #000;
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list {
  position: relative;
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list:hover:after {
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 2px solid #000;
  z-index: 5;
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(1) {
  background-image: url('../img/img_work_life_pc_01.jpg');
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(2) {
  background-image: url('../img/img_work_life_pc_02.jpg');
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(3) {
  background-image: url('../img/img_work_life_pc_03.jpg');
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:nth-child(4) {
  background-image: url('../img/img_work_life_pc_04.jpg');
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list.on:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.container.sub_career .content .career_middle .work_life_box .work_life_list>.list+.list {
  border-top: 1px solid #000;
}

.container.sub_career .content .career_middle .work_life_box .list_title {
  position: relative;
  display: block;
  width: 100%;
  padding: 39px 90px 35px 31px;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.container.sub_career .content .career_middle .work_life_box .list_title .title_txt {
  display: inline-block;
  font-family: Museo_Slab_900, sans-serif;
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}

.container.sub_career .content .career_middle .work_life_box .list.on .list_title {
  color: #fff;
  padding-bottom: 31px;
}

.container.sub_career .content .career_middle .work_life_box .list.on .list_title .title_txt {
  display: inline-block;
  vertical-align: middle;
}

.container.sub_career .content .career_middle .work_life_box .list .support_list {
  display: inline-block;
  margin-left: 25px;
  vertical-align: middle;
}

.container.sub_career .content .career_middle .work_life_box .list.on .support_list {
  display: none;
}

.container.sub_career .content .career_middle .work_life_box .list .support_list .item {
  display: inline-block;
  font-family: NotoSansR, sans-serif;
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.4px;
  vertical-align: top;
}

.container.sub_career .content .career_middle .work_life_box .list .support_list .item+.item {
  margin-left: 12px;
}

.container.sub_career .content .career_middle .work_life_box .list button {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  z-index: 10;
}

.container.sub_career .content .career_middle .work_life_box .list button:before {
  position: absolute;
  top: 37px;
  right: 31px;
}

.container.sub_career .content .career_middle .work_life_box .list .btn_more {
  display: block;
}

.container.sub_career .content .career_middle .work_life_box .list .btn_more:before {
  content: '';
  width: 24px;
  height: 24px;
  background: url('../img/sp_ico_career.png') no-repeat;
  background-position: 0 -57px;
  background-size: 150px 150px;
  -webkit-background-size: 150px 150px;
}

.container.sub_career .content .career_middle .work_life_box .list.on .btn_more {
  display: none;
}

.container.sub_career .content .career_middle .work_life_box .list .btn_close {
  display: none;
}

.container.sub_career .content .career_middle .work_life_box .list .btn_close:before {
  content: '';
  width: 19px;
  height: 19px;
  background: url('../img/sp_ico_career.png') no-repeat;
  background-position: -27px -57px;
  background-size: 150px 150px;
  -webkit-background-size: 150px 150px;
}

.container.sub_career .content .career_middle .work_life_box .list.on .btn_close {
  display: block;
}

.container.sub_career .content .career_middle .work_life_box .list_content .list {
  position: relative;
  padding-left: 13px;
  font-size: 18px;
  color: #fff;
  line-height: 1;
}

.container.sub_career .content .career_middle .work_life_box .list.on {
  padding-bottom: 38px;
}

.container.sub_career .content .career_middle .work_life_box .list_content .list:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #fff;
}

.container.sub_career .content .career_middle .work_life_box .list_content .list+.list {
  margin-top: 22px;
}

.container.sub_career .content .career_middle .work_life_box .list_content .list:last-child {
  padding-bottom: 2px;
}

.container.sub_career .content .career_middle .work_life_box .list_content {
  display: none;
  padding: 0 31px;
}

.container.sub_career .content .career_middle .work_life_box .list.on .list_content {
  display: block;
}

.container.sub_career .content .career_bottom {
  background-color: #fff;
}

.container.sub_career .content .career_bottom .career_bottom_inner {
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

/* 서브 커리어 채용 */
.button {
  padding: 9px 24px 8px;
  background: #000;
  font-size: 1em;
  color: #fff;
  text-decoration: none;
  line-height: 1.5;
  display: inline-block;
}

.ly_srch.inquiry .sp_ico {
  background-image: url(../img/sp_ico.png) !important;
  background-size: 150px 1000px !important;
}

.recruit_progress {
  width: 100%;
  margin: 160px auto 0;
  font-size: 16px;
}

.recruit_progress>h3 {
  font-size: 28px;
  padding: 0 10px 30px 0;
  float: left;
  font-weight: bold;
}

.recruit_progress>.progress_info {
  float: right;
  margin-top: 10px;
}

.recruit_progress>.progress_info a {
  width: 52px;
  height: 23px;
  font-size: 14px;
}

.recruit_progress>.progress_info a:first-child {
  margin-right: 6px;
}

.recruit_progress>ul {
  margin-bottom: 160px;
  border: 1px solid #000;
  border-width: 1px 0;
  clear: both;
}

.recruit_progress>ul>li+li {
  border-top: 1px solid #ced4db;
}

.recruit_progress>ul>li .recruit_board_title {
  position: relative;
}

.recruit_progress>ul>li .recruit_board_title .recruit_date {
  position: absolute;
  top: 53px;
  right: 75px;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.4;
  line-height: 1.5;
  font-family: Museo_Sans_500, sans-serif;
}

.recruit_progress>ul>li .recruit_board_title .recruit_date.txt {
  font-weight: 500;
  font-family: NotoSansR, sans-serif;
}

.recruit_progress>ul>li .recruit_board_title .crd_dday {
  position: absolute;
  top: 21px;
  right: 75px;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5;
  font-family: Museo_Sans_700, sans-serif;
}

.recruit_progress>ul>li .recruit_board_title .crd_dday.txt {
  font-size: 20px;
  font-weight: bold;
  font-family: NotoSansB, sans-serif;
}

.recruit_progress .recruitment.off .recruit_board_title .crd_dday {
  opacity: 0.4;
}

.recruit_progress>ul>li .recruit_board_title .title_txt {
  display: block;
  font-size: 20px;
  padding: 35px 0 34px;
  width: calc(100% - 270px);
  width: -moz-calc(100% - 270px);
  width: -webkit-calc(100% - 270px);
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
}

.recruit_progress .recruitment.off .recruit_board_title .title_txt {
  opacity: 0.4;
}

.recruit_progress>ul>li .recruit_board_title .btn_arrow {
  position: absolute;
  top: 0;
  right: 13px;
  width: 50px;
  height: 100%;
  background-color: #fff;
  outline: 0;
  border: 0;
  cursor: pointer;
}

.recruit_progress>ul>li .recruit_board_title .btn_arrow:before {
  content: '';
  display: inline-block;
  width: 28px;
  height: 16px;
  background: url("../img/sp_ico_career.png") -31px -38px no-repeat;
  background-size: 150px 150px;
  -webkit-background-size: 150px 150px;
}

.recruit_progress>ul>li.open .recruit_board_title .btn_arrow:before {
  background-position: 0 -38px;
}

.recruit_progress>ul>li.new .recruit_board_title .title_txt:after {
  position: relative;
  top: -2px;
  content: 'NEW';
  font-family: 'Roboto', sans-serif;
  color: #fff;
  background: #0078ff;
  display: inline-block;
  font-size: 0.62em;
  padding: 0.29em 0.99em 0.21em;
  margin-left: 7px;
  vertical-align: bottom;
}

.recruit_progress .recruit_board_content {
  border-top: 1px solid #ced4db;
  background: #f7f7fb;
}

.recruit_progress .recruit_board_content .markdown-wrap {
  font-size: 15px;
  padding: 28px 50px 50px;
  margin: 0;
  max-width: 1200px;
  word-break: break-word;
  -ms-word-break: keep-all;
}

.recruit_progress .recruitment .recruit_board_content {
  display: none;
}

.recruit_progress .recruitment.open .recruit_board_content {
  display: block;
}

.recruit_progress .recruit_board_content.nothing {
  background-color: #fff;
  text-align: center;
  padding: 150px 0;
}
