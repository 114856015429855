body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
select {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
input,
textarea,
select,
button,
table,
th,
td {
  font-family: NotoSansR, '나눔고딕', NanumGothic, '맑은 고딕', malgungothic, '굴림', Gulim, AppleGothic, sans-serif;
  font-size: 12px;
  color: #000;
}

h1,
h2,
h3,
h4,
strong {
  font-weight: 600;
}

img,
fieldset {
  border: 0;
}

img {
  vertical-align: top;
}

ul,
ol {
  list-style: none;
}

em,
address {
  font-style: normal;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
