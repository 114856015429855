@font-face {
  font-family: 'NotoSansR';
  src: url('../font/NotoSans/NotoSansKR-Regular-Hestia.otf');
  src: url('../font/NotoSans/NotoSansKR-Regular-Hestia.eot');
  src: url('../font/NotoSans/NotoSansKR-Regular-Hestia.eot?#iefix') format('embedded-opentype'), url('../font/NotoSans/NotoSansKR-Regular-Hestia.woff') format('woff'),
    url('../font/NotoSans/NotoSansKR-Regular-Hestia.ttf') format('ttf');
}
@font-face {
  font-family: 'NotoSansB';
  src: url('../font/NotoSans/NotoSansKR-Bold-Hestia.otf');
  src: url('../font/NotoSans/NotoSansKR-Bold-Hestia.eot');
  src: url('../font/NotoSans/NotoSansKR-Bold-Hestia.eot?#iefix') format('embedded-opentype'), url('../font/NotoSans/NotoSansKR-Bold-Hestia.woff') format('woff'),
    url('../font/NotoSans/NotoSansKR-Bold-Hestia.ttf') format('ttf');
}
@font-face {
  font-family: 'NotoSansM';
  src: url('../font/NotoSans/NotoSansKR-Medium-Hestia.otf');
  src: url('../font/NotoSans/NotoSansKR-Medium-Hestia.eot');
  src: url('../font/NotoSans/NotoSansKR-Medium-Hestia.eot?#iefix') format('embedded-opentype'), url('../font/NotoSans/NotoSansKR-Medium-Hestia.woff') format('woff'),
    url('../font/NotoSans/NotoSansKR-Medium-Hestia.ttf') format('ttf');
}
@font-face {
  font-family: 'Museo_Sanb_300';
  src: url('../font/MuseoSlab/Museo_Sanb_300.otf');
  src: url('../font/MuseoSlab/Museo_Sanb_300.woff') format('woff'), url('../font/MuseoSlab/Museo_Sanb_300.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo_Slab_500';
  src: url('../font/MuseoSlab/Museo_Slab_500.otf');
  src: url('../font/MuseoSlab/Museo_Slab_500.woff') format('woff'), url('../font/MuseoSlab/Museo_Slab_500.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo_Slab_700';
  src: url('../font/MuseoSlab/Museo_Slab_700.otf');
  src: url('../font/MuseoSlab/Museo_Slab_700.woff') format('woff'), url('../font/MuseoSlab/Museo_Slab_700.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo_Slab_900';
  src: url('../font/MuseoSlab/Museo_Slab_900.otf');
  src: url('../font/MuseoSlab/Museo_Slab_900.woff') format('woff'), url('../font/MuseoSlab/Museo_Slab_900.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo_Sans_300';
  src: url('../font/MuseoSans/Museo_Sans_300.ttf') format('ttf'), url('../font/MuseoSans/Museo_Sans_300.woff') format('woff'), url('../font/MuseoSans/Museo_Sans_300.woff') format('woff');
}
@font-face {
  font-family: 'Museo_Sans_500';
  src: url('../font/MuseoSans/Museo_Sans_500.otf');
  src: url('../font/MuseoSans/Museo_Sans_500.woff') format('woff'), url('../font/MuseoSans/Museo_Sans_500.woff2') format('woff2');
}
@font-face {
  font-family: 'Museo_Sans_700';
  src: url('../font/MuseoSans/Museo_Sans_700.otf');
  src: url('../font/MuseoSans/Museo_Sans_700.woff') format('woff'), url('../font/MuseoSans/Museo_Sans_700.woff2') format('woff2');
}
