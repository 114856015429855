.about_bg {
  background: #f5f5f5;
}

.sub_about {
  padding-left: env(safe-area-inset-left);
  padding-left: constant(safe-area-inset-left);
  padding-right: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-left);
  background: #fff;
}

.sub_about_top {
  min-width: 220px;
  padding: 115px 0 78px;
}

.sub_about_top .about_exp {
  position: relative;
  margin: 0 auto;
  padding: 0 30px;
}

.sub_about_top h2 {
  font-weight: 900;
  font-size: 45px;
  line-height: 1.13;
  ;
  font-family: Museo_Slab_900, sans-serif;
}

.sub_about_top h3 {
  line-height: 0;
  margin: 22px auto 0;
}

.sub_about_top h3>span {
  display: inline-block;
  width: 220px;
  height: 22px;
  background-position: 0 -4px;
}

.sub_about_top .lico_esed>span {
  width: 147px;
  height: 77px;
  background-position: 0 -375px;
}

.sub_about_top p {
  font-size: 15px;
  line-height: 1.67;
  letter-spacing: -0.2px;
}

.sub_about_top p .spot {
  font-family: NotoSansB, sans-serif;
}

.sub_about_top .explanation:first-of-type {
  margin-top: 20px;
}

.sub_about_top .explanation {
  margin-top: 15px;
  word-break: keep-all;
}

.sub_about_top .logo_box {
  margin: 19px 0 16px;
  text-align: center;
}

.sub_about_top .logo_box .logo {
  display: inline-block;
  width: 63px;
  height: 63px;
  background-position: 0 -238px;
  vertical-align: top;
}

.sub_about_top .logo_box .logo.webtoon {
  width: 73px;
  height: 73px;
  margin-left: 34px;
  background-position: 0 -389px;
  vertical-align: top;
}

.sub_about_top .logo_box>div:first-child {
  margin-top: 3px;
}

.sub_about_top .established_box {
  margin: 19px auto 0;
}

.sub_about_top .established_box .established_logo .lico_esed {
  margin-top: 13px;
}

.sub_about_diagram {
  margin-top: 40px;
}

.sub_about_diagram ul {
  position: relative;
  text-align: center;
}

.sub_about_diagram ul:before {
  content: '';
  position: absolute;
  top: 7px;
  left: 51px;
  bottom: 15px;
  width: 1px;
  background-color: #000;
}

.sub_about_diagram ul li {
  position: relative;
  margin: 51px auto 0;
  text-align: left;
}

.sub_about_diagram ul li:first-child {
  margin-top: 0;
  margin-bottom: 56px;
}

.sub_about_diagram ul li:nth-child(3) {
  margin-top: 50px;
}

.sub_about_diagram ul li:nth-child(4) {
  margin-top: 49px;
}

.sub_about_diagram ul li:nth-child(5) {
  margin-top: 47px;
}

.sub_about_diagram ul li:nth-child(6),
.sub_about_diagram ul li:nth-child(8),
.sub_about_diagram ul li:nth-child(10) {
  margin-top: 44px;
}

.sub_about_diagram ul li:nth-child(7),
.sub_about_diagram ul li:nth-child(9),
.sub_about_diagram ul li:nth-child(11) {
  margin-top: 43px;
}

.sub_about_diagram ul li:nth-child(12) {
  margin-top: 56px;
}

.sub_about_diagram ul li .detail_next {
  margin-top: -2px;
  margin-left: 13px;
}

.sub_about_diagram ul li strong {
  display: inline-block;
  min-width: 95px;
  margin-left: 15px;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  vertical-align: middle;
  font-family: NotoSansM, sans-serif;
  letter-spacing: -0.2px;
  font-weight: normal;
}

.sub_about_diagram ul li strong .spot {
  font-size: 18px;
  vertical-align: top;
  font-family: Museo_Saan_700, sans-serif;
}

.sub_about_diagram ul li:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 52px;
  margin-top: -4px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  background-color: #000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.sub_about_diagram ul li .detail_years {
  display: inline-block;
  width: 42px;
  margin-top: 2px;
  margin-right: 11px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  vertical-align: middle;
  opacity: 0.6;
  font-family: Museo_Sans_500, sans-serif;
  word-break: break-all;
}

.sub_about_circle {
  position: absolute;
  width: 91px;
  height: 91px;
  top: -35px;
  left: 6.5px;
  z-index: 20;
}

.sub_about_circle span {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 0px;
  height: 0px;
  opacity: 0;
  -webkit-border-radius: 46px;
  -moz-border-radius: 46px;
  border-radius: 46px;
  border: 1px solid #000;
  -webkit-animation: sub_about_circle 3s linear infinite;
  -moz-animation: sub_about_circle 3s linear infinite;
  animation: sub_about_circle 3s linear infinite;
}

.sub_about_circle span:nth-of-type(2) {
  -webkit-animation: sub_about_circle 3s linear infinite 1s;
  -moz-animation: sub_about_circle 3s linear infinite 1s;
  animation: sub_about_circle 3s linear infinite 1s;
}

.sub_about_circle span:nth-of-type(3) {
  -webkit-animation: sub_about_circle 3s linear infinite 2s;
  -moz-animation: sub_about_circle 3s linear infinite 2s;
  animation: sub_about_circle 3s linear infinite 2s;
}

@-moz-keyframes sub_about_circle {
  0% {
    width: 9px;
    height: 9px;
    opacity: 0.4;
  }

  100% {
    width: 91px;
    height: 91px;
    opacity: 0;
  }
}

@-webkit-keyframes sub_about_circle {
  0% {
    width: 9px;
    height: 9px;
    opacity: 0.4;
  }

  100% {
    width: 91px;
    height: 91px;
    opacity: 0;
  }
}

@keyframes sub_about_circle {
  0% {
    width: 9px;
    height: 9px;
    opacity: 0.4;
  }

  100% {
    width: 91px;
    height: 91px;
    opacity: 0;
  }
}

.sub_about_middle {
  padding: 38px 0 0;
}

.sub_about_middle .about_middle_inner {
  margin: 0 auto;
  padding: 0 30px 100px;
}

.lico_at>span {
  display: inline-block;
  width: 262px;
  height: 23px;
  background-position: 0 -128px;
}

.sub_about_middle .lico_exp_box {
  margin-top: 20px;
}

.sub_about_middle .lico_exp_box.v2 {
  display: none;
}

.sub_about_middle .lico_exp_box ul {
  position: relative;
  width: 242px;
  margin: 0 auto;
  font-size: 0;
  text-align: center;
}

.sub_about_middle .lico_exp_box ul li {
  position: relative;
  display: inline-block;
  width: 242px;
  height: 242px;
  margin-top: -30px;
  background: url(../img/mobile/sub_about_box.png) no-repeat;
  background-size: 242px 242px;
}

.sub_about_middle .lico_exp_box ul li:first-child {
  margin-top: 0;
}

.sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner {
  overflow: hidden;
  width: 212px;
  height: 212px;
  text-align: center;
}

.sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner strong {
  display: inline-block;
  margin-top: 71px;
  font-weight: 900;
  font-size: 30px;
  line-height: 1.1;
  letter-spacing: -1px;
  font-family: Museo_Slab_900, sans-serif;
}

.sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner p {
  margin-top: 2px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.33;
  font-family: NotoSansM, sans-serif;
}

.sub_about_middle .lico_exp_box ul li .lico_exp_lst_inner p .bottom_line {
  display: inline-block;
  margin-top: 2px;
}

.sub_about_bottom {
  overflow: hidden;
}

.sub_about_bottom .about_bottom_inner {
  margin: 0 auto;
}

.sub_about_bottom h3 {
  margin: 76px auto 0;
  padding: 0 30px;
}

.sub_about_bottom h3>span {
  display: inline-block;
  width: 112px;
  height: 26px;
  background-position: 0 -252px;
}

.sub_about_bottom .office_sub_txt {
  padding: 8px 30px 0;
  margin: 0 auto;
  font-size: 15px;
  line-height: 1.67;
}

.sub_about_bottom .office_img_slid_box {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 56px;
}
