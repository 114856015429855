.error {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -100%);
  text-align: center;

  &__text {
    font-weight: bold;
    font-size: 18px;
    color: #33475b;
    line-height: 1.83;
    letter-spacing: -0.2px;
  }

  &__btn {
    display: inline-block;
    width: 140px;
    margin-top: 24px;
    padding: 11px 10px 10px 10px;
    border-radius: 4px;
    background-color: #33475b;
    font-size: 13px;
    color: #fff;
    letter-spacing: 0.5px;
  }
}