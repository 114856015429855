.contact_pc_only .header_wrap {
  display: none;
}

.header_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 120;
  padding: 23px 30px;
}

.header_wrap.pc {
  display: none;
}

.header_wrap.mobile {
  display: block;
}

.header_wrap.udr_line {
  border-bottom: 1px solid #ced4db;
}

.header_wrap .lico_logo {
  display: inline-block;
  margin-right: 40px;
  vertical-align: top;
  margin-left: env(safe-area-inset-left);
  margin-left: constant(safe-area-inset-left);
}

.header_wrap .lico_logo a {
  display: block;
  width: 63px;
  height: 32px;
  background-position: -284px -34px;
}

.header_wrap.black {
  background: #fff;
}

.header_wrap.black .lico_logo a {
  background-position: -284px 0;
}

/* 커리어 헤드 pc*/
.career_header.pc .header_wrap {
  position: absolute;
  min-width: 1200px;
  background-color: #fff;
  padding: 50px 80px !important;
  z-index: 90;
  overflow-x: inherit;
}

.career_header.pc .hamburger_menu {
  display: none !important;
}

.career_header.pc .header_wrap .lico_logo a {
  display: block;
  width: 97px !important;
  height: 29px;
  background: url(../img/sp_logo.png) -355px -260px no-repeat !important;
  background-size: 750px 400px !important;
}

.career_header.pc.notbg .header_wrap {
  background-color: transparent;
}

.career_header.pc .header_wrap.udr_line {
  border-bottom: 1px solid #ced4db;
}

.career_header.pc .header_wrap .lico_logo a .tit_career {
  display: inline-block;
  padding-left: 110px;
  font-weight: 500;
  font-size: 34px;
  font-family: Museo_Slab_500, sans-serif;
  line-height: 32px;
  vertical-align: top;
}

.career_header.pc .header_wrap .careers_gnb_area {
  float: right;
}

.career_header.pc .header_wrap .careers_gnb_area .gnb {
  font-size: 0;
}

.career_header.pc .header_wrap .careers_gnb_area .gnb li {
  display: inline-block;
  margin-left: 40px;
}

.career_header.pc .header_wrap .careers_gnb_area .gnb li:first-child {
  margin-left: 0;
}

.career_header.pc .header_wrap .careers_gnb_area .gnb li a {
  position: relative;
  display: block;
  font-weight: normal;
  font-size: 20px;
}

.career_header.pc .header_wrap .careers_gnb_area .gnb li.on a, .career_header.pc .header_wrap .careers_gnb_area .gnb li a:hover {
  font-family: NotoSansB, sans-serif;
}

.career_header.pc .header_wrap .careers_gnb_area .gnb li.on a:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 3px;
  background-color: #000;
}

/* 서브 헤더 */
.sub_header .header_wrap {
  background-color: #fff;
}

.sub_header.notbg .header_wrap {
  background-color: transparent;
}

.sub_header .header_wrap .lico_logo {
  display: inline-block;
  vertical-align: top;
  margin-right: 80px;
}

.sub_header .header_wrap .lico_logo a {
  background-position: -285px 0;
}

.sub_header .header_wrap .hamburger_menu .icon:before, .sub_header .header_wrap .hamburger_menu .icon:after {
  background-color: #000;
}

.sub_header .header_wrap.white .lico_logo a {
  background-position: -285px -34px;
}

.sub_header .header_wrap.white .hamburger_menu .icon:before, .sub_header .header_wrap.white .hamburger_menu .icon:after {
  background-color: #fff;
}

/* 헤더 - 메뉴리스트 */
.mo_menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  background-color: #000;
  height: 100%;
}

.mo_menu .menu_lst {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.mo_menu .menu_lst li {
  margin-top: 20px;
}

.mo_menu .menu_lst li:first-child {
  margin-top: 0;
}

.mo_menu .menu_lst li a {
  position: relative;
  font-weight: bold;
  font-size: 31px;
  color: #fff;
  line-height: 1.19;
  font-family: Museo_Slab_700, sans-serif;
}

.mo_menu .menu_lst li.on a:after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  bottom: -1px;
  height: 3px;
  background-color: #fff;
}

.mo_menu .menu_ft {
  position: absolute;
  left: 50%;
  bottom: 47px;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0;
}

.mo_menu .menu_ft li {
  display: inline-block;
  padding-top: 6px;
  vertical-align: top;
}

.mo_menu .menu_ft li a {
  display: inline-block;
  width: 47px;
  height: 9px;
  background: url(../img/mobile/sp_logo.png) -99px -526px no-repeat;
  background-size: 600px 875px;
  font-size: 10px;
}

.mo_menu .menu_ft li:first-child {
  padding: 0 28px 0 0;
}

.mo_menu .menu_ft li:first-child a {
  width: 65px;
  height: 24px;
  background-position: -99px -489px;
}

/* 헤더 - 햄버거 메뉴*/
.header_wrap.black .hamburger_menu .icon:before, .header_wrap.black .hamburger_menu .icon:after {
  background-color: #000;
}

.hamburger_menu {
  position: absolute;
  top: 28px;
  right: 30px;
  z-index: 130;
  width: 21px;
  height: 20px;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  margin-right: env(safe-area-inset-left);
  margin-right: constant(safe-area-inset-left);

}

.hamburger_menu .icon {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 18px;
  width: 21px;
}

.hamburger_menu .icon:before {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 21px;
  height: 3px;
  background-color: #fff;
  content: "";
  top: 0;
}

.hamburger_menu .icon:after {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 21px;
  height: 3px;
  background-color: #fff;
  content: "";
  bottom: 0;
}

.hamburger_menu.open {
  width: 24px;
}

.hamburger_menu.open .icon {
  width: 24px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.hamburger_menu.open .icon:before {
  width: 25.5px;
  -webkit-transform: rotateZ(45deg) translate(5px, 5px);
  transform: rotateZ(45deg) translate(5px, 5px);
}

.hamburger_menu.open .icon:after {
  width: 25.5px;
  -webkit-transform: rotateZ(-45deg) translate(6px, -6px);
  transform: rotateZ(-45deg) translate(6px, -6px);
}

/* 커리어 헤더 */
.career_header .header_wrap {
  background-color: #fff;
}

.career_header .header_wrap .lico_logo a {
  width: 28px;
  height: 28px;
  background-position: 0 -125px;
}

/* 커리어 햄버거 메뉴 */
.career_header .header_wrap .hamburger_menu .icon:before, .career_header .header_wrap .hamburger_menu .icon:after {
  background-color: #000;
}

.container {
  min-height: 100%;
  padding-bottom: 197px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.container.sub_contact {
  padding-bottom: 0;
}
