/* 팝업 타이틀 스타일 */
.list_box strong {
  display: block;
  padding: 6px 0;
  border-bottom: 1px solid #000;
  font-family: Museo_Slab_900, sans-serif;
  font-size: 14px;
}

.list_box .list {
  max-height: 184px;
  overflow-y: auto;
  padding-top: 8px;
  font-size: 12px;
  color: #000;
  word-break: keep-all;
}

.list_box .list::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.list_box .list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.list_box dl::after {
  content: '';
  display: block;
  clear: both;
}

.list_box dl+dl {
  margin-top: 8px;
}

.list_box .list_title {
  float: left;
  width: 101px;
  margin-right: 24px;
  font-weight: bold;
}

.list_box .list_text {
  overflow: hidden;
}

.btn_popup_close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 0;
  font-size: 12px;
  line-height: 16px;
}

.btn_popup_close::before {
  display: inline-block;
  vertical-align: -3px;
  content: '';
  width: 14px;
  height: 14px;
  background: url('../img/sp_ico.png') no-repeat;
  background-position: -30px -26px;
  background-size: 150px 1000px;
  -webkit-background-size: 150px 1000px;
}

.btn_popup_close span.mo_off {
  display: none;
}

.fullpage_wrapper.work .btn_box li .btn_work_link {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  line-height: 14px;
  vertical-align: top;
}

.fullpage_wrapper.work .btn_box li .btn_work_link:after {
  content: '';
  display: inline-block;
  width: 7px;
  height: 11px;
  margin: 3px 0 0 1px;
  background: url(../img/mobile/sp_arrow.png) -93px -688px no-repeat;
  background-size: 100px 900px;
  vertical-align: top;
}

.fullpage_wrapper.work .btn_box li span.btn_work_link:after {
  display: none
}

.fullpage_wrapper.work .btn_box li {
  display: inline-block;
  margin-top: 8px;
  margin-right: 16px;
  vertical-align: middle;
  position: relative;
}

.fullpage_wrapper.work.white .slide .slide_inner .page_tit {
  color: #fff;
}

.fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .webtoon_tit, .fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .webtoon_tit2 {
  color: #fff;
}

.fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .webtoon_sub {
  color: #fff;
}

.fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_list {
  color: #fff;
}

.fullpage_wrapper.work.white .slider-for .slick-active.on .btn_box .btn_work_list {
  border-bottom: 1px solid #fff;
}

.fullpage_wrapper.work.white .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_link {
  color: #fff;
}

.fullpage_wrapper.work.white .btn_box li .btn_work_list:after {
  background: url(../img/mobile/sp_arrow.png) -93px -674px no-repeat;
  background-size: 100px 900px;
}

.fullpage_wrapper.work.white .btn_box li .btn_work_link:after {
  background: url(../img/mobile/sp_arrow.png) -93px -674px no-repeat;
  background-size: 100px 900px;
}

.fullpage_wrapper.work .slide .slide_inner .webtoon_explanation .btn_box li .btn_work_link .txt_en {
  font-family: Museo_Sans_500, sans-serif;
  font-weight: normal;
}

.fullpage_wrapper.work.white .work_button_next {
  background-position: -31px -133px;
}

.fullpage_wrapper.work.white .work_button_next.off {
  background-position: -31px -211px;
}

.fullpage_wrapper.work.white .work_button_prev {
  background-position: 0 -133px;
}

.fullpage_wrapper.work.white .work_button_prev.off {
  background-position: 0 -211px;
}

/* ie9 대응 팝업 */
.ie9_exp_ly {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 210;
  width: 520px;
  margin: -168px 0 0 -310px;
  padding: 80px 50px;
  background-color: #fff;
}

.ie9_exp_ly .ie9_ly_inner p {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.45;
  text-align: center;
  font-family: NotoSansM, sans-serif;
}

.ie9_exp_ly .ie9_ly_inner p .en {
  font-family: Museo_Sans_500, sans-serif;
}

.ie9_exp_ly .ie9_ly_inner .bottom_line {
  margin-top: 20px;
}

.ie9_exp_ly .ie9_ly_close {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 32px;
  height: 32px;
  background: url("../img/sp_ico.png") 0 0 no-repeat;
  background-size: 150px 1000px;
  background-position: 0 -956px;
}

@media screen and (max-width: 520px) {
  .ie9_exp_ly {
    left: 0;
    width: 100%;
    margin: -168px 0 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
