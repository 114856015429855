/* about 업데이트 > about */
.sub_about_top_img {
  position: relative;
  width: 314px;
  height: 179px;
  margin: 30px auto 0;
  background: url(../img/about/img_about_750.jpg) no-repeat;
  -webkit-background-size: 314px 179px;
  background-size: 314px 179px;
}

.sub_about_top_img:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #000;
}

@media screen and (min-width: 667px) {
  .sub_about_top_img {
    width: 607px;
    height: 294px;
    background: url(../img/about/img_about_1334.jpg) no-repeat;
    -webkit-background-size: 607px 294px;
    background-size: 607px 294px;
  }
}

@media screen and (min-width: 768px) {
  .sub_about_top_img {
    width: 658px;
    height: 201px;
    margin: 36px auto 0;
    background: url(../img/about/img_about_1536.jpg) no-repeat;
    -webkit-background-size: 658px 201px;
    background-size: 658px 201px;
  }
}

@media screen and (min-width: 1024px) {
  .sub_about_top_img {
    width: 914px;
    height: 280px;
    margin: 40px auto 0;
    background: url(../img/about/img_about_2048.jpg) no-repeat;
    -webkit-background-size: 914px 280px;
    background-size: 914px 280px;
  }
}

@media screen and (min-width: 1280px) {
  .sub_about_top_img {
    width: 1200px;
    height: 367px;
    background: url(../img/about/img_about_pc.jpg) no-repeat;
    -webkit-background-size: 1200px 367px;
    background-size: 1200px 367px;
  }

  .sub_about_top_img:after {
    height: 2px;
  }

  .sub_about_top .explanation:first-of-type {
    margin-top: 17px;
  }

  .sub_about_top .explanation {
    margin-top: 28px;
  }
}

/* about 업데이트 > history */
.established_box {
  padding: 0 30px;
}

.established_box .history_title {
  margin: 80px 0 0;
  text-align: left;
}

.established_box .history_title span {
  width: 140px;
  height: 22px;
  background-position: 0 -513px;
}

.history_line {
  display: none;
}

.sub_about_diagram .en_spot {
  font-family: "Museo_Sans_700";
  letter-spacing: 0;
}

@media screen and (max-height: 449px) and (max-width: 812px) and (min-width: 568px) {
  .sub_about_top {
    padding-top: 89px;
    padding-bottom: 0;
  }

  .sub_about_top .established_box {
    position: relative;
    max-width: 1024px;
  }

  .sub_about_diagram {
    margin-top: 26px;
    font-size: 0;
    overflow-y: auto;
  }

  .established_box .history_title {
    margin-top: 56px;
  }

  .sub_about_diagram ul {
    width: 2144px;
    height: 94px;
    padding-top: 30px;
    margin-left: -52px;
  }

  .sub_about_diagram ul:before {
    top: 76px;
    left: 90px;
    right: 90px;
    width: auto;
    height: 1px;
  }

  .sub_about_diagram:after {
    content: "";
    position: absolute;
    top: 46px;
    right: 30px;
    width: 100px;
    height: 124px;
    background: linear-gradient(to left,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0));
  }

  .sub_about_diagram ul li:first-child,
  .sub_about_diagram ul li:nth-child(3),
  .sub_about_diagram ul li:nth-child(4),
  .sub_about_diagram ul li:nth-child(5),
  .sub_about_diagram ul li:nth-child(6),
  .sub_about_diagram ul li:nth-child(7),
  .sub_about_diagram ul li:nth-child(8),
  .sub_about_diagram ul li:nth-child(9),
  .sub_about_diagram ul li:nth-child(10),
  .sub_about_diagram ul li:nth-child(11),
  .sub_about_diagram ul li:nth-child(12) {
    margin-top: 0;
  }

  .sub_about_diagram ul li:first-child {
    margin-left: 4px;
  }

  .sub_about_diagram ul li {
    float: left;
    width: 178px;
    height: 100%;
    margin: 0;
  }

  .sub_about_diagram ul li .detail_years,
  .sub_about_diagram ul li strong {
    position: absolute;
    width: 180px;
    margin: 0;
    text-align: center;
  }

  .sub_about_diagram ul li .detail_years {
    bottom: 14px;
  }

  .sub_about_diagram ul li strong {
    bottom: 62px;
  }

  .sub_about_diagram ul li .detail_next {
    top: 22px;
    bottom: auto;
    margin-left: 0;
  }

  .sub_about_diagram ul li:before {
    left: 50%;
  }

  .sub_about_circle {
    top: 50%;
    left: 50%;
    margin: -45.5px 0 0 -45.5px;
  }
}

@media screen and (min-width: 667px) {
  .sub_about_top {
    padding-top: 89px;
    padding-bottom: 40px;
  }

  .sub_about_top .established_box {
    position: relative;
    max-width: 1024px;
  }

  .sub_about_diagram {
    margin-top: 26px;
    font-size: 0;
    overflow-y: auto;
  }

  .established_box .history_title {
    margin-top: 56px;
  }

  .sub_about_diagram ul {
    width: 2144px;
    height: 94px;
    padding-top: 30px;
    margin-left: -52px;
  }

  .sub_about_diagram ul:before {
    top: 76px;
    left: 90px;
    right: 90px;
    width: auto;
    height: 1px;
  }

  .sub_about_diagram:after {
    content: "";
    position: absolute;
    top: 46px;
    right: 30px;
    width: 100px;
    height: 124px;
    background: linear-gradient(to left,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0));
  }

  .sub_about_diagram ul li:first-child,
  .sub_about_diagram ul li:nth-child(3),
  .sub_about_diagram ul li:nth-child(4),
  .sub_about_diagram ul li:nth-child(5),
  .sub_about_diagram ul li:nth-child(6),
  .sub_about_diagram ul li:nth-child(7),
  .sub_about_diagram ul li:nth-child(8),
  .sub_about_diagram ul li:nth-child(9),
  .sub_about_diagram ul li:nth-child(10),
  .sub_about_diagram ul li:nth-child(11),
  .sub_about_diagram ul li:nth-child(12) {
    margin-top: 0;
  }

  .sub_about_diagram ul li:first-child {
    margin-left: 4px;
    margin-bottom: 0;
  }

  .sub_about_diagram ul li {
    float: left;
    width: 178px;
    height: 100%;
    margin: 0;
  }

  .sub_about_diagram ul li .detail_years,
  .sub_about_diagram ul li strong {
    position: absolute;
    width: 180px;
    margin: 0;
    text-align: center;
  }

  .sub_about_diagram ul li .detail_years {
    bottom: 14px;
  }

  .sub_about_diagram ul li strong {
    bottom: 62px;
  }

  .sub_about_diagram ul li .detail_next {
    top: 22px;
    bottom: auto;
    margin-left: 0;
  }

  .sub_about_diagram ul li:before {
    left: 50%;
  }

  .sub_about_circle {
    top: 50%;
    left: 50%;
    margin: -45.5px 0 0 -45.5px;
  }
}

@media screen and (min-width: 768px) {
  .sub_about_top {
    padding-bottom: 54px;
  }

  .established_box {
    padding: 0 55px;
  }

  .sub_about_diagram {
    position: relative;
  }

  .sub_about_diagram:after {
    display: none;
  }

  .sub_about_diagram ul {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding-top: 0;
    overflow: hidden;
  }

  .sub_about_diagram ul:before {
    display: none;
  }

  .sub_about_diagram ul li {
    float: left;
    width: 33.3%;
    height: auto;
    min-height: 100px;
  }

  .sub_about_diagram ul li:first-child {
    margin-left: 0;
    margin-bottom: 0;
  }

  .sub_about_diagram ul li:first-child,
  .sub_about_diagram ul li:nth-child(3),
  .sub_about_diagram ul li:nth-child(4),
  .sub_about_diagram ul li:nth-child(5),
  .sub_about_diagram ul li:nth-child(6),
  .sub_about_diagram ul li:nth-child(7),
  .sub_about_diagram ul li:nth-child(8),
  .sub_about_diagram ul li:nth-child(9),
  .sub_about_diagram ul li:nth-child(10),
  .sub_about_diagram ul li:nth-child(11),
  .sub_about_diagram ul li:nth-child(12) {
    margin-top: 59px;
  }

  .sub_about_diagram ul li:first-child,
  .sub_about_diagram ul li:nth-child(2),
  .sub_about_diagram ul li:nth-child(3) {
    margin-top: 0;
  }

  .sub_about_diagram ul li:nth-child(4),
  .sub_about_diagram ul li:nth-child(5),
  .sub_about_diagram ul li:nth-child(6),
  .sub_about_diagram ul li:nth-child(10),
  .sub_about_diagram ul li:nth-child(11),
  .sub_about_diagram ul li:nth-child(12) {
    float: right;
  }

  .sub_about_diagram ul li:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    height: 1px;
    background-color: #000;
  }

  .sub_about_diagram ul li .detail_years,
  .sub_about_diagram ul li strong {
    position: absolute;
    width: 100%;
    text-align: center;
    letter-spacing: 0;
    line-height: 23px;
  }

  .sub_about_diagram ul li .detail_years {
    bottom: 17px;
    margin: 0;
  }

  .sub_about_diagram ul li strong {
    bottom: 64px;
    margin-left: 0;
    letter-spacing: 0;
    line-height: 23px;
  }

  .sub_about_diagram ul li .detail_next {
    bottom: 62px;
    margin-left: 0;
  }

  .sub_about_diagram ul li:before {
    left: 50%;
  }

  .sub_about_circle {
    top: 50px;
    left: 50%;
    margin-left: -45.5px;
  }

  .history_line {
    display: block;
    position: absolute;
    width: 1px;
    height: 160px;
    background-color: #000;
  }

  .history_line.right_top {
    top: 49px;
    right: 0;
  }

  .history_line.left_middle {
    top: 208px;
    left: 0;
  }

  .history_line.right_bottom {
    top: 367px;
    right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .sub_about_top {
    padding-bottom: 57px;
  }

  .established_box .history_title {
    margin-top: 90px;
  }

  .sub_about_diagram {
    margin-top: 35px;
  }

  .sub_about_diagram ul li {
    width: 25%;
  }

  .sub_about_diagram ul li:nth-child(4) {
    margin-top: 0;
  }

  .sub_about_diagram ul li:nth-child(4),
  .sub_about_diagram ul li:nth-child(10),
  .sub_about_diagram ul li:nth-child(11),
  .sub_about_diagram ul li:nth-child(12) {
    float: left;
  }

  .sub_about_diagram ul li:nth-child(7),
  .sub_about_diagram ul li:nth-child(8) {
    float: right;
  }

  .right_bottom {
    display: none;
  }

  .sub_about_diagram ul li strong,
  .sub_about_diagram ul li .detail_years {
    font-size: 16px;
  }

  .sub_about_diagram ul li strong {
    bottom: 65px;
  }

  .sub_about_diagram ul li .detail_years {
    bottom: 13px;
  }

  .sub_about_diagram ul li .detail_next {
    top: 17px;
    bottom: auto;
  }
}

@media screen and (min-width: 1280px) {
  .sub_about_top {
    padding-bottom: 47px;
  }

  .sub_about_top .established_box {
    max-width: 1200px;
  }

  .sub_about_diagram {
    width: 1200px;
    margin: 37px auto 0;
  }

  .established_box .history_title span {
    width: 163px;
    height: 25px;
    background-position: 0 -179px;
  }

  .sub_about_diagram ul li strong {
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  .sub_about_diagram ul li:before {
    width: 10px;
    height: 10px;
  }

  .sub_about_diagram ul li:after {
    height: 2px;
    bottom: 48px;
  }

  .sub_about_diagram ul li .detail_years {
    font-size: 14px;
  }

  .sub_about_circle {
    top: 51px;
    margin-left: -44px;
  }

  .sub_about_diagram ul li .detail_next {
    top: 13px;
    font-size: 24px;
  }

  .history_line {
    width: 2px;
  }

  .history_line.right_top {
    top: 50px;
  }

  .history_line.left_middle {
    top: 209px;
  }
}

/* about 업데이트 > office */
.office_img_slid_box .slider-for .slick-slide {
  height: 300px;
  outline: 0;
}

.about_bottom_inner .slider-nav {
  margin: 6px 6px 0 0;
  padding-bottom: 24px;
}

.about_bottom_inner .slider-nav:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: #fff;
  z-index: 10;
}

.about_bottom_inner .slider-nav .slick-slide {
  position: relative;
  height: 47px;
  padding-left: 6px;
  outline: 0;
  box-sizing: border-box;
}

.about_bottom_inner .slider-nav .slick-slide:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.5;
}

.about_bottom_inner .slider-nav .slick-slide.slick-current:after {
  opacity: 0;
}

.slick-slide img {
  width: 100%;
  height: 100%;
}

.about_bottom_inner .slick-prev,
.about_bottom_inner .slick-next {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 30;
  width: 50px;
  height: 50px;
  background-color: rgba(36, 36, 36, 0.35);
  text-align: center;
  outline: 0;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.about_bottom_inner .slick-prev {
  left: 0;
}

.about_bottom_inner .slick-prev:hover,
.about_bottom_inner .slick-prev:focus,
.about_bottom_inner .slick-next:hover,
.about_bottom_inner .slick-next:focus {
  background: rgba(36, 36, 36, 0.35);
}

.about_bottom_inner .slick-next {
  right: 0;
}

.about_bottom_inner .slick-prev:after,
.about_bottom_inner .slick-next:after {
  content: "";
  display: block;
  width: 13px;
  height: 25px;
  margin: 0 auto;
  background: url(../img/mobile/sp_arrow.png) no-repeat;
  background-size: 100px 900px;
  opacity: 1;
}

.about_bottom_inner .slick-prev:after {
  background-position: 0 -738px;
}

.about_bottom_inner .slick-next:after {
  background-position: -20px -738px;
}

.slider-nav .slick-dots {
  bottom: 0;
}

.slider-nav .slick-dots li,
.slider-nav .slick-dots li button {
  height: 3px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.slider-nav .slick-dots li button:before {
  display: none;
}

.slider-nav .slick-dots li button {
  padding: 0;
}

.slider-nav .slick-dots .slick-active {
  background-color: #000;
}

@media screen and (max-height: 449px) and (max-width: 812px) and (min-width: 568px) {
  .office_img_slid_box .slider-for .slick-slide {
    height: 445px;
  }

  .about_bottom_inner .slider-nav {
    margin: 8px 6px 0 0;
    padding-right: 4px;
  }

  .about_bottom_inner .slider-nav:before {
    width: 8px;
  }

  .about_bottom_inner .slider-nav .slick-slide {
    height: 63px;
    padding-left: 8px;
  }

  .about_bottom_inner .slick-prev,
  .about_bottom_inner .slick-next {
    width: 60px;
    height: 60px;
  }

  .about_bottom_inner .slick-prev:after,
  .about_bottom_inner .slick-next:after {
    width: 15px;
    height: 30px;
  }

  .about_bottom_inner .slick-prev:after {
    background-position: 0 -774px;
  }

  .about_bottom_inner .slick-next:after {
    background-position: -18px -774px;
  }
}

@media screen and (min-width: 667px) {
  .about_bottom_inner .slider-nav {
    margin: 8px 0 0 0;
    padding-right: 8px;
  }

  .about_bottom_inner .slider-nav:before {
    width: 10px;
  }

  .about_bottom_inner .slider-nav .slick-slide {
    height: 63px;
    padding-left: 8px;
  }

  .about_bottom_inner .slick-prev,
  .about_bottom_inner .slick-next {
    width: 60px;
    height: 60px;
  }

  .about_bottom_inner .slick-prev:after,
  .about_bottom_inner .slick-next:after {
    width: 15px;
    height: 30px;
  }

  .about_bottom_inner .slick-prev:after {
    background-position: 0 -774px;
  }

  .about_bottom_inner .slick-next:after {
    background-position: -18px -774px;
  }
}

@media screen and (min-width: 768px) {
  .sub_about_bottom {
    padding: 0 55px;
  }

  .office_img_slid_box .slider-for .slick-slide {
    height: 370px;
  }

  .about_bottom_inner .slider-nav {
    margin: 8px 0 0 -8px;
    padding-right: 0;
    padding-bottom: 27px;
  }

  .about_bottom_inner .slider-nav .slick-slide {
    height: 58px;
  }

  .about_bottom_inner .slider-nav .slick-dots {
    left: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .office_img_slid_box .slider-for .slick-slide {
    height: 514px;
  }

  .about_bottom_inner .slider-nav .slick-slide {
    height: 81px;
    padding-left: 10px;
  }

  .about_bottom_inner .slider-nav {
    margin: 10px 0 0 -10px;
    padding-bottom: 24px;
  }

  .about_bottom_inner .slick-prev,
  .about_bottom_inner .slick-next {
    width: 70px;
    height: 70px;
  }

  .about_bottom_inner .slick-prev:after,
  .about_bottom_inner .slick-next:after {
    width: 18px;
    height: 35px;
  }

  .about_bottom_inner .slick-prev:after {
    background-position: 0 -811px;
  }

  .about_bottom_inner .slick-next:after {
    background-position: -20px -811px;
  }
}

@media screen and (min-width: 1280px) {
  .office_img_slid_box .slider-for .slick-slide {
    height: 675px;
  }

  .about_bottom_inner .slider-nav {
    margin: 12px 0 0 -12px;
    padding-bottom: 28px;
  }

  .about_bottom_inner .slider-nav:before {
    width: 12px;
  }

  .about_bottom_inner .slider-nav .slick-slide {
    height: 107px;
    padding-left: 12px;
  }

  .about_bottom_inner .slick-prev,
  .about_bottom_inner .slick-next {
    width: 80px;
    height: 80px;
    margin-top: 20px;
  }

  .about_bottom_inner .slick-prev:after,
  .about_bottom_inner .slick-next:after {
    width: 25px;
    height: 42px;
    background: url(../img/sp_ico.png) no-repeat;
    background-size: 150px 1000px;
  }

  .about_bottom_inner .slick-prev:after {
    background-position: 0 -405px;
  }

  .about_bottom_inner .slick-next:after {
    background-position: -30px -405px;
  }

  .about_bottom_inner .gallery-thumbs {
    padding-bottom: 31px;
  }

  .about_bottom_inner .slider-nav .slick-dots {
    left: 6px;
  }

  .slider-nav .slick-dots li,
  .slider-nav .slick-dots li button {
    height: 4px;
  }

  .fullpage_wrapper.work .slider-for .slide {
    height: 100%;
  }
}

/* work 업데이트 */
.fullpage_wrapper.work .slide,
.fullpage_wrapper.work .slide_inner {
  height: 100%;
}

.fullpage_wrapper.work .slider-for .slide {
  height: 100vh;
}

.fullpage_wrapper.work .slick-arrow {
  position: fixed;
  top: auto;
  bottom: 25%;
  width: 24px;
  height: 57px;
  background: url(../img/mobile/sp_arrow.png) 0 0 no-repeat;
  background-size: 100px 900px;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 30;
  outline: 0;
}

.fullpage_wrapper.work .slick-prev {
  left: 30px;
  background-position: 0 6px;
}

.fullpage_wrapper.work .slick-next {
  right: 30px;
  background-position: -31px 6px;
}

.fullpage_wrapper.work .slick-prev.slick-disabled {
  background-position: 0 -56px;
}

.fullpage_wrapper.work .slick-next.slick-disabled {
  background-position: -31px -56px;
}

.fullpage_wrapper.work.white .slick-prev {
  background-position: 0 -133px;
}

.fullpage_wrapper.work.white .slick-next {
  background-position: -31px -133px;
}

.fullpage_wrapper.work.white .slick-prev.slick-disabled {
  background-position: 0 -211px;
}

.fullpage_wrapper.work.white .slick-next.slick-disabled {
  background-position: -31px -211px;
}

/* work > gallery-thumbs */

.fullpage_wrapper.work .slider-nav {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: 0;
  bottom: 37px;
  width: 264px;
  padding-bottom: 13px;
  z-index: 50;
}

.fullpage_wrapper.work .slider-nav .slick-slide {
  width: 84px;
  padding-right: 6px;
  outline: 0;
}

.fullpage_wrapper.work .slider-nav .slide_nav {
  position: relative;
}

.fullpage_wrapper.work .slider-nav .slide_nav:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.fullpage_wrapper.work .slider-nav .slick-current .slide_nav:after {
  background-color: transparent;
}

.slide_title {
  display: none;
}

.fullpage_wrapper.work .slider-nav .slide_nav {
  border: 1px solid #000;
}

.fullpage_wrapper.work.white .slider-nav .slide_nav {
  border-color: #fff;
}

.fullpage_wrapper.work .slider-nav .slide {
  font-size: 20px;
}

.fullpage_wrapper.work .slider-nav #slick-slide10 {
  padding-left: 30px;
}

.fullpage_wrapper.work .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.fullpage_wrapper.work .slick-dots {
  display: flex;
  left: 0;
  right: 0;
}

.fullpage_wrapper.work .slick-dots li {
  flex: 1;
}

.fullpage_wrapper.work .slick-dots li button {
  width: 100%;
}

.fullpage_wrapper.work .slider-nav .slick-dots li,
.fullpage_wrapper.work .slider-nav .slick-dots li button {
  margin: 0;
}

.fullpage_wrapper.work.white .slider-nav .slick-dots li,
.fullpage_wrapper.work.white .slider-nav .slick-dots li button {
  background-color: rgba(255, 255, 255, 0.2);
}

.fullpage_wrapper.work.white .slider-nav .slick-dots .slick-active {
  background-color: #fff;
}

@media screen and (min-width: 1280px) {
  .fullpage_wrapper.work .slider-for .slide {
    height: 100%;
  }
}

@media screen and (min-width: 374px) {
  .fullpage_wrapper.work .slider-nav {
    width: 354px;
  }
}

@media screen and (max-height: 360px) and (max-width: 568px) and (orientation: landscape) {
  .fullpage_wrapper.work .slide .slide_inner .webtoon_explanation {
    top: 20%;
  }

  .fullpage_wrapper.work .slider-nav.slick-dotted.slick-slider {
    width: 534px;
    bottom: 10px;
  }
}

@media screen and (max-height: 449px) and (max-width: 812px) and (min-width: 568px) {
  .fullpage_wrapper.work .slick-arrow {
    top: 50%;
    margin-top: 0;
  }

  .fullpage_wrapper.work .slider-nav.slick-dotted {
    bottom: 25px;
  }

  .fullpage_wrapper.work .slider-nav #slick-slide10 {
    padding-left: 98px;
  }

  .fullpage_wrapper.work .slider-for .slick-active.on .list_box {
    top: 50%;
    left: auto;
    right: 83px;
    transform: translateY(-50%);
    width: 250px;
    padding: 14px;
  }

  .list_box .list {
    max-height: 120px;
    padding-top: 9px;
    font-size: 12px;
  }
}

@media screen and (min-width: 568px) {
  .fullpage_wrapper.work .slider-nav {
    width: 534px;
  }
}

@media screen and (min-width: 664px) {
  .fullpage_wrapper.work .slider-nav {
    width: 624px;
  }
}

@media screen and (min-width: 670px) and (max-width: 812px) and (orientation: landscape) {
  .fullpage_wrapper.work .slider-nav.slick-dotted {
    width: 558px;
  }

  .fullpage_wrapper.work .slider-nav.slick-dotted .slick-slide {
    width: 84px;
    padding-right: 6px;
  }
}

@media screen and (min-width: 768px) {
  .fullpage_wrapper.work .slider-nav .slide {
    padding: 0 4px;
  }

  .fullpage_wrapper.work .slick-dots {
    width: auto;
    left: 4px;
    right: 4px;
  }

  .fullpage_wrapper.work .slider-nav {
    left: 50%;
    bottom: 56px;
    transform: translateX(-50%);
    right: 0;
    width: 666px;
    margin: 0;
    padding-top: 26px;
    padding-bottom: 16px;
    overflow: hidden;
  }

  .fullpage_wrapper.work .slider-nav .slick-list {
    overflow: visible;
  }

  .fullpage_wrapper.work .slider-nav #slick-slide10 {
    padding-left: 0;
  }

  .fullpage_wrapper.work .slider-nav .slick-slide {
    width: 103px;
    padding: 0 4px;
  }

  .fullpage_wrapper.work .slider-nav .slick-dots li {
    margin: 0;
  }

  .fullpage_wrapper.work .slick-prev {
    bottom: 30%;
    left: 33px;
    width: 30px;
    height: 57px;
    background-position: 0 -285px;
    transform: none;
  }

  .fullpage_wrapper.work .slick-next {
    bottom: 30%;
    right: 33px;
    width: 30px;
    height: 57px;
    background-position: -35px -285px;
    transform: none;
  }

  .fullpage_wrapper.work .slick-prev.slick-disabled {
    background-position: 0 -357px;
  }

  .fullpage_wrapper.work .slick-next.slick-disabled {
    background-position: -35px -357px;
  }

  .fullpage_wrapper.work.white .slick-prev {
    background-position: 0 -435px;
  }

  .fullpage_wrapper.work.white .slick-next {
    background-position: -35px -435px;
  }

  .fullpage_wrapper.work.white .slick-prev.slick-disabled {
    background-position: 0 -513px;
  }

  .fullpage_wrapper.work.white .slick-next.slick-disabled {
    background-position: -35px -513px;
  }

  .fullpage_wrapper.work .slider-nav .slick-current {
    margin-top: -26px;
  }

  .fullpage_wrapper.work .slider-nav .slick-current .slide_title {
    display: block;
    height: 26px;
    padding: 0 8px;
    background-color: #000;
    line-height: 24px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    letter-spacing: -0.2px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-weight: normal;
    font-family: "NotoSansR";
  }

  .fullpage_wrapper.work.white .slider-nav .slick-current .slide_title {
    background-color: #fff;
    color: #000;
  }
}

@media screen and (min-width: 768px) and (max-width: 812px) and (orientation: landscape) {
  .fullpage_wrapper.work .slider-nav .slick-current .slide_title {
    display: none;
  }

  .fullpage_wrapper.work .slider-nav .slick-current {
    margin-top: 0;
  }
}

@media screen and (min-width: 668px) and (max-height: 668px) and (orientation: landscape) {
  .fullpage_wrapper.work .slider-for .slick-active.on .list_box {
    top: 40%;
    left: auto;
    right: 100px;
    transform: translateY(-40%);
    width: 320px;
    max-width: none;
  }

  .list_box .list {
    max-height: 123px;
  }
}

@media screen and (min-height: 550px) and (min-width: 1024px) {
  .fullpage_wrapper.work.white .slick-arrow {
    top: 50%;
    bottom: auto;
  }
}

@media screen and (min-width: 1024px) {
  .fullpage_wrapper.work .slider-nav {
    width: 786px;
  }

  .fullpage_wrapper.work .slider-nav .slick-slide {
    width: 123px;
    padding: 0 4px;
  }

  .fullpage_wrapper.work .slick-dots {
    width: auto;
    left: 4px;
    right: 4px;
  }

  .fullpage_wrapper.work .slick-arrow {
    top: 50%;
    bottom: auto;
  }
}

@media screen and (min-width: 1280px) {
  .fullpage_wrapper.work .slider-nav {
    bottom: 91px;
    width: 1128px;
    margin: 0 auto 30px;
    padding-bottom: 18px;
  }

  .fullpage_wrapper.work .slider-nav .slick-slide {
    width: 131px;
    height: 80px;
    padding: 0 5px;
  }

  .fullpage_wrapper.work .slick-dots {
    width: auto;
    left: 5px;
    right: 5px;
  }

  .fullpage_wrapper.work .slider-nav .slick-dots li,
  .fullpage_wrapper.work .slider-nav .slick-dots li button {
    height: 4px;
  }

  .fullpage_wrapper.work .slider-nav {
    padding-top: 28px;
  }

  .fullpage_wrapper.work .slider-nav .slick-current {
    margin-top: -28px;
  }

  .fullpage_wrapper.work .slider-nav .slick-current .slide_title {
    height: 28px;
    line-height: 26px;
    font-size: 13px;
  }

  .fullpage_wrapper.work .slick-arrow {
    top: 50%;
    width: 60px;
    height: 104px;
    background: url(../img/sp_ico.png) 0 0 no-repeat;
    background-size: 150px 1000px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .fullpage_wrapper.work .slick-prev {
    left: 78px;
    background-position: 0 -480px;
  }

  .fullpage_wrapper.work .slick-next {
    right: 78px;
    background-position: -75px -480px;
  }

  .fullpage_wrapper.work .slick-prev.slick-disabled {
    background-position: 0 -595px;
  }

  .fullpage_wrapper.work .slick-next.slick-disabled {
    background-position: -75px -595px;
  }

  .fullpage_wrapper.work.white .slick-prev {
    left: 78px;
    background-position: 0 -710px;
  }

  .fullpage_wrapper.work.white .slick-next {
    right: 78px;
    background-position: -75px -710px;
  }

  .fullpage_wrapper.work.white .slick-prev.slick-disabled {
    background-position: 0 -825px;
  }

  .fullpage_wrapper.work.white .slick-next.slick-disabled {
    background-position: -75px -825px;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav {
    border-width: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1480px) {
  .fullpage_wrapper.work .slider-nav {
    width: 1410px;
  }
}

/* work > bg */

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg {
  width: 100%;
  height: 100vh;
  min-height: 900px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

/* work bg > full */

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg1 {
  background-image: url(../img/work/work_750_cilist.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg2 {
  background-image: url(../img/work/work_750_propose.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg3 {
  background-image: url(../img/work/work_750_zombie.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg4 {
  background-image: url(../img/work/work_750_hong.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg5 {
  background-image: url(../img/work/work_750_nf.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg6 {
  background-image: url(../img/work/work_750_hsgh.png);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg7 {
  background-image: url(../img/work/work_750_nmby.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg8 {
  background-image: url(../img/work/work_750_wald.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg9 {
  background-image: url(../img/work/work_750_vegetable_garden.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg10 {
  background-image: url(../img/work/work_750_ghosttheater.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg11 {
  background-image: url(../img/work/work_750_gotboys.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg12 {
  background-image: url(../img/work/work_750_nyanya.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg13 {
  background-image: url(../img/work/work_750_ghostteller.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg14 {
  background-image: url(../img/work/work_750_saveme.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg15 {
  background-image: url(../img/work/work_750_ateen.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg16 {
  background-image: url(../img/work/work_750_imback.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg17 {
  background-image: url(../img/work/work_750_teenagers.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg18 {
  background-image: url(../img/work/work_750_adbu.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg19 {
  background-image: url(../img/work/work_750_rfhs.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg20 {
  background-image: url(../img/work/work_750_hg.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg21 {
  background-image: url(../img/work/work_750_slave.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg22 {
  background-image: url(../img/work/work_750_master.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg23 {
  background-image: url(../img/work/work_750_youngboss.png);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg24 {
  background-image: url(../img/work/work_750_seogwajang.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg25 {
  background-image: url(../img/work/work_750_oppressor.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg26 {
  background-image: url(../img/work/work_750_skin.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg27 {
  background-image: url(../img/work/work_750_wicked.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg28 {
  background-image: url(../img/work/work_750_violence.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg29 {
  background-image: url(../img/work/work_750_game.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg30 {
  background-image: url(../img/work/work_750_regret.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg31 {
  background-image: url(../img/work/work_750_empress.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg32 {
  background-image: url(../img/work/work_750_seoul.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg33 {
  background-image: url(../img/work/work_750_eunuch.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg34 {
  background-image: url(../img/work/work_750_tycoon.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg35 {
  background-image: url(../img/work/work_750_warrior.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg36 {
  background-image: url(../img/work/work_750_baby.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg37 {
  background-image: url(../img/work/work_750_friend.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg38 {
  background-image: url(../img/work/work_750_brobro.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg39 {
  background-image: url(../img/work/work_750_death.jpg);
}

.fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg40 {
  background-image: url(../img/work/work_750_marriage.jpg);
}

/* work bg > navigation */

.fullpage_wrapper.work .slider-nav .slide_nav_bg1 {
  background: url(../img/work_thumb/mo_th_cilist.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg2 {
  background: url(../img/work_thumb/mo_th_propose.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg3 {
  background: url(../img/work_thumb/mo_th_zombie.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg4 {
  background: url(../img/work_thumb/mo_th_hong.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg5 {
  background: url(../img/work_thumb/mo_th_nf.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg6 {
  background: url(../img/work_thumb/mo_th_hsgh.png) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg7 {
  background: url(../img/work_thumb/mo_th_nmby.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg8 {
  background: url(../img/work_thumb/mo_th_wald.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg9 {
  background: url(../img/work_thumb/mo_th_vegetable_garden.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg10 {
  background: url(../img/work_thumb/mo_th_hosttheater.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg11 {
  background: url(../img/work_thumb/mo_th_gotboys.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg12 {
  background: url(../img/work_thumb/mo_th_nyanya.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg13 {
  background: url(../img/work_thumb/mo_th_ghostteller.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg14 {
  background: url(../img/work_thumb/mo_th_saveme.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg15 {
  background: url(../img/work_thumb/mo_th_ateen.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg16 {
  background: url(../img/work_thumb/mo_th_imback.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg17 {
  background: url(../img/work_thumb/mo_th_teenagers.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg18 {
  background: url(../img/work_thumb/mo_th_adbu.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg19 {
  background: url(../img/work_thumb/mo_th_rfhs.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg20 {
  background: url(../img/work_thumb/mo_th_hg.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg21 {
  background: url(../img/work_thumb/mo_th_slave.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg22 {
  background: url(../img/work_thumb/mo_th_master.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg23 {
  background: url(../img/work_thumb/mo_th_youngboss.png) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg24 {
  background: url(../img/work_thumb/mo_th_seogwajang.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg25 {
  background: url(../img/work_thumb/mo_th_oppressor.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg26 {
  background: url(../img/work_thumb/mo_th_skin.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg27 {
  background: url(../img/work_thumb/mo_th_wicked.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg28 {
  background: url(../img/work_thumb/mo_th_violence.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg29 {
  background: url(../img/work_thumb/mo_th_game.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg30 {
  background: url(../img/work_thumb/mo_th_regret.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg31 {
  background: url(../img/work_thumb/mo_th_empress.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg32 {
  background: url(../img/work_thumb/mo_th_seoul.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg33 {
  background: url(../img/work_thumb/mo_th_eunuch.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg34 {
  background: url(../img/work_thumb/mo_th_tycoon.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg35 {
  background: url(../img/work_thumb/mo_th_warrior.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg36 {
  background: url(../img/work_thumb/mo_th_baby.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg37 {
  background: url(../img/work_thumb/mo_th_friend.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg38 {
  background: url(../img/work_thumb/mo_th_brobro.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg39 {
  background: url(../img/work_thumb/mo_th_death.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg40 {
  background: url(../img/work_thumb/mo_th_marriage.jpg) no-repeat 50% 50%;
}

.fullpage_wrapper.work .slider-nav .slide_nav_bg1,
.fullpage_wrapper.work .slider-nav .slide_nav_bg2,
.fullpage_wrapper.work .slider-nav .slide_nav_bg3,
.fullpage_wrapper.work .slider-nav .slide_nav_bg4,
.fullpage_wrapper.work .slider-nav .slide_nav_bg5,
.fullpage_wrapper.work .slider-nav .slide_nav_bg6,
.fullpage_wrapper.work .slider-nav .slide_nav_bg7,
.fullpage_wrapper.work .slider-nav .slide_nav_bg8,
.fullpage_wrapper.work .slider-nav .slide_nav_bg9,
.fullpage_wrapper.work .slider-nav .slide_nav_bg10,
.fullpage_wrapper.work .slider-nav .slide_nav_bg11,
.fullpage_wrapper.work .slider-nav .slide_nav_bg12,
.fullpage_wrapper.work .slider-nav .slide_nav_bg13,
.fullpage_wrapper.work .slider-nav .slide_nav_bg14,
.fullpage_wrapper.work .slider-nav .slide_nav_bg15,
.fullpage_wrapper.work .slider-nav .slide_nav_bg16,
.fullpage_wrapper.work .slider-nav .slide_nav_bg17,
.fullpage_wrapper.work .slider-nav .slide_nav_bg18,
.fullpage_wrapper.work .slider-nav .slide_nav_bg19,
.fullpage_wrapper.work .slider-nav .slide_nav_bg20,
.fullpage_wrapper.work .slider-nav .slide_nav_bg21,
.fullpage_wrapper.work .slider-nav .slide_nav_bg22,
.fullpage_wrapper.work .slider-nav .slide_nav_bg23,
.fullpage_wrapper.work .slider-nav .slide_nav_bg24,
.fullpage_wrapper.work .slider-nav .slide_nav_bg25,
.fullpage_wrapper.work .slider-nav .slide_nav_bg26,
.fullpage_wrapper.work .slider-nav .slide_nav_bg27,
.fullpage_wrapper.work .slider-nav .slide_nav_bg28,
.fullpage_wrapper.work .slider-nav .slide_nav_bg29,
.fullpage_wrapper.work .slider-nav .slide_nav_bg30,
.fullpage_wrapper.work .slider-nav .slide_nav_bg31,
.fullpage_wrapper.work .slider-nav .slide_nav_bg32,
.fullpage_wrapper.work .slider-nav .slide_nav_bg33,
.fullpage_wrapper.work .slider-nav .slide_nav_bg34,
.fullpage_wrapper.work .slider-nav .slide_nav_bg35,
.fullpage_wrapper.work .slider-nav .slide_nav_bg36,
.fullpage_wrapper.work .slider-nav .slide_nav_bg37,
.fullpage_wrapper.work .slider-nav .slide_nav_bg38,
.fullpage_wrapper.work .slider-nav .slide_nav_bg39,
.fullpage_wrapper.work .slider-nav .slide_nav_bg40 {
  height: 50px;
  background-size: 100% 50px;
}

@media screen and (max-height: 449px) and (max-width: 812px) and (min-width: 568px) {
  /* work bg > slide-nav */

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg1 {
    background-image: url(../img/work/work_1334_cilist.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg2 {
    background-image: url(../img/work/work_1334_propose.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg3 {
    background-image: url(../img/work/work_1334_zombie.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg4 {
    background-image: url(../img/work/work_1334_hong.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg5 {
    background-image: url(../img/work/work_1334_nf.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg6 {
    background-image: url(../img/work/work_1334_hsgh.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg7 {
    background-image: url(../img/work/work_1334_nmby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg8 {
    background-image: url(../img/work/work_1334_wald.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg9 {
    background-image: url(../img/work/work_1334_vegetable_garden.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg10 {
    background-image: url(../img/work/work_1334_ghosttheater.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg11 {
    background-image: url(../img/work/work_1334_gotboys.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg12 {
    background-image: url(../img/work/work_1334_nyanya.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg13 {
    background-image: url(../img/work/work_1334_ghostteller.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg14 {
    background-image: url(../img/work/work_1334_saveme.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg15 {
    background-image: url(../img/work/work_1334_ateen.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg16 {
    background-image: url(../img/work/work_1334_imback.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg17 {
    background-image: url(../img/work/work_1334_teenagers.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg18 {
    background-image: url(../img/work/work_1334_adbu.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg19 {
    background-image: url(../img/work/work_1334_rfhs.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg20 {
    background-image: url(../img/work/work_1334_hg.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg21 {
    background-image: url(../img/work/work_1334_slave.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg22 {
    background-image: url(../img/work/work_1334_master.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg23 {
    background-image: url(../img/work/work_1334_youngboss.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg24 {
    background-image: url(../img/work/work_1334_seogwajang.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg25 {
    background-image: url(../img/work/work_1334_oppressor.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg26 {
    background-image: url(../img/work/work_1334_skin.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg27 {
    background-image: url(../img/work/work_1334_wicked.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg28 {
    background-image: url(../img/work/work_1334_violence.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg29 {
    background-image: url(../img/work/work_1334_game.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg30 {
    background-image: url(../img/work/work_1334_regret.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg31 {
    background-image: url(../img/work/work_1334_empress.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg32 {
    background-image: url(../img/work/work_1334_seoul.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg33 {
    background-image: url(../img/work/work_1334_eunuch.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg34 {
    background-image: url(../img/work/work_1334_tycoon.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg35 {
    background-image: url(../img/work/work_1334_warrior.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg36 {
    background-image: url(../img/work/work_1334_baby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg37 {
    background-image: url(../img/work/work_1334_friend.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg38 {
    background-image: url(../img/work/work_1334_brobro.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg39 {
    background-image: url(../img/work/work_1334_death.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg40 {
    background-image: url(../img/work/work_1334_marriage.jpg);
  }
}

@media screen and (max-width: 812px) and (min-width: 670px) and (orientation: landscape) {

  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg1,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg2,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg3,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg4,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg5,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg6,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg7,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg8,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg9,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg10,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg11,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg12,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg13,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg14,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg15,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg16,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg17,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg18,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg19,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg20,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg21,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg22,
  .fullpage_wrapper.work .slider-nav.slick-dotted .slide_nav_bg23 {
    height: 50px;
    background-size: 100% 50px;
  }
}

@media screen and (min-width: 768px) {
  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg1 {
    background-image: url(../img/work/work_1536_cilist.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg2 {
    background-image: url(../img/work/work_1536_propose.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg3 {
    background-image: url(../img/work/work_1536_zombie.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg4 {
    background-image: url(../img/work/work_1536_hong.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg5 {
    background-image: url(../img/work/work_1536_nf.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg6 {
    background-image: url(../img/work/work_1536_hsgh.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg7 {
    background-image: url(../img/work/work_1536_nmby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg8 {
    background-image: url(../img/work/work_1536_wald.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg9 {
    background-image: url(../img/work/work_1536_vegetable_garden.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg10 {
    background-image: url(../img/work/work_1536_ghosttheater.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg11 {
    background-image: url(../img/work/work_1536_gotboys.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg12 {
    background-image: url(../img/work/work_1536_nyanya.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg13 {
    background-image: url(../img/work/work_1536_ghostteller.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg14 {
    background-image: url(../img/work/work_1536_saveme.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg15 {
    background-image: url(../img/work/work_1536_ateen.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg16 {
    background-image: url(../img/work/work_1536_imback.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg17 {
    background-image: url(../img/work/work_1536_teenagers.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg18 {
    background-image: url(../img/work/work_1536_adbu.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg19 {
    background-image: url(../img/work/work_1536_rfhs.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg20 {
    background-image: url(../img/work/work_1536_hg.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg21 {
    background-image: url(../img/work/work_1536_slave.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg22 {
    background-image: url(../img/work/work_1536_master.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg23 {
    background-image: url(../img/work/work_1536_youngboss.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg24 {
    background-image: url(../img/work/work_1536_seogwajang.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg25 {
    background-image: url(../img/work/work_1536_oppressor.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg26 {
    background-image: url(../img/work/work_1536_skin.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg27 {
    background-image: url(../img/work/work_1536_wicked.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg28 {
    background-image: url(../img/work/work_1536_violence.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg29 {
    background-image: url(../img/work/work_1536_game.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg30 {
    background-image: url(../img/work/work_1536_regret.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg31 {
    background-image: url(../img/work/work_1536_empress.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg32 {
    background-image: url(../img/work/work_1536_seoul.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg33 {
    background-image: url(../img/work/work_1536_eunuch.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg34 {
    background-image: url(../img/work/work_1536_tycoon.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg35 {
    background-image: url(../img/work/work_1536_warrior.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg36 {
    background-image: url(../img/work/work_1536_baby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg37 {
    background-image: url(../img/work/work_1536_friend.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg38 {
    background-image: url(../img/work/work_1536_brobro.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg39 {
    background-image: url(../img/work/work_1536_death.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg40 {
    background-image: url(../img/work/work_1536_marriage.jpg);
  }

  /* work bg > slide-nav */
  .fullpage_wrapper.work .slider-nav .slide_nav_bg1,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg2,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg3,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg4,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg5,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg6,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg7,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg8,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg9,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg10,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg11,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg12,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg13,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg14,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg15,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg16,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg17,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg18,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg19,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg20,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg21,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg22,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg23,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg24,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg25,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg26,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg27,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg28,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg29,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg30,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg31,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg32,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg33,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg34,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg35,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg36,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg37,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg38,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg39,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg40 {
    height: 61px;
    background-size: 100% 61px;
  }
}

@media screen and (min-width: 1024px) {
  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg1 {
    background-image: url(../img/work/work_2048_cilist.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg2 {
    background-image: url(../img/work/work_2048_propose.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg3 {
    background-image: url(../img/work/work_2048_zombie.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg4 {
    background-image: url(../img/work/work_2048_hong.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg5 {
    background-image: url(../img/work/work_2048_nf.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg6 {
    background-image: url(../img/work/work_2048_hsgh.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg7 {
    background-image: url(../img/work/work_2048_nmby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg8 {
    background-image: url(../img/work/work_2048_wald.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg9 {
    background-image: url(../img/work/work_2048_vegetable_garden.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg10 {
    background-image: url(../img/work/work_2048_ghosttheater.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg11 {
    background-image: url(../img/work/work_2048_gotboys.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg12 {
    background-image: url(../img/work/work_2048_nyanya.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg13 {
    background-image: url(../img/work/work_2048_ghostteller.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg14 {
    background-image: url(../img/work/work_2048_saveme.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg15 {
    background-image: url(../img/work/work_2048_ateen.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg16 {
    background-image: url(../img/work/work_2048_imback.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg17 {
    background-image: url(../img/work/work_2048_teenagers.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg18 {
    background-image: url(../img/work/work_2048_adbu.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg19 {
    background-image: url(../img/work/work_2048_rfhs.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg20 {
    background-image: url(../img/work/work_2048_hg.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg21 {
    background-image: url(../img/work/work_2048_slave.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg22 {
    background-image: url(../img/work/work_2048_master.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg23 {
    background-image: url(../img/work/work_2048_youngboss.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg24 {
    background-image: url(../img/work/work_2048_seogwajang.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg25 {
    background-image: url(../img/work/work_2048_oppressor.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg26 {
    background-image: url(../img/work/work_2048_skin.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg27 {
    background-image: url(../img/work/work_2048_wicked.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg28 {
    background-image: url(../img/work/work_2048_violence.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg29 {
    background-image: url(../img/work/work_2048_game.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg30 {
    background-image: url(../img/work/work_2048_regret.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg31 {
    background-image: url(../img/work/work_2048_empress.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg32 {
    background-image: url(../img/work/work_2048_seoul.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg33 {
    background-image: url(../img/work/work_2048_eunuch.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg34 {
    background-image: url(../img/work/work_2048_tycoon.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg35 {
    background-image: url(../img/work/work_2048_warrior.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg36 {
    background-image: url(../img/work/work_2048_baby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg37 {
    background-image: url(../img/work/work_2048_friend.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg38 {
    background-image: url(../img/work/work_2048_brobro.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg39 {
    background-image: url(../img/work/work_2048_death.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg40 {
    background-image: url(../img/work/work_2048_marriage.jpg);
  }

  /* work bg > slide-nav */
  .fullpage_wrapper.work .slider-nav .slide_nav_bg1,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg2,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg3,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg4,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg5,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg6,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg7,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg8,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg9,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg10,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg11,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg12,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg13,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg14,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg15,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg16,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg17,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg18,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg19,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg20,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg21,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg22,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg23,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg24,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg25,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg26,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg27,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg28,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg29,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg30,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg31,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg32,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg33,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg34,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg35,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg36,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg37,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg38,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg39,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg40 {
    height: 70px;
    background-size: 100% 70px;
  }
}

@media screen and (min-width: 1280px) {
  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg1 {
    background-image: url(../img/work/work_pc_cilist.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg2 {
    background-image: url(../img/work/work_pc_propose.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg3 {
    background-image: url(../img/work/work_pc_zombie.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg4 {
    background-image: url(../img/work/work_pc_hong.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg5 {
    background-image: url(../img/work/work_pc_nf.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg6 {
    background-image: url(../img/work/work_pc_hsgh.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg7 {
    background-image: url(../img/work/work_pc_nmby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg8 {
    background-image: url(../img/work/work_pc_wald.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg9 {
    background-image: url(../img/work/work_pc_vegetable_garden.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg10 {
    background-image: url(../img/work/work_pc_ghosttheater.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg11 {
    background-image: url(../img/work/work_pc_gotboys.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg12 {
    background-image: url(../img/work/work_pc_nyanya.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg13 {
    background-image: url(../img/work/work_pc_ghostteller.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg14 {
    background-image: url(../img/work/work_pc_saveme.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg15 {
    background-image: url(../img/work/work_pc_ateen.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg16 {
    background-image: url(../img/work/work_pc_imback.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg17 {
    background-image: url(../img/work/work_pc_teenagers.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg18 {
    background-image: url(../img/work/work_pc_adbu.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg19 {
    background-image: url(../img/work/work_pc_rfhs.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg20 {
    background-image: url(../img/work/work_pc_hg.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg21 {
    background-image: url(../img/work/work_pc_slave.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg22 {
    background-image: url(../img/work/work_pc_master.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg23 {
    background-image: url(../img/work/work_pc_youngboss.png);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg24 {
    background-image: url(../img/work/work_pc_seogwajang.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg25 {
    background-image: url(../img/work/work_pc_oppressor.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg26 {
    background-image: url(../img/work/work_pc_skin.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg27 {
    background-image: url(../img/work/work_pc_wicked.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg28 {
    background-image: url(../img/work/work_pc_violence.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg29 {
    background-image: url(../img/work/work_pc_game.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg30 {
    background-image: url(../img/work/work_pc_regret.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg31 {
    background-image: url(../img/work/work_pc_empress.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg32 {
    background-image: url(../img/work/work_pc_seoul.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg33 {
    background-image: url(../img/work/work_pc_eunuch.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg34 {
    background-image: url(../img/work/work_pc_tycoon.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg35 {
    background-image: url(../img/work/work_pc_warrior.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg36 {
    background-image: url(../img/work/work_pc_baby.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg37 {
    background-image: url(../img/work/work_pc_friend.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg38 {
    background-image: url(../img/work/work_pc_brobro.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg39 {
    background-image: url(../img/work/work_pc_death.jpg);
  }

  .fullpage_wrapper.work .slider-for .slide .slide_inner .slide_bg40 {
    background-image: url(../img/work/work_pc_marriage.jpg);
  }

  /* work bg > slide-nav */

  .fullpage_wrapper.work .slider-nav .slide_nav_bg1 {
    background: url(../img/work_thumb/pc_th_cilist.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg2 {
    background: url(../img/work_thumb/pc_th_propose.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg3 {
    background: url(../img/work_thumb/pc_th_zombie.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg4 {
    background: url(../img/work_thumb/pc_th_hong.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg5 {
    background: url(../img/work_thumb/pc_th_nf.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg6 {
    background: url(../img/work_thumb/pc_th_hsgh.png) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg7 {
    background: url(../img/work_thumb/pc_th_nmby.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg8 {
    background: url(../img/work_thumb/pc_th_wald.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg9 {
    background: url(../img/work_thumb/pc_th_vegetable_garden.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg10 {
    background: url(../img/work_thumb/pc_th_ghosttheater.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg11 {
    background: url(../img/work_thumb/pc_th_gotboys.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg12 {
    background: url(../img/work_thumb/pc_th_nyanya.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg13 {
    background: url(../img/work_thumb/pc_th_ghostteller.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg14 {
    background: url(../img/work_thumb/pc_th_saveme.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg15 {
    background: url(../img/work_thumb/pc_th_ateen.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg16 {
    background: url(../img/work_thumb/pc_th_imback.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg17 {
    background: url(../img/work_thumb/pc_th_teenagers.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg18 {
    background: url(../img/work_thumb/pc_th_adbu.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg19 {
    background: url(../img/work_thumb/pc_th_rfhs.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg20 {
    background: url(../img/work_thumb/pc_th_hg.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg21 {
    background: url(../img/work_thumb/pc_th_slave.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg22 {
    background: url(../img/work_thumb/pc_th_master.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg23 {
    background: url(../img/work_thumb/pc_th_youngboss.png) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg24 {
    background: url(../img/work_thumb/pc_th_seogwajang.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg25 {
    background: url(../img/work_thumb/pc_th_oppressor.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg26 {
    background: url(../img/work_thumb/pc_th_skin.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg27 {
    background: url(../img/work_thumb/pc_th_wicked.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg28 {
    background: url(../img/work_thumb/pc_th_violence.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg29 {
    background: url(../img/work_thumb/pc_th_game.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg30 {
    background: url(../img/work_thumb/pc_th_regret.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg31 {
    background: url(../img/work_thumb/pc_th_empress.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg32 {
    background: url(../img/work_thumb/pc_th_seoul.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg33 {
    background: url(../img/work_thumb/pc_th_eunuch.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg34 {
    background: url(../img/work_thumb/pc_th_tycoon.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg35 {
    background: url(../img/work_thumb/pc_th_warrior.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg36 {
    background: url(../img/work_thumb/pc_th_baby.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg37 {
    background: url(../img/work_thumb/pc_th_friend.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg38 {
    background: url(../img/work_thumb/pc_th_brobro.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg39 {
    background: url(../img/work_thumb/pc_th_death.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg40 {
    background: url(../img/work_thumb/pc_th_marriage.jpg) no-repeat 50% 50%;
  }

  .fullpage_wrapper.work .slider-nav .slide_nav_bg1,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg2,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg3,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg4,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg5,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg6,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg7,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg8,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg9,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg10,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg11,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg12,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg13,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg14,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg15,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg16,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg17,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg18,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg19,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg20,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg21,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg22,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg23,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg24,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg25,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg26,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg27,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg28,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg29,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg30,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg31,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg32,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg33,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg34,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg35,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg36,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg37,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg38,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg39,
  .fullpage_wrapper.work .slider-nav .slide_nav_bg40 {
    height: 78px;
    background-size: 100% 78px;
  }
}

/* 개인정보처리방침 업데이트 */
.cont_wrap.privacy {
  margin-bottom: 60px;
}

.cont_wrap.privacy .tab_menu_box+h3 {
  padding-top: 40px;
}

.cont_wrap.privacy .btn_prev_area+.top_txt {
  margin-top: 20px;
}

.cont_wrap.privacy .btn_prev_info {
  margin-top: 20px;
  margin-bottom: 1px;
}

.cont_wrap .privacy_notice_wrap {
  margin-top: 24px;
}

.cont_wrap .privacy_notice_wrap .notice_title_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 0;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
}

.cont_wrap .privacy_notice_wrap .notice_title {
  flex: 1;
  line-height: 20px;
  margin-bottom: 0;
  font-size: 15px;
  color: #000;
}

.cont_wrap .privacy_notice_wrap .notice_date {
  display: block;
  margin-top: 8px;
  line-height: 13px;
  font-size: 13px;
  color: #a6adb4;
}

.cont_wrap .notice_detail_box {
  padding-top: 18px;
  padding-bottom: 24px;
}

.cont_wrap .notice_detail_box h3 {
  margin-top: 32px;
  margin-bottom: 16px;
  padding-top: 32px;
  color: #000;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl th {
  text-align: center;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl td {
  vertical-align: top;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl strong {
  display: block;
}

.cont_wrap .notice_detail_box a {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  word-break: break-all;
}

.cont_wrap .notice_detail_box .detail_box_content>p {
  line-height: 24px;
  margin-bottom: 0;
  font-size: 14px;
}

.cont_wrap .notice_detail_box .detail_box_content>p~.other_color {
  line-height: 18px;
  font-size: 12px;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl {
  margin-top: 12px;
  margin-bottom: 32px;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl th {
  font-size: 12px;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl td {
  padding: 12px;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl p,
.cont_wrap .notice_detail_box .tbl.notice_tbl strong,
.cont_wrap .notice_detail_box .tbl.notice_tbl li {
  line-height: 18px;
  font-size: 12px;
}

.cont_wrap .notice_detail_box p+p {
  margin-top: 8px;
}

.cont_wrap .notice_detail_box span.other_color {
  display: block;
  line-height: 18px;
  margin-bottom: 0;
  font-size: 12px;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl strong {
  margin-bottom: 0;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl ul li+li {
  margin-top: 8px;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl .other_color {
  margin-top: 4px;
  margin-bottom: 0;
  word-break: break-word;
}

.cont_wrap .notice_detail_box .tbl.notice_tbl strong+p,
.cont_wrap .notice_detail_box .tbl.notice_tbl strong~ul {
  margin-top: 12px;
}

@media screen and (min-width: 1024px) {
  .cont_wrap.privacy {
    margin-bottom: 200px;
    padding-bottom: 0;
  }

  .cont_wrap.privacy .tab_menu_box+h3 {
    padding-top: 71px;
  }

  .cont_wrap.privacy .btn_prev_area+.top_txt {
    margin-top: 32px;
  }

  .cont_wrap.privacy .btn_prev_info {
    margin-top: 16px;
  }

  .cont_wrap .privacy_notice_wrap {
    margin-top: 36px;
  }

  .cont_wrap .privacy_notice_wrap .notice_title_box {
    flex-direction: row;
    align-items: center;
    padding: 36px 0;
  }

  .cont_wrap .privacy_notice_wrap .notice_title {
    line-height: 29px;
    margin-bottom: 0;
    font-size: 20px;
    color: #353b42;
  }

  .cont_wrap .privacy_notice_wrap .notice_date {
    line-height: 29px;
    margin-top: 0;
    margin-left: auto;
    font-size: 16px;
  }

  .cont_wrap .notice_detail_box {
    padding-top: 36px;
    padding-bottom: 64px;
  }

  .cont_wrap .notice_detail_box h3 {
    line-height: 33px;
    margin-top: 64px;
    margin-bottom: 32px;
    padding-top: 64px;
    color: #353b42;
  }

  .cont_wrap .notice_detail_box .detail_box_content>p {
    line-height: 30px;
    font-size: 17px;
  }

  .cont_wrap .notice_detail_box .detail_box_content>p~.other_color {
    line-height: 27px;
    font-size: 17px;
  }

  .cont_wrap .notice_detail_box .tbl.notice_tbl {
    margin-top: 20px;
    margin-bottom: 60px;
  }

  .cont_wrap .notice_detail_box .tbl.notice_tbl th {
    font-size: 15px;
  }

  .cont_wrap .notice_detail_box .tbl.notice_tbl td {
    padding: 16px;
  }

  .cont_wrap .notice_detail_box .tbl.notice_tbl p,
  .cont_wrap .notice_detail_box .tbl.notice_tbl strong,
  .cont_wrap .notice_detail_box .tbl.notice_tbl span,
  .cont_wrap .notice_detail_box .tbl.notice_tbl li {
    line-height: 22px;
    font-size: 14px;
  }

  .cont_wrap .notice_detail_box span.other_color {
    line-height: 22px;
    font-size: 14px;
  }

  .cont_wrap .notice_detail_box .tbl.notice_tbl ul li+li {
    margin-top: 16px;
  }

  .cont_wrap .notice_detail_box .tbl.notice_tbl .other_color {
    margin-top: 8px;
  }

  .cont_wrap .notice_detail_box .tbl.notice_tbl strong+p,
  .cont_wrap .notice_detail_box .tbl.notice_tbl strong~ul {
    margin-top: 16px;
  }

  .btn_prev_info+.btn_prev_info {
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cont_wrap .tab_menu_box .tab_menu_list>li {
    width: 150px;
  }

  .cont_wrap .tab_menu_box .tab_menu_list>li.wide {
    width: 200px;
  }
}
