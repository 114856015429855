.cont_wrap {
  // overflow: hidden;
  // min-width: 300px;
  margin: 103px auto 78px;
  padding: 0 30px;
}

g .cont_wrap.ethics {
  margin: 103px auto 60px;
}

.cont_wrap h2 {
  font-weight: bold;
  font-size: 22px;
  font-family: NotoSansB, sans-serif;
  line-height: 1.2;
}

.cont_wrap h2 .txt_num_en {
  font-family: Museo_Sans_700, sans-serif;
  line-height: 1.4;
}

.btn_prev_area {
  overflow: hidden;
}

.btn_prev_info {
  position: relative;
  float: right;
  margin: 20px 0 10px;
}

.btn_prev_info+.btn_prev_info {
  margin-right: 12px;
}

.btn_prev_info:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #595e65;
}

.cont_wrap .mo_blind {
  display: none;
}

.cont_wrap .top_txt {
  margin: 20px 0 5px;
  font-family: NotoSansR, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #595e65;
  line-height: 24px;
  &.last {
    + p {
      line-height: 24px;
    }
  }
}

.cont_wrap .mg0 {
  margin: 0 !important;
}

.cont_wrap h3 {
  padding-top: 33px;
  margin: 28px 0 17px;
  border-top: 1px solid #c3c6ca;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  font-family: NotoSansM, sans-serif;
}

.cont_wrap p, .cont_wrap ol, .cont_wrap ul, .cont_wrap a {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.33;
  color: #595e65;
}

.cont_wrap a {
  font-size: 12px;
}

.cont_wrap .tbl.lico_contact th {
  padding: 16px 10px 16px;
}

.cont_wrap .tbl.lico_contact td {
  padding: 12px 10px 11px;
}

.cont_wrap.ethics a {
  font-weight: 500;
  font-size: 12px;
  color: #595e65;
  line-height: 1.2;
  font-family: MuseoSans_500, sans-serif;
  word-break: break-all;
}

.cont_wrap ul {
  color: #595e65;
  font-family: NotoSansM, sans-serif;
}

.cont_wrap li .other_color:last-child {
  margin-bottom: 10px;
}

.cont_wrap p {
  margin-bottom: 26px;
  font-family: NotoSansM, sans-serif;
}

.cont_wrap.ethics p {
  font-size: 14px;
  color: #595e65;
  line-height: 1.63;
  letter-spacing: -0.2px;
  font-family: NotoSansR, sans-serif;
}

.cont_wrap.ethics .ethics_table p {
  font-size: 12px;
  color: #595e65;
  line-height: 1.33;
  letter-spacing: -0.2px;
}

.cont_wrap.ethics .ethics_table .middle td>div {
  padding: 11px 27px 11px;
}

.cont_wrap.ethics .ethics_table .tbl.tbl_type .middle th {
  padding: 11px 30px 11px 0;
}

.cont_wrap .other_color {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #a6adb4;
  line-height: 1.71;
  font-family: NotoSansM, sans-serif;
}

.cont_wrap .other_color a {
  color: #a6adb4;
}

.cont_wrap .person_list {
  margin: 0 0 26px;
  font-family: NotoSansR, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.cont_wrap strong {
  display: inline-block;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  color: #595e65;
  line-height: 1.71;
  font-family: NotoSansM, sans-serif;
}

.cont_wrap .tbl+h3 {
  margin-top: 36px;
}

.cont_wrap .privacy_policy.tbl th {
  border-top: 2px solid #595e65;
  border-bottom: 2px solid #595e65;
  vertical-align: top;
}

.cont_wrap .privacy_policy.tbl th>div {
  border-top: 0;
  border-bottom: 0;
  font-family: NotoSansB, sans-serif;
}

.cont_wrap .tbl {
  width: 100%;
  table-layout: fixed;
  margin: 20px 0;
  border: 0;
  font-size: 14px;
  line-height: 22px;
}

.cont_wrap .tbl th {
  padding: 10px 10px 10px;
  border-left: 1px solid #c3c6ca;
  border-top: 2px solid #595e65;
  border-bottom: 2px solid #595e65;
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: #595e65;
  font-family: NotoSansB, sans-serif;
}

.cont_wrap .tbl th.btm_line {
  border-bottom: 1px solid #c3c6ca;
}

.cont_wrap .tbl th:first-child {
  border-left: 0;
  padding-left: 0;
}

.cont_wrap .tbl td {
  padding: 5px 10px 5px;
  border: 1px solid #c3c6ca;
  border-width: 0 0 1px 1px;
  font-size: 15px;
  line-height: 2;
  color: #595e65;
}

.cont_wrap .tbl td.btm_line {
  border-bottom: 1px solid #595e65;
}

.cont_wrap .tbl td.btm_line2 {
  border-bottom: 2px solid #595e65;
}

.cont_wrap .tbl td:first-child {
  border-left: 0;
  padding-left: 0;
}

.cont_wrap .tbl td.align_top {
  vertical-align: top;
}

.cont_wrap .tbl td.align_center {
  text-align: center;
}

.cont_wrap .tbl td>div {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.43;
  letter-spacing: -0.2px;
}

.cont_wrap .tbl p {
  margin: 0;
}

.cont_wrap .tbl.tbl_type {
  margin: 0;
}

.cont_wrap .tbl.tbl_type tr:first-child th, .cont_wrap .tbl.tbl_type tr:first-child td {
  border-top: 2px solid #595e65;
}

.cont_wrap .tbl.tbl_type tr:last-child th, .cont_wrap .tbl.tbl_type tr:last-child td {
  border-bottom: 0;
}

.cont_wrap .tbl.tbl_type th {
  padding: 20px 30px 17px;
  border: 0;
  vertical-align: top;
}

.cont_wrap.ethics .tbl.tbl_type th {
  padding: 11px 0 17px 0;
  width: 66px;
  font-size: 12px;
  color: #595e65;
  line-height: 1.2;
}

.cont_wrap .tbl.tbl_type td {
  padding: 0;
  border-left: 0;
}

.cont_wrap .tbl.tbl_type td>div {
  padding: 23px 38px 24px;
  border: 0;
  border-left: 1px solid #c3c6ca;
}

.cont_wrap.ethics .tbl.tbl_type td>div {
  padding: 11px 13px 11px;
}

.cont_wrap .tbl.tbl_type th, .cont_wrap .tbl.tbl_type td {
  border-bottom: 1px solid #595e65;
}

.cont_wrap.ethics .tbl.tbl_type th, .cont_wrap .tbl.tbl_type td {
  border-bottom: 1px solid #c3c6ca;
}

.cont_wrap .tbl.privacy_policy .first_tr td {
  padding: 7px 10px 7px;
}

.cont_wrap .tbl.privacy_policy .first_tr td:first-child {
  padding-left: 0;
}

.cont_wrap .tbl.privacy_policy .second_tr td {
  padding: 8px 10px 7px;
}

.cont_wrap .tbl.privacy_policy .second_tr td:first-child {
  padding-left: 0;
}

.cont_wrap .tbl.tbl_type .btn_counseling {
  display: block;
  margin-top: 6px;
  width: 102px;
  height: 34px;
  background-color: #000;
  font-weight: 500;
  font-family: NotoSansM, sans-serif;
  font-size: 12px;
  line-height: 34px;
  color: #fff;
  letter-spacing: 0.1px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cont_wrap .tbl.tbl_type .btn_counseling .dot {
  display: inline-block;
  width: 2px;
  height: 2px;
  margin: -3px 2px 0 4px;
  background-color: #fff;
  vertical-align: middle;
}

.cont_wrap .tbl_type_area {
  margin-top: 36px;
  border-bottom: 2px solid #595e65;
}

.cont_wrap .list_base {
  margin-top: 19px;
}

.cont_wrap .def_info {
  overflow: hidden;
  line-height: 2;
}

.cont_wrap .def_info dt {
  float: left;
  margin-right: 5px;
  line-height: 1.33;
}

.cont_wrap .def_info dd {
  overflow: hidden;
  line-height: 1.33;
}

.cont_wrap .privacy_process_txt {
  margin-bottom: 20px;
}

.cont_wrap .privacy_policy.tbl th.col1, .cont_wrap .privacy_policy.tbl td.col1 {
  width: 68px;
}

.cont_wrap .privacy_policy.tbl th.col2, .cont_wrap .privacy_policy.tbl td.col2 {
  width: 95px;
}

.cont_wrap .tbl th.col1, .cont_wrap .tbl td.col1 {
  width: 70px;
}

.cont_wrap .tbl.lico_contact tr:first-child th,
.cont_wrap .tbl.lico_contact tr:first-child td {
  border-top: 2px solid #595e65;
  border-bottom: 1px solid #c3c6ca;
}

.cont_wrap .tbl.lico_contact tr~tr th {
  border-top: 0;
  border-bottom: 1px solid #c3c6ca;
}

.cont_wrap .tbl.lico_contact tr:last-child th, .cont_wrap .tbl.lico_contact tr:last-child td {
  border-top: 1px solid #c3c6ca;
  border-bottom: 2px solid #595e65;
}

.cont_wrap .tbl.lico_contact tr th {
  vertical-align: top;
}

.cont_wrap .tbl.privacy_policy th>div span,
.cont_wrap .tbl.privacy_policy td>div span {
  display: none;
}

.cont_wrap .tbl.privacy_policy th>div span.privacy_policy_txt1,
.cont_wrap .tbl.privacy_policy td>div span.privacy_policy_txt1 {
  display: block;
}

/* 상단 tab 박스 */
.cont_wrap .tab_menu_box {
  margin-top: 20px;
}

.cont_wrap .tab_menu_box+h3 {
  border-top: 0;
  margin: 0;
}



.cont_wrap .tab_menu_box .tab_menu_list {
  display: block;
  font-size: 0;
  border: solid #000;
  border-width: 0 0 1px 0;
}

.cont_wrap .tab_menu_box .tab_menu_list>li {
  display: inline-block;
  vertical-align: top;
  width: 110px;
  height: 40px;
  border: solid #000;
  border-width: 1px 1px 0 1px;
  text-align: center;
  box-sizing: border-box;
}

.cont_wrap .tab_menu_box .tab_menu_list>li.wide {
  width: 175px;
}

.cont_wrap .tab_menu_box .tab_menu_list>li+li {
  border-left: 0;
}

.cont_wrap .tab_menu_box .tab_menu_list>li>a {
  display: block;
  padding: 10px 0 11px;
  font-size: 12px !important;
  letter-spacing: -0.2px;
  font-weight: bold;
  color: #000;
  line-height: normal;
  box-sizing: border-box;
  height: 100%;
}

.cont_wrap .tab_menu_box .tab_menu_list>li>a.active {
  background-color: #000;
  color: #fff;
}

/* Privacy Common */ 
.cont_wrap.privacy .information_area {
  margin-top: 40px;
}

.cont_wrap.privacy .information_area .information_head .title {
  line-height: 24px;
  font-size: 18px;
  font-weight: bold;
}

.cont_wrap.privacy .information_area .information_inner {
  margin-top: 20px;
}

.cont_wrap .notice_wrap {
  min-height: 100%;
  margin-bottom: 68px;
}

.cont_wrap .notice_wrap .notice_list {
  margin-top: 24px;
}

.cont_wrap .notice_wrap .notice_title_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 18px 0;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
  text-align: left;
}

.cont_wrap .notice_wrap .notice_title {
  flex: 1;
  line-height: 20px;
  margin-bottom: 0;
  font-size: 15px;
  color: #000;
}

.cont_wrap .notice_wrap .notice_date {
  display: block;
  margin-top: 8px;
  line-height: 13px;
  font-size: 13px;
  color: #A6ADB4;
  ;
}

.cont_wrap .notice_detail_box {
  display: none;
  padding-top: 18px;

  &.is-show {
    display: block;
  }
}

@media screen and (min-width:1600px) {
  .cont_wrap.privacy .information_area .information_inner {
    margin-top: 32px;
  }
}

@media screen and (min-width:1440px) {
  .cont_wrap .notice_wrap .notice_list {
    margin-top: 36px;
  }
}

@media screen and (min-width:1024px) {
  .cont_wrap .tab_menu_box {
    margin-top: 56px;
    border-bottom: 1px solid #000;
  }

  .cont_wrap .tab_menu_box .tab_menu_list {
    border: none;
  }

  .cont_wrap .tab_menu_box .tab_menu_list>li {
    width: 240px;
    height: 64px;
  }

  .cont_wrap .tab_menu_box .tab_menu_list>li.wide {
    width: 340px;
  }

  .cont_wrap .tab_menu_box .tab_menu_list>li>a {
    padding: 17px 0 18px;
    font-size: 20px !important;
    letter-spacing: -0.4px;
  }

  .cont_wrap .top_txt.last {
    margin-bottom: 26px;
    + p {
      line-height: 24px;
    }
  }

  .cont_wrap.privacy .information_area {
    margin-top: 70px;
  }

  .cont_wrap .notice_wrap {
    max-width: 1280px;
    min-height: 623px;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 102px;
  }

  .cont_wrap .notice_wrap .notice_title_box {
    flex-direction: row;
    align-items: center;
    padding: 36px 0;
  }

  .cont_wrap .notice_wrap .notice_title {
    line-height: 29px;
    margin-bottom: 0;
    font-size: 20px;
  }

  .cont_wrap .notice_wrap .notice_date {
    line-height: 29px;
    margin-top: 0;
    margin-left: auto;
    font-size: 16px;
  }

  .cont_wrap .notice_detail_box {
    padding-top: 53px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cont_wrap .top_txt.last {
    margin-bottom: 26px;
  }

  .cont_wrap .notice_wrap {
    min-height: 574px;
    margin-bottom: 62px;
  }

  .cont_wrap .notice_wrap .notice_list {
    margin-top: 22px;
  }

  .cont_wrap .notice_detail_box {
    padding-bottom: 62px;
  }
}

@media screen and (max-width: 767px) {
  .cont_wrap h3 {
    line-height: 24px;
  }

  .cont_wrap .top_txt.last {
    margin-bottom: 26px;
  }

  .cont_wrap .tab_menu_box .tab_menu_list {
    display: flex;

    &>li {
      width: auto;
      flex-basis: 92px;
      flex: auto;

      &.wide {
        width: auto;
        flex-basis: auto;
      }

      &>a {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 14px;
        font-size: 11px !important;
      }
    }
  }
}
