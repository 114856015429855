.main_bg_video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -20;
  background: #000;
}

.main_bg_video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -30;
}

/* main - about */
.wrap {
  height: 100%;
}

.wrap.work {
  min-height: 285px;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrap.work .mo_footer {
  display: none;
}

.fullpage_wrapper {
  max-height: 100%;
}

.section {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.section_inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section_inner .section_cell {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  z-index: 11;
}

.section_inner .main_box {
  margin: 0 30px;
  color: #fff;
  white-space: nowrap;
  z-index: 11;
  padding-left: env(safe-area-inset-left);
  padding-left: constant(safe-area-inset-left);
  padding-right: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-left);
}

.section_inner .main_box .txt_transform_line {
  overflow: hidden;
}

.section_inner .main_box .txt_transform_line.btn {
  overflow: visible;
}

.section_inner .main_box .head_txt {
  position: relative;
  font-weight: 900;
  font-size: 50px;
  line-height: 1.65;
  font-family: Museo_Slab_900, sans-serif;
  letter-spacing: -0.5px;

}

.section_inner .main_box .head_txt.sub {
  z-index: 11;
}

.section_inner .main_box .head_txt .txt_transform_line+.txt_transform_line {
  margin-top: -27px;
}

.section_inner .main_box .head_txt.type1 {
  display: none;
}

.section_inner .main_box .head_txt.type2 {
  display: none;
}

.section_inner .main_box .sub_txt_box {
  margin-top: -20px;
  position: relative;
  z-index: 11;
}

/* .section_inner.work .main_box .sub_txt_box,.section_inner.career .main_box .sub_txt_box{
	margin-top: -1px
} */
.section_inner .main_box .sub_txt_box .sub_txt {
  font-size: 20px;
  line-height: 1.3;
  z-index: 11;
}

.section_inner .main_box .sub_txt_box .explanation_txt {
  margin-top: 10px;
  font-size: 15px;
  opacity: 0.6;
  line-height: 1.38;
  z-index: 11;
}

.section_inner .main_box .sub_txt_box .explanation_txt.type2 {
  display: none;
}

.section_inner .main_box .sub_txt_box .explanation_txt.type3 {
  display: none;
}

.section_inner .main_box .btn_box {
  position: relative;
}

.section_inner .main_box .btn_box a {
  display: inline-block;
  width: 138px;
  height: 43px;
  margin-top: 25px;
  border: 1px solid #fff;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-family: Museo_Slab_500, sans-serif;
}

.section_inner .main_box .btn_box a:hover {
  background: #fff;
  color: #000;
}

/* main - contact */
.section.contact {
  position: fixed;
  background-color: #fff;
  overflow-y: auto;
  position: relative \9;
}

/* IE10 and IE11 hack */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .section.contact {
    position: relative;
  }
}

.sub_contact .section.contact {
  position: relative !important;
}

.section.contact .contact_inner {
  padding: 116px 30px 247px;
  min-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: env(safe-area-inset-left);
  margin-left: constant(safe-area-inset-left);
  margin-right: env(safe-area-inset-left);
  margin-right: constant(safe-area-inset-left);
}

.section.contact .contact_inner .header_contact {
  font-weight: 900;
  font-size: 45px;
  color: #000;
  line-height: 1.11;
  font-family: Museo_Slab_900, sans-serif;
  font-weight: normal;
}

.section.contact .contact_inner .way_to_come {
  margin-top: 17px;
}

.section.contact .contact_inner .way_to_come .en {
  font-family: Museo_Sans_300, sans-serif;
}

.section.contact .contact_inner .way_to_come dt {
  font-weight: 600;
  font-size: 17px;
  color: #000;
  line-height: 20px;
}

.section.contact .contact_inner .way_to_come dd {
  margin-top: 10px;
}

.section.contact .contact_inner .way_to_come dd ul li {
  margin-top: 1px;
  font-size: 13px;
  color: #000;
  line-height: 1.38;
}

.section.contact .contact_inner .way_to_come dd ul li:after {
  content: '';
  display: block;
  clear: both;
}

.section.contact .contact_inner .way_to_come dd ul li:first-child {
  margin-top: 0;
}

.section.contact .contact_inner .way_to_come dd ul li .bu_txt {
  float: left;
  line-height: 1.3;
}

.section.contact .contact_inner .way_to_come dd ul li .txt_box {
  overflow: hidden;
  display: block;
  line-height: 18px;
}

.section.contact .contact_inner .way_to_come dd ul li.type2 {
  display: none;
}

.section.contact .contact_inner .btn_naver_map {
  position: relative;
  display: inline-block;
  margin-top: 8px;
  font-weight: 600;
  font-size: 13px;
  color: #000;
  line-height: 1.38;
}

.section.contact .contact_inner .btn_naver_map:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 9px;
  margin: 5px 0 0 4px;
  background: url(../img/mobile/sp_arrow.png) 0 -688px no-repeat;
  background-size: 100px 900px;
  vertical-align: top;
}

.section.contact .contact_inner .btn_naver_map:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #000;
}

.section.contact .contact_inner .contact_bottom .img_lico_map {
  width: 315px;
  height: 278px;
  background: url(../img/mobile/img_contact_map_v2.png) 50% 50% no-repeat;
  background-size: 315px auto;
  vertical-align: top;
}

.section.contact .contact_inner .contact_bottom .contact_method li+li:before {
  content: '';
  display: block;
  height: 0.5px;
  margin: 50px 0 47px;
  background-color: #000;
  opacity: 0.12;
}

.section.contact .contact_inner .contact_bottom .contact_method li strong {
  font-size: 17px;
  color: #000;
}

.section.contact .contact_inner .contact_bottom .contact_method li p {
  margin-top: 6px;
  font-size: 13px;
  color: #000;
  line-height: 1.4;
}

.section.contact .contact_inner .contact_bottom .contact_method li p+p {
  margin-top: 1px;
}

.section.contact .contact_inner .contact_bottom .contact_method li p .spot_en {
  display: inline-block;
  font-family: Museo_Sans_300, sans-serif;
  line-height: 1.3;
}

.section.contact .contact_inner .contact_bottom .contact_method li a {
  display: inline-block;
  width: 150px;
  height: 47px;
  margin-top: 16px;
  background-color: #000;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  line-height: 47px;
  text-align: center;
  vertical-align: top;
}

.section.contact .contact_inner .contact_bottom .contact_method .pc_only_view {
  display: none;
}

/* main - 섹션 내비게이션 */
.section_nav {
  position: fixed;
  top: 50%;
  right: 30px;
  z-index: 50;
  margin-top: -32px;
  margin-right: env(safe-area-inset-left);
  margin-right: constant(safe-area-inset-left);
}

.section_nav li {
  position: relative;
  width: 2px;
  height: 2px;
  padding: 4px 0 5px;
}

.section_nav li:first-child {
  margin-top: 0;
}

.section_nav li a:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
}

.section_nav li:hover a:after, .section_nav li.on a:after {
  margin: -1px 0 0 -1px;
  width: 4px;
  height: 4px;
}

/* main - scroll down button */
.btn_scroll_down {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  z-index: 110;
  width: 47px;
  height: 25px;
  background-position: 0 -599px;
}

/* main - text animation */
.head_txt .txt_transform_line div.fadeinup {
  animation-name: head_fadeinup;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.sub_txt .txt_transform_line div.fadeinup {
  animation-name: head_fadeinup;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}

.explanation_txt .txt_transform_line div.fadeinup {
  animation-name: head_fadeinup;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-delay: 0.4s;
}

.btn_box .txt_transform_line div.buttonup {
  animation-name: buttonup;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-delay: 0.6s;
}

@media screen and (min-width: 1280px) {
  .wrap.work {
    min-height: 900px;
  }
}
